import { WrongClipService } from './../../services/wrong-clip.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extraplayer-errors',
  templateUrl: './extraplayer-errors.component.html',
  styleUrls: ['./extraplayer-errors.component.scss'],
  providers: [
      WrongClipService
  ],
})
export class ExtraplayerErrorsComponent implements OnInit {

  constructor(private wrongClipService: WrongClipService) { }

  loading: boolean = true;
  wrongClips = 
  [
    {
      "wrongClipId": "7f241e42-8ff2-41a5-8c80-4b645494aefd",
      "createdAt": "2023-01-13T07:19:30+0000",
      "reporter": {
        "id": "0636591991",
        "name": "Michal",
        "surname": "Bulíř",
        "jersey": "80"
      },
      "error": "wrong_player",
      "resolved": false,
      "event": {
        "type": "shot",
        "time": 3500
      }
    },
    {
      "wrongClipId": "7f241e42-8ff3-41a5-8c80-4b645494aefd",
      "createdAt": "2023-01-14T07:19:30+0000",
      "reporter": {
        "id": "0637291991",
        "name": "Karel",
        "surname": "Novotný",
        "jersey": "82"
      },
      "error": "wrong_player",
      "resolved": false,
      "event": {
        "type": "shot",
        "time": 4000
      }
    }
  ];
  wrongClipsToDisplay: any[] = [];
  possibilities: string[] = ["wrong_player", "wrong_time", "other", "resolved"];

  ngOnInit(): void {
    this.getErrors()
  }

  getErrors(): void {
    this.wrongClipService.getWrongClips().subscribe(
      data => {
        console.log("Data:", data);
        this.filterWrongClips();
      },
      error => console.error("Error while getting errors:", error),
      () => this.loading = false
    );
  }

  /**
   * Filter all the clips into sections.
   */
  filterWrongClips(): void {
    this.wrongClips = this.wrongClips.filter(clip => !clip.resolved);
    this.possibilities.forEach(possibility =>
      this.wrongClipsToDisplay[possibility] = this.wrongClips.filter(clip => clip.error === possibility));
  }
}