import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'clip-node',
  templateUrl: './clip-node.component.html',
  styleUrls: ['./clip-node.component.scss']
})
export class ClipNodeComponent implements OnInit {

  constructor() { }

  @Input() wrongClipArray: any[];
  @Input() section: string;
  @Input() odd: boolean;

  ngOnInit(): void {
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
  }

  loadEditting(clip: any): void {
  }

  /**
   * Toggles collapsing of editing node and changes plus into minus and backwards.
   */
  collapseEdit() {
    if(this.wrongClipArray.length === 0 || this.wrongClipArray === null || this.wrongClipArray === undefined)
      return;
    let node = document.getElementsByClassName(this.section)[0];
    let collapsable = node.getElementsByClassName("collapsable")[0] as HTMLElement;
    if(collapsable != undefined)
      if(collapsable.classList.contains("collapse")) {
        collapsable.classList.remove("collapse");
        collapsable.style.height = "0px";
        collapsable.style.marginTop = "0px";
      } else {
        collapsable.classList.add("collapse");
        collapsable.style.height = collapsable.scrollHeight > 300 ? 300 + "px" : collapsable.scrollHeight + "px";
        collapsable.style.marginTop = "8px";
      }
  }
}