import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WrongClipService } from '../../../services/wrong-clip.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [WrongClipService],
})
export class NavComponent implements OnInit {
  page: string = '';
  user: string = '';
  loading: boolean = true;
  hokejPlayerErrors: any[] = [];

  username: string = '';

  constructor(
    private router: Router,
    private wrongClipService: WrongClipService
  ) {
    this.page = this.router.url;
    this.user = localStorage.getItem('logged_user');

    this.username = JSON.parse(localStorage.getItem('user_identity'))['name'];
  }

  ngOnInit(): void {
    console.log(this.router.url);
    this.getErrors();
  }

  isActive = (page: string) => this.page.includes(page);

  getErrors(): void {
    this.wrongClipService.getWrongClips().subscribe(
      (data: any[]) => {
        this.hokejPlayerErrors = data;
        this.loading = false;
      },
      (error) => {
        console.error('Error while getting errors:', error);
        this.loading = false;
      }
    );
  }

  logout() {
    localStorage.setItem('access_token', '');
    localStorage.setItem('logged_user', '');
    this.router.navigate(['/login']);
  }
}
