<div class="edit-header">
    <div class="match">
        <div class="team-left">
            <img src="/assets/image/logos/{{ match.homeShortcut }}.png"
            onerror="this.src='/assets/image/logos/default.png';" alt="Domácí" />
        </div>
        <div class="match-info">
            <span class="info-text">{{ match.date | date: "dd. MM. yyyy" }} ･ {{ match.competition.league }}</span>
            <h2 class="teams">{{ match.homeShortcut }}-{{ match.awayShortcut }}</h2>
        </div>
        <div class="team-right">
            <img src="/assets/image/logos/{{ match.awayShortcut }}.png"
            onerror="this.src='/assets/image/logos/default.png';" alt="Hosté" />
        </div>
    </div>
    <div class="tools">
        <select [(ngModel)]="selectedEdit" (change)="filterEdits();" class="select-default-width">
            <option value="all">Všechny úpravy</option>
            <option *ngFor="let edit of editArray" value="{{ edit }}">{{ edit | typeTranslate }}</option>
        </select>
        <select [(ngModel)]="selectedUser" (change)="filterUsers();" class="select-default-width">
            <option value="all">Všichni uživatelé</option>
            <option *ngFor="let user of userArray" value="{{ user }}">{{ user }}</option>
        </select>
        <select [(ngModel)]="selectedEvent" (change)="filterEvents();" class="select-default-width">
            <option value="all">Všechny události</option>
            <option *ngFor="let event of eventArray" value="{{ event }}">{{ event | eventTranslate }}</option>
        </select>
        <div class="input-group">
            <input type="text" id="time" [(ngModel)]="time" (input)="filterTime($event.target.value);" placeholder="Čas události" />
            <img src="/assets/image/trash-alt.svg" alt="Koš" (click)="clearTime();" />
        </div>
    </div>
</div>