<app-nav></app-nav>
<loading *ngIf="loading"></loading>

<div class="page-heading">
  <div class="heading-inner">
    <h1>Chyby hokej.PLAYER</h1>
  </div>
</div>

<div class="page">
  <div class="sides">
    <div class="wrong-clips-pane" *ngIf="!loading">
      <div class="clip-list">
        <clip-node
          *ngFor="let option of options; let i = index"
          [wrongClipArray]="wrongClipsToDisplay[option]"
          [section]="option"
          [odd]="i % 2 > 0"
          (onClipResolveButtonClicked)="resolveError($event)"
        >
        </clip-node>
      </div>
    </div>
  </div>
</div>
