<div class="nav">
  <div class="nav-left">
    <ng-container *ngIf="isActive('edit-overview') || isActive('error-overview') || isActive('select') || isActive('extraplayer-errors')">
      <a routerLink="/select" class="nav-item ico game" [class.active]="isActive('select')">Výběr zápasu</a>
      <a routerLink="/error-overview" class="nav-item ico error" [class.active]="isActive('error-overview')">Chyby a upozornění</a>
      <a routerLink="/edit-overview" class="nav-item ico overview" [class.active]="isActive('edit-overview')">Přehled úprav</a>
      <!--<a routerLink="/extraplayer-errors" class="nav-item extraplayer" [class.active]="isActive('extraplayer-errors')">
        <span>
          2
        </span>
        Chyby ExtraPlayer
      </a>-->
    </ng-container>

    <ng-container *ngIf="isActive('tracking')">
      <a routerLink="/select" class="nav-item ico tracking">Výběr zápasu</a>
      <div class="nav-item tracking active">Tracking</div>
    </ng-container>

    <ng-container *ngIf="isActive('supervize')">
      <a routerLink="/select" class="nav-item ico game">Výběr zápasu</a>
      <div class="nav-item tracking active">Supervize</div>
    </ng-container>

    <ng-container *ngIf="isActive('timeline')">
      <a routerLink="/select" class="nav-item ico game">Výběr zápasu</a>
      <div class="nav-item tracking">Supervize</div>
    </ng-container>
  </div>
  <div class="nav-right">
    <div class="nav-item ico user">{{ username }}</div>
    <a (click)="logout()" class="nav-item ico logout">Odhlásit se</a>
  </div>
</div>