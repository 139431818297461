import {
    BACKSPACE,
    DELETE,
    LEFT_ARROW,
    NINE,
    NUMPAD_NINE,
    NUMPAD_ZERO,
    RIGHT_ARROW,
    TAB,
    ZERO,
} from '@angular/cdk/keycodes';
import {
    Directive,
    ElementRef,
    forwardRef,
    Host,
    HostListener,
    OnInit,
    Renderer2,
    Self,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
} from '@angular/forms';

@Directive({
    selector: '[appTimeMask]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeMaskDirective),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeMaskDirective),
            multi: true,
        },
    ],
})
export class TimeMaskDirective implements OnInit, ControlValueAccessor, Validator {

    _onChange: (_: string) => void;

    _touched: () => void;

    private _dateValue: string;


    private _fieldJustGotFocus = false;
    middleValues: string[] = [];

    constructor(@Self() private _el: ElementRef, private _renderer: Renderer2) { }

    ngOnInit() {
        this._el.nativeElement.style.cursor = 'default';
    }


    @HostListener('keydown', ['$event'])
    onKeyDown(evt: KeyboardEvent) {

        const keyCode = evt.keyCode;
        switch (keyCode) {
            case LEFT_ARROW:
            case RIGHT_ARROW:
            case TAB:
                this._decideWhetherToJumpAndSelect(keyCode, evt);
                break;

            case DELETE:
            case BACKSPACE:
                this._clearHoursOrMinutes();
                break;

            default:
                if ((keyCode >= ZERO && keyCode <= NINE) ||
                    (keyCode >= NUMPAD_ZERO && keyCode <= NUMPAD_NINE)) {

                    this._setInputText(evt.key);
                }
        }


        if (keyCode !== TAB) {
            evt.preventDefault();
        }
    }

    @HostListener('click', ['$event'])
    onClick(evt: MouseEvent) {
        this._fieldJustGotFocus = true;
        const caretPosition = this._doGetCaretPosition();
        if (caretPosition < 3) {
            this._el.nativeElement.setSelectionRange(0, 2);
        }
        else if (caretPosition < 5) {
            this._el.nativeElement.setSelectionRange(3, 5);
        } else {
            this._el.nativeElement.setSelectionRange(6, 9);
        }
    }

    @HostListener('focus', ['$event'])
    onFocus(evt: any) {
        this._fieldJustGotFocus = true;
        const caretPosition = this._doGetCaretPosition();
        if (caretPosition < 3) {
            this._el.nativeElement.setSelectionRange(0, 2);
        } else if (caretPosition < 5) {
            this._el.nativeElement.setSelectionRange(3, 5);
        } else {
            this._el.nativeElement.setSelectionRange(6, 9);
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(evt: any) {
        this._touched();
    }


    private _decideWhetherToJumpAndSelect(keyCode: number, evt?: KeyboardEvent) {

        const caretPosition = this._doGetCaretPosition();

        switch (keyCode) {
            case RIGHT_ARROW:
                if (caretPosition < 2)
                    this._el.nativeElement.setSelectionRange(3, 5);
                else
                    this._el.nativeElement.setSelectionRange(6, 9);
                break;

            case LEFT_ARROW:
                
                if (caretPosition > 4)
                    this._el.nativeElement.setSelectionRange(3, 5);
                else
                    this._el.nativeElement.setSelectionRange(0, 2);
                break;

            case TAB:
                if (caretPosition < 1 && !evt.shiftKey) {
                    
                    this._el.nativeElement.setSelectionRange(3, 5);
                    evt.preventDefault();
                } else if (caretPosition > 2 && caretPosition < 5 && evt.shiftKey) {
                    
                    this._el.nativeElement.setSelectionRange(0, 2);
                    evt.preventDefault();
                }
                else if (caretPosition > 4 && evt.shiftKey) {
                    
                    this._el.nativeElement.setSelectionRange(3, 5);
                    evt.preventDefault();
                }
                else {
                    this._el.nativeElement.setSelectionRange(6, 9);
                    evt.preventDefault();
                }
        }

        this._fieldJustGotFocus = true;
    }


    private _setInputText(key: string) {
        const input: string[] = this._el.nativeElement.value.split(':');

        const hours: string = input[0];
        const minutes: string = input[1];
        const seconds: string = input[2];

        const caretPosition = this._doGetCaretPosition();
        
        if (caretPosition < 3) {
            this._setHours(hours, minutes, seconds, key);
        } else if (caretPosition < 5) {
            this._setMinutes(hours, minutes, seconds, key);
        } else {
            this._setSeconds(hours, minutes, seconds, key);
        }

        this._fieldJustGotFocus = false;
    }

    private _setHours(hours: string, minutes: string, seconds: string, key) {
        const hoursArray: string[] = hours.split('');
        const firstDigit: string = hoursArray[0];
        const secondDigit: string = hoursArray[1];

        let newHour = '';

        let completeTime = '';
        let sendCaretToMinutes = false;

        if (firstDigit === '-' || this._fieldJustGotFocus) {
            newHour = `0${key}`;
            sendCaretToMinutes = Number(key) > 2;
        } else {
            newHour = `${secondDigit}${key}`;
            if (Number(newHour) > 23) {
                newHour = '23';
            }
            sendCaretToMinutes = true;
        }

        completeTime = `${newHour}:${minutes}:${seconds}`;

        this._renderer.setProperty(this._el.nativeElement, 'value', completeTime);
        this._controlValueChanged();
        if (!sendCaretToMinutes) {
            this._el.nativeElement.setSelectionRange(0, 2);
        } else {
            this._el.nativeElement.setSelectionRange(3, 5);
            this._fieldJustGotFocus = true;
        }
    }

    private _setMinutes(hours: string, minutes: string, seconds: string, key) {
        const minituesArray: string[] = minutes.split('');
        const firstDigit: string = minituesArray[0];
        const secondDigit: string = minituesArray[1];

        let newMinute = '';

        let completeTime = '';
        let sendCaretToMinutes = false;

        if (firstDigit === '-' || this._fieldJustGotFocus || this.middleValues.length < 1) {
            newMinute = `0${key}`;
            this.middleValues.push(key);
        } else {
            if (Number(secondDigit) === 59) {
                newMinute = `0${key}`;
            } else {
                newMinute = `${secondDigit}${key}`;
                if (Number(newMinute) > 59) {
                    newMinute = '59';
                }
                sendCaretToMinutes = true;
                this.middleValues = [];
            }
        }

        completeTime = `${hours}:${newMinute}:${seconds}`;

        this._renderer.setProperty(this._el.nativeElement, 'value', completeTime);
        this._controlValueChanged();
        if (!sendCaretToMinutes) {
            this._el.nativeElement.setSelectionRange(3, 5);
        } else {
            this._el.nativeElement.setSelectionRange(6, 9);
            this._fieldJustGotFocus = true;
        }
    }

    private _setSeconds(hours: string, minutes: string, seconds: string, key) {
        const secondsArray: string[] = seconds.split('');
        const firstDigit: string = secondsArray[0];
        const secondDigit: string = secondsArray[1];

        let newSeconds = '';

        let completeTime = '';

        if (firstDigit === '-' || this._fieldJustGotFocus) {
            newSeconds = `0${key}`;
        } else {
            if (Number(secondDigit) === 59) {
                newSeconds = `0${key}`;
            } else {
                newSeconds = `${secondDigit}${key}`;
                if (Number(newSeconds) > 59) {
                    newSeconds = '59';
                }
            }
        }

        completeTime = `${hours}:${minutes}:${newSeconds}`;

        this._renderer.setProperty(this._el.nativeElement, 'value', completeTime);
        this._controlValueChanged();
        this._el.nativeElement.setSelectionRange(6, 9);
    }


    _clearHoursOrMinutes() {
        const caretPosition = this._doGetCaretPosition();
        const input: string[] = this._el.nativeElement.value.split(':');

        const hours: string = input[0];
        const minutes: string = input[1];
        const seconds: string = input[2];

        let newTime = '';
        let sendCaretToMinutes = false;
        let sendCaretToSeconds = false;
        
        if (caretPosition < 2) {
            newTime = `00:${minutes}:${seconds}`;
            sendCaretToMinutes = false;
            sendCaretToSeconds = false;
        } else if (caretPosition < 5) {
            newTime = `${hours}:00:${seconds}`;
            sendCaretToSeconds = false;
            sendCaretToMinutes = true;
        } else {
            newTime = `${hours}:${minutes}:00`;
            sendCaretToMinutes = false;
            sendCaretToSeconds = true;
        }

        this._fieldJustGotFocus = true;

        this._renderer.setProperty(this._el.nativeElement, 'value', newTime);
        this._controlValueChanged();


        if (caretPosition < 4) {
            if (!sendCaretToMinutes) {
                this._el.nativeElement.setSelectionRange(0, 2);
            } else {
                this._el.nativeElement.setSelectionRange(3, 5);
            }
        }


        if (!sendCaretToSeconds && caretPosition > 6) {
            this._el.nativeElement.setSelectionRange(6, 9);
        }
    }

    writeValue(value: string): void {
     
        this._dateValue = value;

        const v = value ? value : '00:00:00';

        this._renderer.setProperty(this._el.nativeElement, 'value', v);
    }


    registerOnChange(fn: (_: string) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._touched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
    }

    validate(c: FormControl): { [key: string]: any } {
        return this._el.nativeElement.value.indexOf('-') === -1 ? null : { validTime: false };
    }


    private _doGetCaretPosition(): number {

        // Initialize
        let iCaretPos = 0;

        const nativeElement = this._el.nativeElement;

        // IE Support
        if (document.hasOwnProperty('selection')) {

            // Set focus on the element
            nativeElement.focus();

            // To get cursor position, get empty selection range
            const oSel = document['selection'].createRange();

            // Move selection start to 0 position
            oSel.moveStart('character', -nativeElement.value.length);

            // The caret position is selection length
            iCaretPos = oSel.text.length;
        } else if (nativeElement.selectionStart || nativeElement.selectionStart === '0') {
            // Firefox support
            iCaretPos = nativeElement.selectionStart;
        }

        // Return results
        return iCaretPos;
    }


    private _zeroFill(value: number): string {
        return (value > 9 ? '' : '0') + value;
    }

    private _dateToStringTime(value: Date) {
        return this._zeroFill(value.getHours()) + ':' + this._zeroFill(value.getMinutes()) + ':' + this._zeroFill(value.getSeconds());
    }

    private _stringToNumber(str: string) {
        if (str.indexOf('-') === -1) {
            return Number(str);
        }

        const finalStr = str.replace('-', '0').replace('-', '0').replace('-', '0');

        return Number(finalStr);
    }

    private _controlValueChanged() {
        const timeArray: string[] = this._el.nativeElement.value.split(':');
        this._dateValue = timeArray[0]
        this._dateValue = timeArray[1]
        this._dateValue = timeArray[2]
        this._onChange(this._dateValue);
    }

}
