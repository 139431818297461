<div class="container canvas black" [class.no-scroll]="eventflow_loading" *ngIf="
      !show_faceOff &&
      !show_shot &&
      !show_zoneEntry &&
      !show_dumpIn &&
      !show_zoneExit &&
      !show_dumpOut &&
      !show_offensiveZoneLoss &&
      !show_hit &&
      !show_shootout &&
      !show_penalty &&
      !show_topMoment &&
      !show_penaltyShootout
    ">
    <loading-small *ngIf="eventflow_loading"></loading-small>
    <table class="table-basic">
        <tr>
            <th>Třetina</th>
            <th>Čas v zápase</th>
            <th>Čas časomíry</th>
            <th>Souřadnice</th>
            <th>Typ akce</th>
            <th>Hráč</th>
            <th></th>
        </tr>

        <tr *ngFor="let event of render_eventflow">
            <ng-container *ngIf="event.event === 'faceOff'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.location }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event === 'shot'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ event.coordinates.x }};{{ event.coordinates.y }}
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.result | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'zoneEntry'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ event.coordinates.x }};{{ event.coordinates.y }}
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'hit'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ event.coordinates.x }};{{ event.coordinates.y }}
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'dumpIn'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ event.coordinates.x }};{{ event.coordinates.y }}
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'zoneExit'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ event.coordinates.x }};{{ event.coordinates.y }}
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'dumpOut'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ event.coordinates.x }};{{ event.coordinates.y }}
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'offensiveZoneLoss'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ event.coordinates.x }};{{ event.coordinates.y }}
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'shootout' ">
                <td>
                    <div class="td-inner">sn.</div>
                </td>
                <td>
                    <div class="td-inner">-</div>
                </td>
                <td>
                    <div class="td-inner">-</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.gateZone }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'penalty'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.created) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.created | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.created) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ event.coordinates.x }};{{ event.coordinates.y }}
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'penaltyShootout'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.location }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="event.event == 'topMoment'">
                <td>
                    <div class="td-inner">{{ getPeriod(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ event.eventTime | translateSeconds }}</div>
                </td>
                <td>
                    <div class="td-inner">{{ getCasomira(event.eventTime) }}</div>
                </td>
                <td>
                    <div class="td-inner">
                    </div>
                </td>
                <td>
                    <div class="td-inner">{{ event.event | eventTranslate }}</div>
                </td>
                <td>
                    <div class="td-inner">
                        {{ getPlayerInfo(event, "jersey") }}
                        {{ getPlayerInfo(event, "surname") }} ({{
                        getPlayerTeam(getPlayerInfo(event, "id"))
                        }})
                    </div>
                </td>
            </ng-container>

            <td>
                <button class="edit-btn" (click)="editEvent(event)">Editovat</button>
                <button class="pen-btn" [disabled]="detectEdit(event.edition)"></button>
                <button id="info-btn" [ngClass]="getInfoButtonClass(event)" (click)="correctEventError(event)" [disabled]="detectSupervision(event)">
                    <ng-container *ngIf="getInfoButtonClass(event) === 'warning-btn' && pageType === 'supervize'">
                        <span class="tooltip-window-warning">
                            {{getInfoMessage(event)}}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="getInfoButtonClass(event) === 'error-btn' && pageType === 'supervize'">
                        <span class="tooltip-window-error">
                            {{getInfoMessage(event)}}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="getInfoButtonClass(event) === 'info-btn'">
                        <span class="tooltip-window-info">
                            {{getInfoMessage(event)}}
                        </span>
                    </ng-container>
                </button>


                <ng-container *ngIf="
                    event.event === 'faceOff' ||
                    event.event === 'shot' ||
                    event.event === 'zoneEntry' ||
                    event.event === 'dumpIn' ||
                    event.event === 'zoneExit' ||
                    event.event === 'dumpOut' ||
                    event.event === 'offensiveZoneLoss' ||
                    event.event === 'hit'||
                    event.event === 'topMoment'
                ">
                    <button class="remove-btn" (click)="removeEvent(event)"></button>
                </ng-container>
            </td>
        </tr>
    </table>
</div>