import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { DumpIntUpdateRequestBodyInterface } from 'src/app/interfaces/dumpin-update-request-body.interface';
import { DumpOutUpdateRequestBodyInterface } from 'src/app/interfaces/dumpout-update-request-body.interface';
import { FaceoffUpdateRequestBodyInterface } from 'src/app/interfaces/faceoff-update-request-body.interface';
import { PassUpdateRequestBodyInterface } from 'src/app/interfaces/pass-update-request-body.interface';
import { PuckWonUpdateRequestBodyInterface } from 'src/app/interfaces/puck-won-request-body.interface';
import { ShotUpdateRequestBodyInterface } from 'src/app/interfaces/shot-update-request-body.interface';
import { ZoneEntryUpdateRequestBodyInterface } from 'src/app/interfaces/zone-entry-update-request-body.interface';
import { ZoneExitUpdateRequestBodyInterface } from 'src/app/interfaces/zone-exit-update-request-body.interface';

interface OutputData {
  event: string;
  eventId: number;
  body: any;
}

@Component({
  selector: 'app-time-line-event-edit',
  templateUrl: './time-line-event-edit.component.html',
  styleUrls: ['./time-line-event-edit.component.scss'],
})
export class TimeLineEventEditComponent implements OnInit, OnDestroy {
  @Input() inputData: any;
  @Input() matchData: any;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveData: EventEmitter<OutputData> = new EventEmitter<OutputData>();
  @Output() supervision: EventEmitter<OutputData> =
    new EventEmitter<OutputData>();

  public time;
  public players;
  public shootingPlayer;

  public columns = [];

  constructor() {}

  ngOnInit(): void {
    if (this.inputData && this.matchData?.gameData) {
      this.initData();
    }
  }

  private initData() {
    this.time = this.formatTime(this.inputData.time);
    this.players = [
      ...this.matchData?.roster?.away?.map((el) => ({
        ...el,
        home: false,
        team: this.matchData?.gameData?.awayTeam,
      })),
      ...this.matchData?.roster?.home?.map((el) => ({
        ...el,
        home: true,
        team: this.matchData?.gameData?.homeTeam,
      })),
    ];
    this.inputData.eventEn =
      this.inputData.eventEn ?? this.getEventEn(this.inputData.event);
    const mapData = this.getEventPlayersIds(this.inputData.eventEn);
    Object.keys(this.inputData.players).forEach((key) => {
      const element = mapData.find((el) => el.id === key);
      if (element) {
        const playerData = this.players.find(
          (player) => player.id === this.inputData.players[key]
        );
        this.columns.push({
          ...element,
          value: this.inputData.players[key],
          teamId: playerData.team.id,
        });
      }
    });
  }

  private getEventEn(event: string) {
    let result = null;
    switch (event) {
      case 'VÝSTUP Z PÁSMA': {
        result = 'zoneExit';
        break;
      }
      case 'VSTUP DO PÁSMA': {
        result = 'zoneEntry';
        break;
      }
      case 'ZISK PUKU': {
        result = 'puckWon';
        break;
      }
      case 'VHAZOVÁNÍ': {
        result = 'faceOff';
        break;
      }
      case 'STŘELA': {
        result = 'shot';
        break;
      }
      case 'PŘIHŘÁVKA': {
        result = 'pass';
        break;
      }
      case 'VYHOZENÍ': {
        result = 'dumpOut';
        break;
      }
      case 'NAHOZENÍ': {
        result = 'dumpIn';
        break;
      }
    }
    return result;
  }

  private getEventPlayersIds(event) {
    const result = [];
    switch (event) {
      case 'faceOff': {
        result.push(
          { name: 'Vítěz', id: 'winnerId' },
          { name: 'Poražený', id: 'loserId' }
        );
        break;
      }
      case 'shot': {
        result.push(
          { name: 'Střílející hráč', id: 'playerId' },
          { name: 'Blokující hráč', id: 'blockerId' }
        );
        break;
      }
      case 'zoneEntry': {
        result.push(
          { name: 'Hráč přihrávající puk', id: 'passingPlayerId' },
          { name: 'Hráč zavážející puk', id: 'playerId' },
          { name: 'Protihráč, který způsobil ztrátu', id: 'stopperPlayerId' }
        );
        break;
      }
      case 'zoneExit': {
        result.push(
          { name: 'Hráč, který puk vyvezl', id: 'playerId' },
          { name: 'Protihráč, který způsobil ztrátu', id: 'denialPlayerId' },
          { name: 'Hráč přihrávající puk', id: 'passingPlayerId' }
        );
        break;
      }
      case 'pass': {
        result.push(
          { name: 'Přihrávající hráč', id: 'playerId' },
          { name: 'Blokující hráč', id: 'blockerId' }
        );
        break;
      }
      case 'puckWon': {
        result.push(
          { name: 'Hráč, který získal puk', id: 'playerId' },
          { name: 'Hráč, který přišel o puk', id: 'puckLostPlayerId' },
          {
            name: 'Hráč, který má podíl na zisku puku',
            id: 'gainSharePlayerId',
          }
        );
        break;
      }
      case 'dumpOut': {
        result.push({ name: 'Hráč, který vyhodil puk', id: 'playerId' });
        break;
      }
      case 'dumpIn': {
        result.push({ name: 'Hráč, který nahodil puk', id: 'playerId' });
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }

  private getRequestBody() {
    let result;
    const data = this.inputData.originalData ?? {};
    switch (this.inputData.eventEn) {
      case 'shot': {
        const body: ShotUpdateRequestBodyInterface = {
          app: 'tracking',
          time: data.time,
          attributes: data.attributes,
          result: data.result,
          coordinates: data.coordinates,
          realDate: data.realDate,
          playerId: this.columns.find((col) => col.id === 'playerId')?.value,
          blockerId: this.columns.find((col) => col.id === 'blockerId')?.value,
          goalkeeperId: data.goalkeeperId,
        };
        result = { body, id: data.shotId };
        break;
      }
      case 'faceOff': {
        const body: FaceoffUpdateRequestBodyInterface = {
          app: 'tracking',
          time: data.time,
          location: data.location,
          realDate: data.realDate,
          cleanWin: data.cleanWin,
          winnerId: this.columns.find((col) => col.id === 'winnerId')?.value,
          loserId: this.columns.find((col) => col.id === 'loserId')?.value,
          videoTime: data.videoTime,
        };
        result = { body, id: data.faceOffId };
        break;
      }
      case 'zoneExit': {
        const body: ZoneExitUpdateRequestBodyInterface = {
          app: 'tracking',
          time: data.time,
          playerId: this.columns.find((col) => col.id === 'playerId')?.value,
          passingPlayerId: this.columns.find(
            (col) => col.id === 'passingPlayerId'
          )?.value,
          denialPlayerId: this.columns.find(
            (col) => col.id === 'denialPlayerId'
          )?.value,
          success: data.success,
          denialTime: data.denialTime,
          type: data.type,
          coordinates: data.coordinates,
          denialCoordinates: data.denialCoordinates,
          received: data.received,
          underPressure: data.underPressure,
          stretchPass: data.stretchPass,
          followingEvent: data.followingEvent,
          participation: data.participation,
        };
        result = { body, id: data.zoneExitId };
        break;
      }
      case 'zoneEntry': {
        const body: ZoneEntryUpdateRequestBodyInterface = {
          app: 'tracking',
          time: data.time,
          playerId: this.columns.find((col) => col.id === 'playerId')?.value,
          passingPlayerId: this.columns.find(
            (col) => col.id === 'passingPlayerId'
          )?.value,
          stopperPlayerId: this.columns.find(
            (col) => col.id === 'stopperPlayerId'
          )?.value,
          coordinates: data.coordinates,
          completed: data.completed,
          type: data.type,
        };
        result = { body, id: data.zoneEntryId };
        break;
      }
      case 'pass': {
        const body: PassUpdateRequestBodyInterface = {
          app: 'tracking',
          time: data.time,
          playerId: this.columns.find((col) => col.id === 'playerId')?.value,
          blockerId:
            this.columns.find((col) => col.id === 'blockerId')?.value ?? null,
          shotId: data.shotId,
          coordinates: data.coordinates,
          quickAttack: data.quickAttack,
          forecheck: data.forecheck,
          oddManRush: data.oddManRush,
          completeToSlot: data.completeToSlot,
          nonCompleteToSlot: data.nonCompleteToSlot,
          blockedToSlot: data.blockedToSlot,
        };
        result = { body, id: data.passId };
        break;
      }
      case 'puckWon': {
        const body: PuckWonUpdateRequestBodyInterface = {
          app: 'tracking',
          time: data.time,
          playerId: this.columns.find((col) => col.id === 'playerId')?.value,
          puckLostPlayerId: this.columns.find(
            (col) => col.id === 'puckLostPlayerId'
          )?.value,
          gainSharePlayerId: this.columns.find(
            (col) => col.id === 'gainSharePlayerId'
          )?.value,
          shotId: data.shotId,
          coordinates: data.coordinates,
          zoneExitId: data.zoneExitId,
          dumpOutId: data.dumpOutId,
          type: data.type,
          goalieTouch: data.goalieTouch,
          underPressure: data.underPressure,
          realDate: data.realDate,
          participation: data.participation,
        };
        result = { body, id: data.puckWonId };
        break;
      }
      case 'dumpOut': {
        const body: DumpOutUpdateRequestBodyInterface = {
          app: 'tracking',
          time: data.time,
          playerId: this.columns.find((col) => col.id === 'playerId')?.value,
          coordinates: data.coordinates,
          battleWonTeamId: data.battleWonTeamId,
          battle: data.battle,
          dumpIn: data.dumpIn,
          realDate: data.realDate,
        };
        result = { body, id: data.dumpOutId };
        break;
      }
      case 'dumpIn': {
        const body: DumpIntUpdateRequestBodyInterface = {
          app: 'tracking',
          time: data.time,
          playerId: this.columns.find((col) => col.id === 'playerId')?.value,
          coordinates: data.coordinates,
          battleWonTeamId: data.battleWonTeamId,
          dumpIn: data.dumpIn,
          battleWon: data.battleWon,
          realDate: data.realDate,
        };
        result = { body, id: data.dumpInId };
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }

  private formatTime(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const secondString = seconds <= 9 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${secondString}`;
  }

  public getPlayersByTeamId(teamId) {
    return this.players.filter((el) => el.team.id === teamId);
  }

  public onSaveClick() {
    const requestBody = this.getRequestBody();
    this.saveData.emit({
      body: requestBody.body,
      event: this.inputData.eventEn,
      eventId: requestBody.id,
    });
  }

  public onSupervizeClick() {
    const requestBody = this.getRequestBody();
    const body = this.inputData.originalData.supervision ?? {};
    this.columns.forEach((el) => (body[el.id] = true));
    this.supervision.emit({
      body,
      event: this.inputData.eventEn,
      eventId: requestBody.id,
    });
  }

  public onClose() {
    this.close.emit();
  }

  public ngOnDestroy(): void {
    this.close.emit();
  }
}
