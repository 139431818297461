/**
 * Formates the time to the 00:00 format.
 * @param seconds Seconds to be calculated with the result.
 * @returns a string representation of the 00:00 formated time.
 */
export function formatTime(seconds: number) {
    return (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds;
}

export function formatTimeNumber(value: number) {
    return value < 10 ? '0' + value : value;
}

export function getTimeInSeconds(timeString: string, period: number, overtimeLength: number) {
  let times = timeString.split(':');
  let time = Number(times[0]) * 60 + Number(times[1]);
  if (period === 1)
    return (time - 1200) * -1;
  if (period === 2)
    return (time - 1200) * -1 + 1200;
  if (period === 3)
    return (time - 2400) * -1 + 1200;
  for(let i: number = 3; i < 8; i++)
    if(period === i + 1)
      return (time - i * 1200) * -1 + overtimeLength;

  return time;
}