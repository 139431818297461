import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  @Output() onToggle = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
  }

  expanded: boolean = false;
  src: string = "/assets/image/" + (!this.expanded ? "plus-circle.svg" : "minus-circle.svg");

  /**
   * Toggles the image source.
   */
  toggle() {
    this.expanded = !this.expanded;
    this.onToggle.emit(this.expanded);
    this.src = "/assets/image/" + (!this.expanded ? "plus-circle.svg" : "minus-circle.svg");
  }
}