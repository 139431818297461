import { UtilService } from './../../../services/util.service';
import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { isNumber } from 'util';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { errorsText } from '../../../../errors_text';
import { MatchTypes } from '../../../enums/match-type.enum';

import { EventFlowService } from '../../../services/eventflow.service';
import { FaceOffService } from '../../../services/faceOff.service';
import { DumpInService } from '../../../services/dumpIn.service';
import { DumpOutService } from '../../../services/dumpOut.service';
import { HitService } from '../../../services/hit.service';
import { OffensiveZoneLossService } from '../../../services/offensiveZoneLoss.service';
import { ShotService } from '../../../services/shot.service';
import { ZoneEntryService } from '../../../services/zoneEntry.service';
import { ZoneExitService } from '../../../services/zoneExit.service';
import { PassService } from '../../../services/pass.service';
import { PuckWonService } from '../../../services/puckWon.service';

import * as TrackingUtils from '../../../utils/tracking-utils';

@Component({
  selector: 'eventflow',
  templateUrl: './eventflow.component.html',
  styleUrls: ['./eventflow.component.scss'],
  providers: [
    EventFlowService,
    UtilService,
    FaceOffService,
    DumpInService,
    DumpOutService,
    HitService,
    OffensiveZoneLossService,
    ShotService,
    ZoneEntryService,
    ZoneExitService,
    PassService,
    PuckWonService,
  ],
})
export class EventflowComponent implements OnInit, OnChanges {
  @Input() show_shootout: boolean = false;
  @Input() show_faceOff: boolean = false;
  @Input() show_shot: boolean = false;
  @Input() show_zoneEntry: boolean = false;
  @Input() show_dumpIn: boolean = false;
  @Input() show_offensiveZoneLoss: boolean = false;
  @Input() show_hit: boolean = false;
  @Input() show_penalty: boolean = false;
  @Input() show_zoneExit: boolean = false;
  @Input() show_dumpOut: boolean = false;
  @Input() show_topMoment: boolean = false;
  @Input() show_penaltyShootout: boolean = false;

  @Input() id: any;
  @Input() game: any;
  @Input() editData: any;
  @Input() sidesPeriod: boolean[];
  @Input() period: number;
  @Input() pageType: string;
  @Input() overtimeLength: number;
  @Input() roster_home: any;
  @Input() roster_away: any;
  @Input() toggle_adding_nav: boolean;
  @Input() filterEventFlow: boolean;
  @Input() passIdForDeletion: number;
  @Input() puckwonIdForDeletion: number;
  @Input() requireEventFlow: boolean;

  eventflow: any = [];
  @Input() render_eventflow: any = [];
  @Output() onToggleCanvas = new EventEmitter<String>();
  @Output() onSetCasomiraByEvent = new EventEmitter<number>();
  @Output() onLoading = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<any>();
  @Output() onEventFlowChange = new EventEmitter<any[]>();
  @Output() onRequireEventFlowChange = new EventEmitter<boolean>(true);
  @Output() onEditDataChange = new EventEmitter<any>();

  eventflow_loading: boolean = true;
  title: string = '';

  constructor(
    private eventFlowService: EventFlowService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private faceOffService: FaceOffService,
    private dumpInService: DumpInService,
    private dumpOutService: DumpOutService,
    private hitService: HitService,
    private offensiveZoneLossService: OffensiveZoneLossService,
    private shotService: ShotService,
    private zoneEntryService: ZoneEntryService,
    private zoneExitService: ZoneExitService,
    private passService: PassService,
    private puckWonService: PuckWonService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(change: SimpleChanges): void {
    if (
      change.period?.currentValue !== undefined &&
      change.period?.currentValue !== null
    ) {
      this.period = change.period.currentValue;
      console.log('Period:', change.period.currentValue);
      console.log('Period this:', this.period);
      this.loadEventFlow();
    }
    if (
      change.requireEventFlow?.currentValue !== undefined &&
      change.requireEventFlow?.currentValue !== null
    ) {
      this.requireEventFlow = change.requireEventFlow.currentValue;
      if (this.requireEventFlow) {
        this.loadEventFlow();
        this.requireEventFlow = false;
        this.onRequireEventFlowChange.emit(false);
      }
    }
  }

  loadEventFlow() {
    this.eventflow = [];
    this.eventflow_loading = true;
    this.eventFlowService.getEventflow(this.id).subscribe(
      (data: any) => {
        console.log('Loaded Data EW', data);
        this.eventflow = data;
        const mapShotIdsFromPassPuckWon = new Map<number, any>();
        // Mapa pro naparovani shotId s error type a rule
        this.eventflow.forEach((item) => {
          if (['pass', 'puckWon'].includes(item.event) && item.error !== null) {
            const shotId = item.shotId;
            const value = {
              rule: item.error.rule,
              errorType: item.error.type,
              prev_time: item.error.prev_time,
              time: item.time,
            };
            mapShotIdsFromPassPuckWon.set(shotId, value);
          }
        });

        // premapovat za pomoci mapy
        this.eventflow.forEach((item) => {
          if (
            item.event === 'shot' &&
            mapShotIdsFromPassPuckWon.has(item.shotId)
          ) {
            const isObjectPresent = mapShotIdsFromPassPuckWon.get(item.shotId);
            item.error = {
              type: isObjectPresent.errorType,
              rule: isObjectPresent.rule,
              prev_time: isObjectPresent.prev_time,
            };
            // item.shotTimeBackup =  item.time ;
            // item.time = isObjectPresent.time;
          }
        });

        this.eventflow.forEach((item) => {
          if (typeof item.time !== 'undefined') {
            if (isNumber(item.time)) {
              item.eventTime = item.time;
            } else {
              item.eventTime = item.time.start;
            }
          } else {
            item.eventTime = 10000;
          }
          item.filter_player_post = this.getPlayerInfo(item, 'position');
          item.filter_player_team = this.getPlayerTeam2(
            this.getPlayerInfo(item, 'id')
          );
        });

        this.eventflow.sort((a, b) => a.eventTime - b.eventTime);
        this.filterEventFlow = true;
        this.onEventFlowChange.emit(this.eventflow);
      },
      (error) => console.error('Event flow error:', error),
      () => (this.eventflow_loading = false)
    );
  }

  getPeriod(time: number): string {
    if (time > -1 && time < 1200) return '1.';
    if (time >= 1200 && time < 2400) return '2.';
    if (time >= 2400 && time < 3600) return '3.';
    if (time >= 3600 && time < 3600 + this.overtimeLength) return 'prod.';
    let iterationCount: number = Math.ceil((time - 3600) / this.overtimeLength);
    return 'prod.' + iterationCount;
  }

  getPeriodNumber(seconds: number): number {
    if (seconds > -1 && seconds < 1200) return 1;
    if (seconds >= 1200 && seconds < 2400) return 2;
    if (seconds >= 2400 && seconds < 3600) return 3;
    if (seconds >= 3600 && seconds < 3600 + this.overtimeLength) return 4;
    return Math.ceil((seconds - 3600) / this.overtimeLength);
  }

  getCasomira(seconds: number) {
    const period = TrackingUtils.getPeriodNumber(seconds, this.overtimeLength);
    if (period <= 3) return this.formatTime(1200 * period - seconds);
    if (period === 4)
      return this.formatTime(3600 + this.overtimeLength - seconds);
    return this.formatTime(3600 + (period - 3) * this.overtimeLength - seconds);
  }

  detectSupervision(event: any) {
    if (
      event.error?.type !== undefined &&
      event.error?.type !== undefined &&
      event.error?.type === 'warning'
    )
      return false;

    let supervision: any = event.supervision;
    if (supervision === null) return true;

    let count = 0;
    for (const prop in supervision)
      if (Object.prototype.hasOwnProperty.call(supervision, prop))
        if (supervision[prop]) count = count + 1;

    return count === 0;
  }

  formatTime(seconds: number) {
    const minutes = (seconds - (seconds % 60)) / 60;
    const secondsNoFormat = seconds % 60;
    return minutes
      .toString()
      .concat(':')
      .concat(
        secondsNoFormat > 9
          ? secondsNoFormat.toString()
          : '0'.concat(secondsNoFormat.toString())
      );

    // return (
    //   (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds
    // );
  }

  getPlayerInfo(event: any, type: string) {
    if (event.event === 'faceOff') return this.findPlayer(event.winnerId)[type];
    if (event.event === 'shot') return this.findPlayer(event.playerId)[type];
    if (event.event === 'zoneEntry')
      return this.findPlayer(event.playerId)[type];
    if (event.event === 'dumpIn') return this.findPlayer(event.playerId)[type];
    if (event.event === 'zoneExit')
      return this.findPlayer(event.playerId)[type];
    if (event.event === 'dumpOut') return this.findPlayer(event.playerId)[type];
    if (event.event === 'offensiveZoneLoss')
      return this.findPlayer(event.playerId)[type];
    if (event.event === 'hit') return this.findPlayer(event.hitter)[type];
    if (event.event === 'penalty')
      return this.findPlayer(event.disciplinedPlayerId)[type];
    if (event.event === 'shootout' || event.event === 'penaltyShootout')
      return this.findPlayer(event.shooterId)[type];
    if (event.event === 'topMoment')
      return this.findPlayer(event.players[0])[type];
  }

  findPlayer(id: number) {
    let player_selected = [];

    this.roster_home.forEach((player) => {
      if (player.id === id) player_selected = player;
    });

    this.roster_away.forEach((player) => {
      if (player.id === id) player_selected = player;
    });

    return player_selected;
  }

  getPlayerTeam(player_id: number) {
    let team = '';
    this.roster_home.forEach((player) => {
      if (player.id === player_id) team = 'home';
    });
    this.roster_away.forEach((player) => {
      if (player.id === player_id) team = 'away';
    });
    if (team === 'home') return this.game.gameData.homeTeam.name;
    if (team === 'away') return this.game.gameData.awayTeam.name;
    return '???';
  }

  getPlayerTeam2(player_id: number) {
    let team = '';
    this.roster_home.forEach((player) => {
      if (player.id === player_id) team = 'home';
    });
    this.roster_away.forEach((player) => {
      if (player.id === player_id) team = 'away';
    });
    return team;
  }

  editEvent(event: any) {
    this.editData = event;
    //this.relogCheck2();
    this.toggle_adding_nav = true;

    if (event.event === 'faceOff') {
      if (event.time !== 3599) {
        this.onSetCasomiraByEvent.emit(event.time + 1);
      } else {
        this.onSetCasomiraByEvent.emit(event.time);
      }
      this.onToggleCanvas.emit('faceOff');
    } else if (event.event === 'shot') {
      this.onSetCasomiraByEvent.emit(event.time);
      const shotId = event.shotId;
      const topMoments = this.eventflow.filter(
        (x) => x.event === 'topMoment' && x.shotId === shotId
      );
      event['topMoments'] = topMoments;
      if (event.shotTimeBackup?.toString().length > 0) {
        event.time = event.shotTimeBackup;
      }
      this.onToggleCanvas.emit('shot');
    } else if (event.event === 'zoneEntry') {
      this.onSetCasomiraByEvent.emit(event.time);
      this.onToggleCanvas.emit('zoneEntry');
    } else if (event.event === 'dumpIn') {
      this.onSetCasomiraByEvent.emit(event.time);
      this.onToggleCanvas.emit('dumpIn');
    } else if (event.event === 'zoneExit') {
      this.onSetCasomiraByEvent.emit(event.time);
      this.onToggleCanvas.emit('zoneExit');
    } else if (event.event === 'dumpOut') {
      this.onSetCasomiraByEvent.emit(event.time);
      this.onToggleCanvas.emit('dumpOut');
    } else if (event.event === 'offensiveZoneLoss') {
      this.onSetCasomiraByEvent.emit(event.time);
      this.onToggleCanvas.emit('offensiveZoneLoss');
    } else if (event.event === 'hit') {
      this.onSetCasomiraByEvent.emit(event.time);
      this.onToggleCanvas.emit('hit');
    } else if (event.event === 'shootout') {
      this.onToggleCanvas.emit('shootout');
    } else if (event.event === 'penalty') {
      this.onToggleCanvas.emit('penalty');
      this.onSetCasomiraByEvent.emit(event.created);
    } else if (event.event === 'topMoment') {
      this.onSetCasomiraByEvent.emit(event.time);
      // let shotId = event.shotId;
      // let topMoments =this.eventflow.filter( x=>  x.event === "topMoment"  &&  x.shotId === shotId );
      //event['topMoments'] = topMoments
      this.onToggleCanvas.emit('topMoment');
    } else if (event.event === 'penaltyShootout') {
      this.onToggleCanvas.emit('penaltyShootout');
    }
    this.onEditDataChange.emit(this.editData);
  }

  detectEdit(edit: any) {
    if (this.pageType === 'tracking') return edit.tracking === null;
    else if (this.pageType === 'supervize') return edit.supervision === null;
    return true;
  }

  getInfoButtonClass(event: any): string {
    if (event.error?.corrected) return 'success-btn';
    if (event.error?.type === 'warning' && this.pageType === 'supervize')
      return 'warning-btn';
    if (event.error?.type === 'error' && this.pageType === 'supervize')
      return 'error-btn';
    return 'info-btn';
  }

  correctEventError(event): void {
    if (this.getInfoButtonClass(event) !== 'warning-btn') return;

    let type: string = event.event;
    let eventId: number = event.shotId;

    if (
      type === null ||
      type === undefined ||
      eventId === null ||
      eventId === undefined
    ) {
      this.toastr.error(
        'Tento záznam nemá typ, anebo ID záznamu, takže jej nemůžeme opravit.',
        'Chyba!',
        {
          progressBar: true,
        }
      );
      console.warn('Event nemá typ, nebo ID!');
      return;
    }

    this.onLoading.emit(true);
    this.eventFlowService.correctEventError(this.id, eventId, type).subscribe(
      () => this.loadEventFlow(),
      (error) => {
        console.error('Correct event error:', error);
        this.toastr.error('Během opravy eventu došlo k chybě.', 'Chyba!', {
          progressBar: true,
        });
      },
      () => this.onLoading.emit(false)
    );
  }

  getInfoMessage(event: any): string {
    this.title = '';
    const errorsPlaceholders = ['XXX1', 'XXX2'];
    const errors = ['warning', 'error'];
    const errorTypes = new Map<string, string>([
      ['warning', 'Upozornění'],
      ['error', 'Chyba'],
    ]);

    errorsText.forEach((obj) => {
      if (
        event.error !== null &&
        obj.type === event.error?.type &&
        obj.rule === event.error?.rule
      ) {
        const titlePrefix = obj.type
          .concat(' ')
          .concat('#')
          .concat(obj.rule.toString())
          .concat(' ');
        this.title = titlePrefix + obj.text;
        if (this.title.includes(errorsPlaceholders[0])) {
          this.title = this.title.replace(
            errorsPlaceholders[0],
            event.error.prev_time !== 0
              ? this.utilService.formatTime(event.error.prev_time)
              : '0'
          );
          this.title = this.title.replace(
            errorsPlaceholders[1],
            event.time !== 0 ? this.utilService.formatTime(event.time) : '0'
          );
        }
        this.title = this.title.includes(errors[0])
          ? this.title.replace(errors[0], errorTypes.get(errors[0]))
          : (this.title = this.title.replace(
              errors[1],
              errorTypes.get(errors[1])
            ));
        return;
      }
    });
    return this.title;
  }

  removeEvent(event: any) {
    let remove_id;
    let array = [
      {
        name: 'faceOff',
        action: this.faceOffService.removeFaceOff(this.id, event.faceOffId),
      },
      {
        name: 'topMoment',
        action: this.shotService.deleteTopMoment(this.id, event.topMomentId),
      },
      {
        name: 'zoneEntry',
        action: this.zoneEntryService.removeZoneEntry(
          this.id,
          event.zoneEntryId
        ),
      },
      {
        name: 'dumpIn',
        action: this.dumpInService.removeDumpIn(this.id, event.dumpInId),
      },
      {
        name: 'zoneExit',
        action: (remove_id = this.zoneExitService.removeZoneExit(
          this.id,
          event.zoneExitId
        )),
      },
      {
        name: 'dumpOut',
        action: this.dumpOutService.removeDumpOut(this.id, event.dumpOutId),
      },
      {
        name: 'offensizeZoneLoss',
        action: this.offensiveZoneLossService.removeOffensiveZoneLoss(
          this.id,
          event.offensiveZoneLossId
        ),
      },
      { name: 'hit', action: this.hitService.removeHit(this.id, event.hitId) },
    ];

    if (!confirm('Opravdu si přejete vybranou událost smazat?')) return;

    if (event.event === 'shot') {
      remove_id = event.shotId;
      this.checkPassOrPuckWonContainsShotId(remove_id);
      if (this.passIdForDeletion || this.puckwonIdForDeletion) {
        forkJoin([
          this.shotService.removeShot(this.id, remove_id),
          this.passIdForDeletion
            ? this.passService.removePass(this.id, this.passIdForDeletion)
            : this.puckWonService.removePuckWon(
                this.id,
                this.puckwonIdForDeletion
              ),
        ]).subscribe(
          () => this.toastSuccess(),
          (error) => this.toastError(error, 'shot 1')
        );
        return;
      }
      this.shotService.removeShot(this.id, remove_id).subscribe(
        () => this.toastSuccess(),
        (error) => this.toastError(error, 'shot 2')
      );
      return;
    }

    array.forEach((item) => {
      if (event.event !== item.name) return;
      item.action.subscribe(
        () => this.toastSuccess(),
        (error) => this.toastError(error, item.name)
      );
    });
  }

  toastSuccess(): void {
    this.toastr.success(
      'Událost ID:' + this.id + ' byla úspěšně smazána.',
      'Výborně!',
      { progressBar: true }
    );
    this.onClose.emit(undefined);
  }

  toastError(error, type): void {
    this.toastr.warning(
      'Během mazání události ID:' + this.id + ' došlo k chybě.',
      'Chyba!',
      { progressBar: true }
    );
    console.error('Chyba při mazání záznamu typu', type);
    console.error('Error:', error);
    this.onClose.emit(undefined);
  }

  checkPassOrPuckWonContainsShotId(shotId: string) {
    this.eventflow.forEach((item) => {
      if (
        MatchTypes.PASS === item.event &&
        shotId === item.shotId &&
        item.error !== null
      )
        this.passIdForDeletion = item.passId;
      else if (
        MatchTypes.PUCKWON === item.event &&
        item.shotId &&
        item.error !== null
      )
        this.puckwonIdForDeletion = item.puckWonId;
    });
  }
}
