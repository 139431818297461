<div oncontextmenu="return false" class="event-edit">
  <button class="close" (click)="onClose()">Zavřít bez uložení</button>
  <ng-container *ngIf="inputData; else noData">
    <span class="event-name">{{ inputData?.event }}</span>
    <div class="time">{{ time }}</div>
    <div class="select-wrapper" *ngFor="let column of columns; let i = index">
      <label class="label text">{{ column.name }}</label>
      <ng-select
        class="select"
        [(ngModel)]="column.value"
        dropdownPosition="bottom"
        bindLabel="search"
        bindValue="id"
      >
        <ng-option
          *ngFor="let item of getPlayersByTeamId(column.teamId)"
          [value]="item.id"
          class="option-item"
        >
          <div class="item-wrapper">
            <div class="left-side">
              <img
                *ngIf="item.id"
                src="/assets/image/logos/{{ item.team.shortcut }}.png"
                onerror="this.src='/assets/image/logos/default.png';"
                class="logo-image"
              />
              <span class="jersey-item">{{ item.jersey }}</span>
              <span class="name-item">{{ item.name }} {{ item.surname }}</span>
            </div>
            <span class="position-item">
              {{
                item.position === "goalkeeper"
                  ? "G"
                  : item.position === "forward"
                  ? "Ú"
                  : "O"
              }}
            </span>
          </div>
        </ng-option>
      </ng-select>
    </div>
    <div class="action-buttons">
      <button class="supervize-button" (click)="onSupervizeClick()">
        K supervizi
      </button>
      <button class="save-button" (click)="onSaveClick()">Uložit záznam</button>
    </div>
  </ng-container>

  <ng-template #noData class="no-data">
    <span>Nejsou data</span>
  </ng-template>
</div>
