<div class="dialog-wrapper">
    <span class="note">Nastavte strany pro začátek zápasu</span>
    <div class="sides-wrapper">
        <div class="side">
            <div class="logo left">
                <img src="/assets/image/logos/{{ leftSide.shortcut }}.png" onerror="this.src='/assets/image/logos/default.png'"/>
            </div>
            <span class="name">{{ leftSide.shortName }}</span>
        </div>
        <div class="side">
            <span class="name">{{ rightSide.shortName }}</span>
            <div class="logo right">
                <img src="/assets/image/logos/{{ rightSide.shortcut }}.png" onerror="this.src='/assets/image/logos/default.png'"/>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button class="button" (click)="onChangeSites()">
            Změnit strany <img src="/assets/image/switch-side.svg" />
        </button>
        <button class="button" (click)="onSaveClick()">
            Uložit strany
        </button>
    </div>
</div>

