/**
 * Calcultes the period from the seconds given as the parameter.
 * @param seconds Seconds used to calculate the period.
 * @returns a number of the period.
 */
export function getPeriodNumber(seconds: number, overtimeLength: number): number {
  if (seconds > -1 && seconds < 1200) return 1;
  if (seconds >= 1200 && seconds < 2400) return 2;
  if (seconds >= 2400 && seconds < 3600) return 3;
  if (seconds >= 3600 && seconds < 3600 + overtimeLength) return 4;
  return Math.ceil((seconds - 3600) / overtimeLength) + 3;
}

/**
 * Calculates the time on the timer.
 * @param seconds Seconds for the calculation of the result
 * @param overtimeLength Length of the overtime
 * @returns a number calculated from the seconds and the length of the overtime.
 */
export function getCasomira(seconds: number, overtimeLength: number) {
    const period = getPeriodNumber(seconds, overtimeLength);
    if(period <= 3) return 1200 * period - seconds;
    if(period === 4) return 3600 + overtimeLength - seconds;
    return (3600 + (period - 3) * overtimeLength) - seconds;
}

/**
 * Recounts the given coordinates and returns the pixel value.
 */
export function recountCoordinates(x: number, y: number, coordinate: string, reversed_sides: boolean) {
    x -= 270;
    y -= 270 / 2;

    if (!reversed_sides) {
        x = remap(x, -270, 270, -100, 100);
        y = remap(y, -135, 135, -100, 100) * -1;
    } else {
        x = remap(x, -270, 270, -100, 100) * -1;
        y = remap(y, -135, 135, -100, 100);
    }

    return coordinate === 'x' ? Math.round(x) : Math.round(y);
}

/**
 * Remaps the coordinates into the pixels.
 */
export function remap(value: number, in_min: number, in_max: number, out_min: number, out_max: number) {
  return Math.ceil(((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min);
}

export function getPlayerTemplate(id: number, roster_home: any[], roster_away: any[]) {
  let player_selected = '';

  let team = getPlayerTeamShortcut(id, roster_home, roster_away);

  roster_home.forEach(player => {
    if (player.id === id) {
      player_selected =
        '#' +
        player.jersey +
        ' ' +
        player.surname +
        ' ' +
        player.name +
        ' (' +
        team +
        ')';
    }
  });

  roster_away.forEach(player => {
    if (player.id === id) {
      player_selected =
        '#' +
        player.jersey +
        ' ' +
        player.surname +
        ' ' +
        player.name +
        ' (' +
        team +
        ')';
    }
  });

  return String(player_selected);
}

export function getPlayerTeam(id: number, roster_home: any[], roster_away: any[]) {
  let player_selected = '';

  roster_home.forEach(player => {
    if (player.id === id) {
      player_selected = player.team;
    }
  });

  roster_away.forEach(player => {
    if (player.id === id) {
      player_selected = player.team;
    }
  });

  return player_selected;
}

export function getPlayerTeamShortcut(id: number, roster_home: any[], roster_away: any[]) {
    let player_selected = '';

    roster_home.forEach(player => {
      if (player.id === id)
        player_selected = player.team_shortcut;
    });

    roster_away.forEach(player => {
      if (player.id === id)
        player_selected = player.team_shortcut;
    });

    return player_selected;
}

export function getOwnTeamRoster(player: string, roster_home: any[], roster_away: any[], roster_all: any[]) {
    let type = getPlayerTeam(Number(player), roster_home, roster_away);

    if (type === 'home')
      return roster_home;

    if (type === 'away')
      return roster_away;

    return roster_all;
}

export function getOpponentRoster(player: string, roster_home: any[], roster_away: any[], roster_all: any[]) {
  let type = getPlayerTeam(Number(player), roster_home, roster_away);

  if (type === 'home')
    return roster_away;

  if (type === 'away')
    return roster_home;

  return roster_all;
}

export function formatRosters(roster_home: any[], roster_away: any[]) {
    let array: any[] = [];

    roster_home.forEach(player => {
      player.team = 'home';
      player.search = player.name + ' ' + player.surname + '' + player.jersey;

      array.push(player);
    });

    roster_away.forEach(player => {
      player.team = 'away';
      player.search = player.name + ' ' + player.surname + '' + player.jersey;

      array.push(player);
    });

    return array;
}

/**
 * Formates the position into the signle formatted character.
 * @param position Given position to be formatted.
 * @returns A single character formatted from the position.
 */
export function formatPosition(position: string) {
  if (position === 'forward')
    return 'Ú';

  if (position === 'backward')
    return 'O';

  if (position === 'goalkeeper')
    return 'B';
}