import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { DefaultService } from '../../services/default.service';
import { TimelineService } from '../../services/timeline.service';
import { ShiftService } from '../../services/shift.service';
import * as data from '../../../lang.json';
import { EventType } from '../../enums/match-type.enum';
import { EventFlowService } from '../../services/eventflow.service';
import { forkJoin } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  providers: [DefaultService, TimelineService, ShiftService, EventFlowService],
})
export class TimelineComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private defaultService: DefaultService,
    private timelineService: TimelineService,
    private shiftService: ShiftService,
    private eventFlowService: EventFlowService
  ) {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
  }
  @ViewChild('scrollView') private scrollView: ElementRef;

  id: number;

  width: number = 300;
  left: number = 20;
  public style: object = {};

  show_edit_dialog: boolean = false;
  show_addshift_dialog: boolean = false;
  dialog_x: number;
  dialog_y: number;
  edit_player: boolean = false;
  edit_event: boolean = false;
  new_shift: boolean = false;
  from: string = '';
  to: string = '';
  selected_slot: number;

  disabledWatch: boolean = false;

  homeTeamId: number;
  awayTeamId: number;
  homeTeamName: string = '';
  awayTeamName: string = '';
  selected_team: string = 'home';
  selected_type: string = 'all';
  gameState: any = [];
  shots: any = [];
  faceOffs: any = [];
  blocks = [];
  hits = [];
  shifts: any = [];

  matchData: any = [];

  gameLength: number = 0;

  slots: any = [];

  roster_home: any = [];
  roster_away: any = [];

  loading: boolean = false;
  loadingLabel: string = 'Načítám data';

  homeShifts: any = [];
  awayShifts: any = [];

  selected_player: string = '';
  selected_shift: any = [];

  slots_list: any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  active_camera: number = 1;

  problem_seconds: any = [];
  faceOff_seconds: any = [];
  shots_seconds: any = [];

  raw_move_time_from = '00:00';
  raw_move_time_to = '00:00';
  move_time_from = 0;
  move_time_to = 0;
  timeline = [];
  autosave = true;
  to_update = [];
  select_mode = false;
  select_from = 0;
  select_to = 0;
  selected_shifts = [];
  show_selected = false;

  selected_problem: number = 0;

  clicked_shift: number = null;
  langData: any;
  eventFlowData: any = [];
  eventTimeLineData: any = [];
  eventTimeLineDataToDisplay: any = [];
  eventArray: string[] = [];
  EXCLAMATION_MARK = '\u{0021}';

  // event edit - variables
  public show_event_edit_canvas: boolean = false;
  public eventEditInputData: any = null;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.disabledWatch = true;
    this.defaultService.removeWatch(this.id).subscribe(
      (data: any) => {
        this.toastr.success(
          'Sběr dat byl ukončen a zápas uzamčen.',
          'Výborně!',
          {
            progressBar: true,
          }
        );
      },
      (error) => {
        this.toastr.error('Během zamykání zápasu došlo k chybě.', 'Chyba!', {
          progressBar: true,
        });
      }
    );

    return false;
    // I have used return false but you can your other functions or any query or condition
  }

  ngOnInit(): void {
    this.langData = (data as any).default;
    this.loadData();
  }

  trackByShiftId = (slot: any): number => slot.shift;

  switchAutosave(save: boolean) {
    if (!save) {
      this.to_update = [];
    }
    this.autosave = save;
  }

  formatTimeFrom(time) {
    let times = [];
    times = time.split(':');
    let formated_time = Number(times[0]) * 60 + Number(times[1]);
    this.select_from = formated_time;
  }
  formatTimeTo(time) {
    let times = [];
    times = time.split(':');
    let formated_time = Number(times[0]) * 60 + Number(times[1]);
    this.select_to = formated_time;
  }

  moveAllShifts() {
    let filtered_data = [];
    let id = [];
    this.timeline.forEach((shift) => {
      shift.slots.forEach((item) => {
        let raw_data = {};
        if (item.length != 0) {
          if (id.length != 0 && !id.includes(item[0].shift.id)) {
            id.push(item[0].shift.id);
            raw_data['time'] = {};
            raw_data['playerId'] = item[0].playerId;
            raw_data['shiftId'] = item[0].shift.id;
            raw_data['time']['start'] =
              item[0].shift.start + this.move_time_from;
            if (raw_data['time']['start'] < 0) {
              raw_data['time']['start'] = 0;
            }
            raw_data['time']['end'] = item[0].shift.end + this.move_time_to;
            filtered_data.push(raw_data);
          } else if (id.length == 0) {
            id.push(item[0].shift.id);
            raw_data['time'] = {};
            raw_data['playerId'] = item[0].playerId;
            raw_data['shiftId'] = item[0].shift.id;
            raw_data['time']['start'] =
              item[0].shift.start + this.move_time_from;
            if (raw_data['time']['start'] < 0) {
              raw_data['time']['start'] = 0;
            }
            raw_data['time']['end'] = item[0].shift.end + this.move_time_to;
            filtered_data.push(raw_data);
          }
        }
      });
    });
    //this.changeAllShifts(filtered_data);
  }

  changeAllShifts(data: any) {
    /* this.shiftService.getShift(this.id).subscribe((data: any) => {
      console.log('Data', data);
      data.forEach((element) => {
        if (element.time.start < 0) {
          console.log('Shit data:', element);
        }
      });
    }); */
    this.loading = true;
    console.log('Data:', data);
    this.shiftService.updateShifts(data, this.id).subscribe(
      () => {
        this.toastr.success('Úprava všech střídání byla úspěšně uložena.');

        this.loading = false;
        this.reloadTimeline();
        this.to_update = [];
        this.selected_shifts = [];
      },
      (error) => {
        this.loading = false;
        this.reloadTimeline();
        console.error('Error while editting the shift:', error);
        this.toastr.error('Během úpravy střídání došlo k chybě.', 'Chyba!');
        this.to_update = [];
        this.selected_shifts = [];
      }
    );
  }

  showSelected() {
    this.show_selected = !this.show_selected;
  }

  deleteShift(i) {
    this.selected_shifts.splice(i, 1);
  }

  orderByName(names) {
    names.sort((a, b) => {
      if (a.surname < b.surname) {
        return -1;
      }
      if (a.surname > b.surname) {
        return 1;
      }
      return 0;
    });
    return names;
  }

  moveSelectedShifts() {
    this.selected_shifts.forEach((shift) => {
      if (shift.start + this.move_time_from < 0) {
        shift.start = 0;
      } else {
        shift.time.start = shift.time.start + this.move_time_from;
      }
      shift.time.end = shift.time.end + this.move_time_to;
    });

    this.changeAllShifts(this.selected_shifts);
  }

  activeShiftSelect() {
    this.select_mode = !this.select_mode;
    if (this.select_mode) {
      this.autosave = true;
    }
  }

  selectSelected() {
    let filtered_data = [];
    let id = [];
    this.selected_shifts = [];
    this.timeline.forEach((shift) => {
      shift.slots.forEach((item) => {
        let raw_data = {};
        if (item.length != 0) {
          if (!id.includes(item[0].shift.id)) {
            if (
              item[0].shift.start >= this.select_from &&
              item[0].shift.end <= this.select_to
            ) {
              id.push(item[0].shift.id);
              raw_data['playerId'] = item[0].playerId;
              raw_data['shiftId'] = item[0].shift.id;
              raw_data['hidden'] = item[0].shift.hidden;
              raw_data['time'] = {};
              raw_data['time']['start'] = item[0].shift.start;
              raw_data['time']['end'] = item[0].shift.end;
              this.selected_shifts.push(raw_data);
            }
          }
        }
      });
    });
  }

  loadData() {
    this.loading = true;
    forkJoin([
      this.eventFlowService.getEventflow(this.id),
      this.defaultService.getMatch(this.id),
    ]).subscribe(
      (forkData) => {
        console.log('Loaded Data Event', forkData[0], ' Data: ', forkData[1]);
        this.eventFlowData = forkData[0];
        this.matchData = forkData[1];
        this.roster_home = this.orderByName(this.matchData.roster.home);
        this.roster_away = this.orderByName(this.matchData.roster.away);
        this.homeTeamId = this.matchData.gameData.homeTeam.id;
        this.awayTeamId = this.matchData.gameData.awayTeam.id;
        this.homeTeamName = this.matchData.gameData.homeTeam.name;
        this.awayTeamName = this.matchData.gameData.awayTeam.name;
        this.timeline;
        if (this.selected_team === 'home') {
          this.loadHomeTimeline();
        } else {
          this.loadAwayTimeline();
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  checkFaceOffs() {
    this.shifts = [];
    let fixed_shifts = [];
    this.shiftService.getShift(this.id).subscribe((data: any) => {
      data.forEach((shift) => this.shifts.push(shift));

      this.faceOffs.forEach((faceOff) => {
        this.shifts.forEach((shift) => {
          if (
            shift.playerId == faceOff.winnerId ||
            shift.playerId == faceOff.loserId
          ) {
            if (
              shift.time.end < faceOff.time &&
              shift.time.end > faceOff.time - 5
            ) {
              console.log('Fixing...');
              console.log('Shift.id', shift.playerId);
              console.log('faceOff.winner', faceOff.winnerId);
              shift.time.end = faceOff.time;
              fixed_shifts.push(shift);
            }
          }
        });
      });
      console.log('fixed', fixed_shifts);
      if (fixed_shifts.length > 0) {
        this.changeAllShifts(fixed_shifts);
      }
      //this.changeAllShifts(fexed_shifts);
    });

    //
    //this.faceOffs.forEach((faceoff) => {});
  }

  checkDuplicates(toCheck) {
    let checked = [];
    toCheck.forEach((item, index) => {
      if (!checked.includes(item.shiftId)) {
        checked.push(item);
      } else checked.splice(index, 1);
    });
  }

  loadHomeTimeline() {
    this.loading = true;
    this.faceOff_seconds = [];
    this.shots_seconds = [];

    /**
     * call for timeline API for home team (blocks, faceOff, gameState, hits, shot, timeLIne)
     */
    this.timelineService.getTimeline(this.id, this.homeTeamId).subscribe(
      (data: any) => {
        console.log('Games data home team:', data);
        this.gameLength = data.gameState[data.gameState.length - 1].end;
        this.gameState = data.gameState;
        this.shots = data.shot;
        this.faceOffs = data.faceOff;
        // faceOffs = "stridani" udelame clone a k nemu pridame eventy
        //this.eventTimeLineData = cloneDeep(this.faceOffs);
        this.eventTimeLineData = [];
        console.log('faceOffs home', data.faceOff);
        console.log('eventTimeLineData home', this.eventTimeLineData);
        this.eventFlowData.forEach((event) => {
          this.eventTimeLineData.push({
            event: this.getEventTypeInCzech(event.event),
            eventEn: event.event,
            winnerId: this.getWinnerAndLoserIdByEvent(event, true),
            loserId: this.getWinnerAndLoserIdByEvent(event, false),
            time: event.time,
            passingPlayerId: event.passingPlayerId,
            playerId: event.playerId,
            stopperPlayerId: event.stopperPlayerId,
            hitter: event.hitter,
            denialPlayerId: event.denialPlayerId,
            puckLostPlayerId: event.puckLostPlayerId,
            gainSharePlayerId: event.gainSharePlayerId,
            goalkeeperId: event.goalkeeperId,
            players: this.getPlayersByEvent(event),
            originalData: { ...event }, // nemazat - dont delete this line !
          });
        });
        this.eventTimeLineData.sort((a, b) => (a.time <= b.time ? -1 : 0));
        this.eventTimeLineData = this.eventTimeLineData.filter(
          (e) => e.time <= this.gameLength
        );
        this.eventTimeLineData = this.eventTimeLineData.filter((event) => {
          return this.roster_home.some((rosterHome) => {
            const players = Object.keys(event.players).map(
              (key) => event.players[key]
            );
            // event.event undefined je pro stridani, kde neni nastavene
            return (
              players.indexOf(rosterHome.id) > -1 || event.event === undefined
            );
          });
        });
        this.loadEvents();
        this.eventTimeLineDataToDisplay = this.eventTimeLineData;
        console.log(
          'eventTimeLineData po zmene home team',
          this.eventTimeLineData
        );
        if (data.hits) {
          this.hits = data.hits;
        }
        if (data.blocks) {
          this.blocks = data.blocks;
        }

        this.faceOffs.forEach((faceoff) =>
          this.faceOff_seconds.push(faceoff.time)
        );
        this.shots.forEach((shot) => this.shots_seconds.push(shot.time));
        this.timeline = data.timeLine;

        this.checkFaceOffs();

        this.timelineService.getSlot(this.id, this.homeTeamId).subscribe(
          (data: any) => {
            this.slots = data;
            this.slots_list = Array.from(
              { length: data.length + 1 },
              (_, i) => i + 1
            );

            this.timelineService
              .getTimelineOfShifts(this.id, this.homeTeamId)
              .subscribe(
                (homeShiftsData: any) => {
                  this.homeShifts = homeShiftsData;

                  this.homeShifts.forEach((shift, index) => {
                    if (index <= this.homeShifts.length - 1) {
                      shift.timeTo =
                        typeof this.homeShifts[index + 1] != 'undefined'
                          ? this.homeShifts[index + 1].time
                          : 1000000;
                    }
                  });

                  this.findProblems();
                  this.loading = false;
                  this.optimizeShifts(data);
                },
                (error) => {
                  this.loading = false;
                }
              );
          },
          (error) => {
            this.loading = false;
          }
        );
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  /**
   * Loads the events and creates an array of it.
   */
  loadEvents() {
    this.eventTimeLineData.forEach((element) => {
      if (!this.eventArray.includes(element.eventEn))
        this.eventArray.push(element.eventEn);
    });
  }

  getEventTypeInCzech(eventType: string): string {
    const eventEnum = Object.keys(EventType);
    let type;
    eventEnum.forEach((e) => {
      if (e === eventType.toUpperCase()) {
        type = EventType[eventType.toUpperCase()];
      }
    });
    return type;
  }

  getWinnerAndLoserIdByEvent(event: any, winner: boolean): number {
    const eventEnum = Object.keys(EventType);
    switch (event.event.toUpperCase()) {
      case eventEnum[9]:
        return winner ? event.winnerId : event.loserId;
      case eventEnum[3]:
      case eventEnum[4]:
      case eventEnum[5]:
      case eventEnum[6]:
        return event.playerId;
      case eventEnum[7]:
      case eventEnum[2]:
        return winner ? event.playerId : event.puckLostPlayerId;
      case eventEnum[1]:
        return event.playerId;
      case eventEnum[0]:
        return winner ? event.playerId : event.passingPlayerId;
      case eventEnum[10]:
        return winner ? event.hitter : event.hitted;
    }
  }

  loadAwayTimeline() {
    this.loading = true;
    this.faceOff_seconds = [];
    this.shots_seconds = [];

    /**
     * call for timeline API for away team (blocks, faceOff, gameState, hits, shot, timeLIne)
     */
    this.timelineService.getTimeline(this.id, this.awayTeamId).subscribe(
      (data: any) => {
        console.log('Games data away team:', data);
        this.gameLength = data.gameState[data.gameState.length - 1].end;
        this.gameState = data.gameState;
        this.shots = data.shot;
        this.faceOffs = data.faceOff;
        //this.eventTimeLineData = cloneDeep(this.faceOffs);
        this.eventTimeLineData = [];
        console.log('faceOffs away', data.faceOff);
        console.log('eventTimeLineData away', this.eventTimeLineData);
        this.eventFlowData.forEach((event) => {
          this.eventTimeLineData.push({
            event: this.getEventTypeInCzech(event.event),
            winnerId: this.getWinnerAndLoserIdByEvent(event, true),
            loserId: this.getWinnerAndLoserIdByEvent(event, false),
            players: this.getPlayersByEvent(event),
            time: event.time,
            originalData: { ...event }, // nemazat - dont delete this line !
          });
        });
        this.eventTimeLineData.sort((a, b) => (a.time <= b.time ? -1 : 0));
        this.eventTimeLineData = this.eventTimeLineData.filter(
          (e) => e.time <= this.gameLength
        );
        this.eventTimeLineData = this.eventTimeLineData.filter((event) => {
          return this.roster_away.some((rosterAway) => {
            const players = Object.keys(event.players).map(
              (key) => event.players[key]
            );
            // event.event undefined je pro stridani, kde neni nastavene
            return (
              players.indexOf(rosterAway.id) > -1 || event.event === undefined
            );
          });
        });
        this.eventTimeLineDataToDisplay = this.eventTimeLineData;
        console.log(
          'eventTimeLineData po zmene away team',
          this.eventTimeLineData
        );
        if (data.hits) this.hits = data.hits;
        if (data.blocks) this.blocks = data.blocks;

        this.faceOffs.forEach((faceoff) =>
          this.faceOff_seconds.push(faceoff.time)
        );
        this.shots.forEach((shot) => this.shots_seconds.push(shot.time));

        this.timelineService.getSlot(this.id, this.awayTeamId).subscribe(
          (data: any) => {
            this.slots = data;
            this.slots_list = Array.from(
              { length: data.length + 1 },
              (_, i) => i + 1
            );

            this.timelineService
              .getTimelineOfShifts(this.id, this.awayTeamId)
              .subscribe(
                (awayShiftData: any) => {
                  this.awayShifts = awayShiftData;

                  this.awayShifts.forEach((shift, index) => {
                    if (index <= this.awayShifts.length - 1) {
                      if (typeof this.awayShifts[index + 1] != 'undefined') {
                        shift.timeTo = this.awayShifts[index + 1].time;
                      } else {
                        shift.timeTo = 1000000;
                      }
                    }
                  });

                  this.loading = false;
                  this.findProblems();
                  this.optimizeShifts(data);
                },
                (error) => {
                  this.loading = false;
                }
              );
          },
          (error) => {
            this.loading = false;
          }
        );
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  findCombinedShifts(data) {
    let combinedShifts = [];
    data.forEach((slot) => {
      slot.forEach((shift) => {
        let filteredShifts = this.filterShiftsByPlayer(shift);
        const relatedShiftInterval =
          this.computeIntervalOfAllRelatedShiftsToShift(shift, filteredShifts);

        filteredShifts = filteredShifts.filter(
          (s) =>
            s.time.start + 1 > relatedShiftInterval.start &&
            s.time.end - 1 < relatedShiftInterval.end
        );

        const crossesPeriod =
          filteredShifts.find((s) => {
            const periodStart = Math.floor(s.time.start / 1200);
            const periodEnd = Math.floor(s.time.end / 1200);
            return periodStart !== periodEnd;
          }) !== undefined;
        if (crossesPeriod) return;

        if (filteredShifts.length > 1) {
          combinedShifts.push(filteredShifts);
        }
      });
    });
    return combinedShifts;
  }

  async optimizeShifts(data) {
    this.loadingLabel = 'Optimalizuji střídání';
    this.loading = true;
    await this.removeDuplicatedShiftRecords(data);
    await this.splitShiftsCrossingBreakPoints(data);

    this.loadingLabel = 'Načítám data';
    this.loading = false;
  }

  async removeDuplicatedShiftRecords(data) {
    let combinedShifts = this.findCombinedShifts(data);
    const seenShiftIds = new Set<number>();
    const result: any[] = [];

    combinedShifts.forEach((shiftGroup) => {
      const uniqueShiftGroup = shiftGroup.filter((shift) => {
        if (seenShiftIds.has(shift.shiftId)) {
          return false;
        } else {
          seenShiftIds.add(shift.shiftId);
          return true;
        }
      });

      if (uniqueShiftGroup.length > 0) {
        result.push(uniqueShiftGroup);
      }
    });

    await this.uniteCombinedShifts(result);
  }

  async uniteCombinedShifts(combinedShifts: any) {
    if (combinedShifts.length === 0) return;

    for (const shiftArray of combinedShifts) {
      const shift = {
        ...shiftArray[0],
        from: shiftArray[0].time.start,
        to: shiftArray[0].time.end,
      };
      const relatedShiftInterval =
        this.computeIntervalOfAllRelatedShiftsToShift(shift, shiftArray);
      const shiftsRemoved = await this.removeShiftsOfArray(
        shiftArray,
        false,
        false
      );
      if (!shiftsRemoved) return;

      await this.addNewShift(
        shift.playerId,
        relatedShiftInterval.start,
        relatedShiftInterval.end,
        false,
        false
      );
      location.reload();
    }
  }

  findSeparatedShiftsByBE(data: any) {
    let separatedShifts = [];
    let unitedShifts = [];
    data.forEach((slot) => (unitedShifts = [...unitedShifts, ...slot]), []);
    unitedShifts = unitedShifts
      .sort((a, b) => a.to - b.to)
      .sort((a, b) => a.from - b.from);

    unitedShifts.forEach((shift) => {
      unitedShifts.forEach((shift2) => {
        if (
          shift.shift === shift2.shift &&
          shift.from === shift2.to &&
          !separatedShifts.includes(shift.shift)
        ) {
          separatedShifts.push(shift.shift);
        }
      });
    });
    return separatedShifts;
  }

  getSegmentedTimes(start, end, gameLength) {
    const regularPeriodLength = 1200;
    const periods = Math.floor(gameLength / regularPeriodLength);
    let segmentTimes = [start];

    for (let i = 1; i <= periods; i++) {
      const periodEnd = i * regularPeriodLength;
      if (periodEnd > start && periodEnd < end) {
        segmentTimes.push(periodEnd);
      }
    }

    segmentTimes.push(end);
    return segmentTimes;
  }

  async splitShiftsCrossingBreakPoints(data: any) {
    let separatedShifts = this.findSeparatedShiftsByBE(data);
    for (const shift of separatedShifts) {
      const originalShift = this.shifts.find((s) => s.shiftId === shift);
      if (!originalShift) return;

      const from = originalShift.time.start;
      const to = originalShift.time.end;
      const separationTimes = this.getSegmentedTimes(from, to, this.gameLength);
      const shiftWithOnlyId = { shiftId: shift };

      const shiftsRemoved = await this.removeShiftsOfArray(
        [shiftWithOnlyId],
        false,
        false,
        false
      );
      if (!shiftsRemoved) return;

      for (let i = 0; i < separationTimes.length - 1; i++) {
        await this.addNewShift(
          originalShift.playerId,
          separationTimes[i],
          separationTimes[i + 1],
          false,
          false
        );
      }
      this.toastr.info(
        'Byl proveden pokus o rozdělení střídání přes přestávku.',
        'Info'
      );
      this.reloadTimeline();
    }
  }

  reloadTimeline() {
    this.edit_player = false;
    this.edit_event = false;
    this.new_shift = false;

    this.from = '';
    this.to = '';
    this.selected_player = '';

    this.show_addshift_dialog = false;
    this.show_edit_dialog = false;

    if (this.selected_team == 'home') {
      this.loadHomeTimeline();
    } else {
      this.loadAwayTimeline();
    }
  }

  openVideo(second: number) {
    this.loading = true;
    this.timelineService
      .getVideoTime(this.id, second === 0 ? 1 : second)
      .subscribe(
        (data: any) => {
          this.defaultService
            .getVideoPlayer({
              matchId: this.id.toString(),
              videoType: 'full',
              seekTime: data.videoTime,
              extraControls: true,
            })
            .subscribe(
              (data) => {
                const videoWindow = window.open(
                  '',
                  '_blank',
                  'width=800,height=600'
                );

                if (videoWindow) {
                  videoWindow.document.open();
                  videoWindow.document.write(data || '');
                  videoWindow.document.close();
                } else {
                  console.error('Error while openning video');
                  this.toastr.error(
                    'Během spuštění videa došlo k chybě.',
                    'Chyba!'
                  );
                }
                this.loading = false;
              },
              (error) => {
                console.error('Error while getting video data:', error);
                this.toastr.error(
                  'Během spuštění videa došlo k chybě.',
                  'Chyba!'
                );
                this.loading = false;
              }
            );
        },
        (error) => {
          console.error('Error while getting video time:', error);
          this.toastr.error('Během spuštění videa došlo k chybě.', 'Chyba!');
          this.loading = false;
        }
      );
  }

  findProblems() {
    let problems = [];
    this.problem_seconds = [];

    this.slots.forEach((slot) => {
      slot.forEach((shift) => {
        if (shift.to - shift.from == 1) {
          problems.push(shift.from);
        }
      });
    });

    this.slots.forEach((slot) => {
      slot.forEach((shift) => {
        if (shift.overlayFrom > 4) {
          problems.push(shift.from);
        }
      });
    });

    // if (this.selected_team === 'home') {
    //   this.shots.forEach((shot) => {
    //     this.homeShifts.forEach((shift) => {
    //       if (shot.time >= shift.time && shot.time <= shift.timeTo) {
    //         let slots = [];
    //         shift.slots.forEach((slot) => {
    //           slot.forEach((slot2) => {
    //             slots.push(slot2);
    //           });
    //         });
    //         if (slots.includes(shot.player)) {
    //           //console.log("uff")
    //         } else {
    //           problems.push(shot.time);
    //         }
    //       }
    //     });
    //   });
    // } else if (this.selected_team === 'away') {
    //   this.shots.forEach((shot) => {
    //     this.awayShifts.forEach((shift) => {
    //       if (shot.time >= shift.time && shot.time <= shift.timeTo) {
    //         let slots = [];
    //         shift.slots.forEach((slot) => {
    //           slot.forEach((slot2) => {
    //             slots.push(slot2);
    //           });
    //         });
    //         if (slots.includes(shot.player)) {
    //           //console.log("uff")
    //         } else {
    //           console.log('problem');
    //           problems.push(shot.time);
    //         }
    //       }
    //     });
    //   });
    // }

    console.log('Problems before new code:', problems);

    if (this.selected_team === 'home') {
      const homeProblems = this.checkTeamShiftEventProblems(
        this.eventTimeLineData,
        this.homeShifts,
        this.roster_home
      );
      problems = problems.concat(homeProblems);
    }

    if (this.selected_team === 'away') {
      const awayProblems = this.checkTeamShiftEventProblems(
        this.eventTimeLineData,
        this.awayShifts,
        this.roster_away
      );
      problems = problems.concat(awayProblems);
    }

    // if (this.selected_team === 'home') {
    //   this.faceOffs.forEach((faceOff) => {
    //     this.homeShifts.forEach((shift) => {
    //       if (
    //         faceOff.time + 1 >= shift.time &&
    //         faceOff.time + 1 <= shift.timeTo
    //       ) {
    //         let slots = [];
    //         shift.slots.forEach((slot) => {
    //           slot.forEach((slot2) => {
    //             slots.push(slot2);
    //           });
    //         });
    //         if (
    //           slots.includes(faceOff.winnerId) ||
    //           slots.includes(faceOff.loserId)
    //         ) {
    //           //console.log("uff")
    //         } else {
    //           console.log('problem');
    //           console.log(
    //             JSON.stringify(shift) + '   ' + JSON.stringify(faceOff)
    //           );
    //           problems.push(faceOff.time);
    //         }
    //       }
    //     });
    //   });
    // } else if (this.selected_team === 'away') {
    //   this.faceOffs.forEach((faceOff) => {
    //     this.awayShifts.forEach((shift) => {
    //       if (
    //         faceOff.time + 1 >= shift.time &&
    //         faceOff.time + 1 <= shift.timeTo
    //       ) {
    //         let slots = [];
    //         shift.slots.forEach((slot) => {
    //           slot.forEach((slot2) => {
    //             slots.push(slot2);
    //           });
    //         });
    //         if (
    //           slots.includes(faceOff.winnerId) ||
    //           slots.includes(faceOff.loserId)
    //         ) {
    //           //console.log("uff")
    //         } else {
    //           console.log('problem');
    //           console.log(
    //             JSON.stringify(shift) + '   ' + JSON.stringify(faceOff)
    //           );
    //           problems.push(faceOff.time);
    //         }
    //       }
    //     });
    //   });
    // }

    this.problem_seconds = problems;
  }

  checkTeamShiftEventProblems = (timelineData, teamShifts, teamRosters) =>
    timelineData.reduce((timelineAcc, event) => {
      teamShifts.forEach((shift) => {
        if (event.time + 1 >= shift.time && event.time + 1 <= shift.timeTo) {
          const allActivePlayers = shift.slots.reduce((shiftAcc, slot) => [
            ...shiftAcc,
            ...slot.flat(),
          ]);
          const isEventOkay = Object.entries(event.players).reduce(
            (playerAcc, entry) => {
              const playerId = entry[1];
              if (
                allActivePlayers.some(
                  (activePlayer) => +activePlayer.playerId === playerId
                ) &&
                teamRosters.some((roster) => roster.id == playerId)
              ) {
                playerAcc = true;
              }
              return playerAcc;
            },
            false
          );

          if (!isEventOkay) {
            timelineAcc.push(event.time);
          }
        }
      });
      return timelineAcc;
    }, []);

  checkProblem = (second: number) => this.problem_seconds.includes(second);

  checkFaceOff = (second: number) => this.faceOff_seconds.includes(second);

  checkShot = (second: number) => this.shots_seconds.includes(second);

  jumpToProblem() {
    let problem_seconds = this.problem_seconds;
    problem_seconds.sort((a, b) => a - b);

    if (this.selected_problem + 1 > problem_seconds.length) {
      this.selected_problem = 0;
    }

    if (problem_seconds.length > 0) {
      this.selected_problem = this.selected_problem + 1;
      this.scrollView.nativeElement.scrollTo({
        left: problem_seconds[this.selected_problem - 1] * 20,
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  onTeamChange($event) {
    if (this.selected_team == 'home') this.loadHomeTimeline();
    else this.loadAwayTimeline();
  }

  /**
   * Changes selected type by user selection of the type select.
   */
  onTypeChange() {
    this.eventTimeLineDataToDisplay =
      this.selected_type === 'all'
        ? this.eventTimeLineData
        : this.eventTimeLineData.filter(
            (item) => item.eventEn === this.selected_type
          );
  }

  getRoster = () =>
    this.selected_team == 'home' ? this.roster_home : this.roster_away;

  getSlot(slot: number) {
    if (slot - 1 < this.slots.length) return this.slots[slot - 1];

    const hiddenShifts = this.shifts.filter((shift) => shift.hidden);
    return hiddenShifts.map((hiddenShift) => {
      const edittedShift = this.to_update.find(
        (shift) => shift.shiftId == hiddenShift.shiftId
      );
      let from = hiddenShift.time.start;
      let to = hiddenShift.time.end;

      if (edittedShift) {
        from = edittedShift.time.start;
        to = edittedShift.time.end;
      }

      return {
        from,
        to,
        player: hiddenShift.playerId,
        shift: hiddenShift.shiftId,
        overlayTo: 0,
        overlayFrom: 0,
      };
    });
  }

  getGameLength() {
    return Array.from(Array(this.gameLength).keys());
  }

  formatTime(seconds: number) {
    return (
      (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds
    );
  }

  getPlayerName(id: number) {
    let name = '';
    this.roster_home.forEach((player) => {
      if (player.id == id) {
        name = player.surname;
      }
    });

    this.roster_away.forEach((player) => {
      if (player.id == id) {
        name = player.surname;
      }
    });

    return name;
  }

  selectShift(id: number) {
    if (this.clicked_shift == id) {
      this.clicked_shift = null;
    } else {
      setTimeout(() => {
        this.clicked_shift = id;
      }, 300);
    }
  }

  getEventType(): string {
    return EventType.SHIFT;
  }

  getPlayerNumber(id: number) {
    let jersey = '';
    this.roster_home.forEach((player) => {
      if (player.id == id) {
        jersey = player.jersey;
      }
    });

    this.roster_away.forEach((player) => {
      if (player.id == id) {
        jersey = player.jersey;
      }
    });

    return jersey;
  }

  calcPos = (seconds: number) => seconds * 20;

  calcWidth = (seconds: number) => seconds * 20;

  async onResizeEnd(
    event: ResizeEvent,
    shift: any,
    slot: number,
    index: number
  ): Promise<void> {
    console.log('Event:', event);
    console.log('Shift:', shift);
    console.log('Slot:', slot);
    console.log('index:', index);
    this.style = {
      position: 'absolute',
      left: `${event.rectangle.left}px`,
      top: `${event.rectangle.top}px`,
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`,
    };

    this.width = event.rectangle.width;
    this.left = event.rectangle.left;

    let start = 0;
    let end = 0;

    if (event.rectangle.left > 0) {
      start = event.rectangle.left / 20;
    }

    if (event.rectangle.width > 0) {
      end = start + event.rectangle.width / 20;
    }

    const slotShifts = this.getSlot(slot);
    slotShifts[index]['from'] = start;
    slotShifts[index]['to'] = end;

    shift.from = start;
    shift.to = end;
    let filteredShifts = this.filterShiftsByPlayer(shift);

    const relatedShiftInterval = this.computeIntervalOfAllRelatedShiftsToShift(
      shift,
      filteredShifts
    );

    filteredShifts = filteredShifts.filter(
      (s) =>
        s.time.start + 1 > relatedShiftInterval.start &&
        s.time.end - 1 < relatedShiftInterval.end
    );
    if (filteredShifts.length > 1) {
      const shiftsRemoved = await this.removeShiftsOfArray(
        filteredShifts,
        false,
        true,
        false
      );
      if (!shiftsRemoved) return;

      await this.addNewShift(
        filteredShifts[0].playerId,
        relatedShiftInterval.start,
        relatedShiftInterval.end
      );
      return;
    }

    if (this.autosave) {
      this.updateShift(shift.player, shift.shift, start, end);
      return;
    }

    let wasChanged = false;
    this.to_update.forEach((item) => {
      if (item.shiftId == shift.shift) {
        wasChanged = true;
        item.time.start = start;
        item.time.end = end;
      }
    });
    if (!wasChanged) {
      this.to_update.push({
        playerId: shift.player,
        shiftId: shift.shift,
        time: {
          start: start,
          end: end,
        },
      });
    }
  }

  saveSelected() {
    console.log('To update:', this.to_update);
    /* this.to_update.forEach((shift) => {
      this.updateShift(shift.player, shift.shift, shift.start, shift.end);
    }); */

    this.changeAllShifts(this.to_update);
  }

  updateShift(playerId: any, shift: number, start: number, end: number) {
    let data = {
      playerId: playerId,
      time: {
        start,
        end,
      },
      app: 'tracking',
    };

    this.loading = true;
    this.shiftService.updateShift(this.id, shift, data).subscribe(
      (data: any) => {
        this.toastr.success(
          'Úprava střídání byla úspěšně uložena.',
          'Výborně!'
        );

        this.loading = false;
        this.reloadTimeline();
      },
      (error) => {
        this.loading = false;
        this.reloadTimeline();
        this.toastr.error('Během úpravy střídání došlo k chybě.', 'Chyba!');
      }
    );
  }

  async addNewShift(
    playerId: any,
    start: number,
    end: number,
    reloadTimeline: boolean = true,
    showToastMessage: boolean = true
  ) {
    if (playerId == '') {
      this.toastr.error('Není vyplěný hráč.', 'Chyba!');
      return;
    }
    if (start == null) {
      this.toastr.error('Začátek střídání není správně vyplněn.', 'Chyba!');
      return;
    }
    if (end == null) {
      this.toastr.error('Konec střídání není správně vyplněn.', 'Chyba!');
      return;
    }

    let data = {
      playerId: playerId,
      time: {
        start,
        end,
      },
      app: 'tracking',
    };

    this.loading = true;
    this.shiftService.createShift(this.id, data).subscribe(
      () => {
        if (showToastMessage)
          this.toastr.success(
            'Nové střídání bylo úspěšně přidáno.',
            'Výborně!'
          );

        this.loading = false;
        if (reloadTimeline) this.reloadTimeline();
      },
      (error) => {
        this.loading = false;
        if (reloadTimeline) this.reloadTimeline();
        console.error('Error while adding new shift:', error);
        this.toastr.error(
          'Během přidávání nového střídání došlo k chybě.',
          'Chyba!'
        );
      }
    );
  }

  submitNewShift() {
    let froms = [];
    froms = this.from.split(':');
    let from = Number(froms[0]) * 60 + Number(froms[1]);

    let tos = [];
    tos = this.to.split(':');
    let to = Number(tos[0]) * 60 + Number(tos[1]);

    this.addNewShift(this.selected_player, from, to);
  }

  resizing(event: ResizeEvent) {
    /*
    this.style = {
      position: 'absolute',
      left: `${event.rectangle.left}px`,
      top: `${event.rectangle.top}px`,
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };

    this.width = event.rectangle.width;
    this.left = event.rectangle.left;
    */
  }

  goToSelect() {
    this.router.navigate(['/select']);
  }

  getShiftWidthClass(width: number) {
    if (width <= 60) return 'small2';
    if (width <= 160) return 'small';
    return 'normal';
  }

  editShift(event, slot: any) {
    this.edit_player = false;
    this.edit_event = false;
    this.new_shift = false;

    this.show_edit_dialog = true;
    this.show_addshift_dialog = false;
    this.new_shift = false;
    this.dialog_x = event.clientX;
    this.dialog_y = event.clientY - 100;

    this.from = this.formatTime(
      Math.ceil(
        (event.clientX + this.scrollView.nativeElement.scrollLeft - 140) / 20
      ) - 1
    );

    //this.selected_slot = selected_slot;

    this.selected_player = slot.player;
    this.selected_shift = slot;

    return false;
  }

  getAllMovingShiftsFromShiftSlot(shift: any) {
    let filteredShifts = this.filterShiftsByPlayer(shift);
    const relatedShiftInterval = this.computeIntervalOfAllRelatedShiftsToShift(
      shift,
      filteredShifts
    );

    filteredShifts = filteredShifts.filter(
      (s) =>
        s.time.start + 1 > relatedShiftInterval.start &&
        s.time.end - 1 < relatedShiftInterval.end
    );

    return filteredShifts;
  }

  filterShiftsByPlayer(shift: any) {
    let filteredShifts = this.shifts.filter(
      (s) => s.playerId === shift.player && !s.hidden
    );
    const indexOfShift = filteredShifts.findIndex(
      (s) => s.shift === shift.shiftId
    );
    return filteredShifts.slice(indexOfShift);
  }

  computeIntervalOfAllRelatedShiftsToShift(shift: any, filteredShifts: any[]) {
    let start = shift.from;
    let end = shift.to;

    filteredShifts.forEach(() => {
      let occurences = 0;

      filteredShifts.forEach((s) => {
        if (s.time.start - 1 < end && s.time.end > end) {
          end = s.time.end;
          occurences++;
        } else if (s.time.end + 1 > start && s.time.start - 1 < start) {
          start = s.time.start;
          occurences++;
        } else if (s.time.start < start && s.time.end > end) {
          start = s.time.start;
          end = s.time.end;
          occurences++;
        }
      });

      if (occurences === 0) return;
    });

    return { start, end };
  }

  toggleEditPlayer() {
    this.edit_player = !this.edit_player;
  }

  toggleNewShift() {
    this.new_shift = !this.new_shift;
  }

  toggleEditEvent() {
    alert('TOTO SE MÁ ZOBRAZIT JEN U CHYBNÉ UDÁLOSTI.');
    this.edit_event = !this.edit_event;
  }

  toggleShiftFreeSlot() {
    const hideShift =
      this.slots.find((slotArr) =>
        slotArr.some((slot) => slot.shift === this.selected_shift.shift)
      ) !== undefined;

    if (
      !confirm(
        'Opravdu chcete vybrané střídání přesunout do / z volného slotu?'
      )
    )
      return;

    let data = {
      playerId: this.selected_shift.player,
      time: {
        start: this.selected_shift.from,
        end: this.selected_shift.to,
      },
      hidden: hideShift,
      app: 'tracking',
    };
    this.loading = true;
    this.shiftService
      .updateShift(this.id, this.selected_shift.shift, data)
      .subscribe(
        () => {
          this.toastr.success(
            'Úprava střídání byla úspěšně uložena.',
            'Výborně!'
          );
          this.loading = false;
          const index = this.shifts.findIndex(
            (s) => s.shiftId === this.selected_shift.shift
          );
          this.shifts[index].hidden = hideShift;
          this.reloadTimeline();
        },
        (error) => {
          this.loading = false;
          console.error('Error while adding to free slot:', error);
          this.reloadTimeline();
          this.toastr.error('Během úpravy střídání došlo k chybě.', 'Chyba!');
        }
      );
  }

  removeShift() {
    if (confirm('Opravdu chcete vybrané střídání smazat?')) {
      this.shiftService
        .removeShift(this.id, this.selected_shift.shift)
        .subscribe(
          (data: any) => {
            this.toastr.success('Střídání bylo úspěšně smazáno.', 'Výborně!');

            this.loading = false;
            this.reloadTimeline();
          },
          (error) => {
            this.loading = false;
            this.reloadTimeline();
            this.toastr.error('Během mazání střídání došlo k chybě.', 'Chyba!');
          }
        );
    }
  }

  async removeShiftsOfArray(
    shifts: any[],
    showConfirm = true,
    showLoading = true,
    reloadTimeline = true
  ): Promise<boolean> {
    let error = false;

    if (
      (showConfirm && confirm('Opravdu chcete vybrané střídání smazat?')) ||
      !showConfirm
    ) {
      if (showLoading) this.loading = true;
      for (let index = 0; index < shifts.length; index++) {
        const shift = shifts[index];

        try {
          await this.shiftService
            .removeShift(this.id, shift.shiftId)
            .toPromise();

          if (index === shifts.length - 1) {
            if (showLoading) this.loading = false;
            if (reloadTimeline) this.reloadTimeline();
            if (!error) {
              this.toastr.success(
                'Spojovaná střídání úspěšně vymazána.',
                'Výborně!'
              );
            } else {
              this.toastr.error(
                'Během mazání spojovaných střídání došlo k chybě.',
                'Chyba!'
              );
            }
          }
        } catch (err) {
          error = true;
          console.error(
            'Error while removing shifts of array:',
            shifts,
            '\nError:',
            err
          );

          if (index === shifts.length - 1) {
            if (showLoading) this.loading = false;
            if (reloadTimeline) this.reloadTimeline();
            if (!error) {
              this.toastr.success(
                'Spojovaná střídání úspěšně vymazána.',
                'Výborně!'
              );
            } else {
              this.toastr.error(
                'Během mazání spojovaných střídání došlo k chybě.',
                'Chyba!'
              );
            }
          }
        }
      }
    }
    return !error;
  }

  changePlayer() {
    this.updateShift(
      this.selected_player,
      this.selected_shift.shift,
      this.selected_shift.from,
      this.selected_shift.to
    );
  }

  //@HostListener('document:click', ['$event'])
  documentClick() {
    //let target = (event.target as Element).parentElement.className;
    //alert(target);

    //if (target != 'event-box' && target != 'event-row' && target != 'event-more' && target != 'event-select' && target != 'event-time') {
    //this.show_edit_dialog = false;
    //this.show_addshift_dialog = false;
    //}

    this.show_edit_dialog = false;
    this.show_addshift_dialog = false;
    this.clicked_shift = null;
    //console.log(event);
  }

  addShift(event: MouseEvent) {
    //this.selected_slot = selected_slot;
    this.new_shift = false;
    setTimeout(() => {
      this.show_addshift_dialog = true;
    }, 1);
    this.dialog_x = event.clientX;
    this.dialog_y = event.clientY + 10;
    this.from = this.formatTime(
      Math.ceil(
        (event.clientX + this.scrollView.nativeElement.scrollLeft - 140) / 20
      ) - 1
    );
    //this.to = '0:00';

    return false;
  }

  getPlayerPost(id: number) {
    let homeRoster = this.roster_home.find((player) => player.id == id);

    if (homeRoster) {
      return homeRoster.position;
    }

    let awayRoster = this.roster_away.find((player) => player.id == id);

    if (awayRoster) {
      return awayRoster.position;
    }

    console.warn('Player post with id', id, 'was not found!');
    return '';
  }

  renderProblemSlot() {
    let data = this.getSlot(1);

    console.log(JSON.stringify(data));
  }

  canDeactivate() {
    if (!confirm('Přejete si opustit zápas a uzamknout ho?')) return false;

    this.defaultService.removeWatch(this.id).subscribe(
      () => {
        this.toastr.success(
          'Sběr dat byl ukončen a zápas uzamčen.',
          'Výborně!',
          {
            progressBar: true,
          }
        );
      },
      (error) => {
        console.error('Error while locking the game', error);
        this.toastr.error('Během zamykání zápasu došlo k chybě.', 'Chyba!', {
          progressBar: true,
        });
      }
    );

    return true;
  }

  /**
   * Get a object containing all the players by event
   * @param {any} event
   */
  getPlayersByEvent(event: any): any {
    let eventEnum: string[] = Object.keys(EventType);
    let players: any = {};
    let eventKey = event.event.toUpperCase();

    switch (eventKey) {
      case eventEnum[9]: {
        event.winnerId && (players.winnerId = event.winnerId);
        event.loserId && (players.loserId = event.loserId);
      }
      case eventEnum[3]: {
        event.passingPlayerId &&
          (players.passingPlayerId = event.passingPlayerId);
        event.playerId && (players.playerId = event.playerId);
        event.stopperPlayerId &&
          (players.stopperPlayerId = event.stopperPlayerId);
      }
      case eventEnum[4]: {
        event.passingPlayerId &&
          (players.passingPlayerId = event.passingPlayerId);
        event.playerId && (players.playerId = event.playerId);
        event.denialPlayerId && (players.denialPlayerId = event.denialPlayerId);
      }
      case eventEnum[5]:
      case eventEnum[6]: {
        event.playerId && (players.playerId = event.playerId);
      }
      case eventEnum[7]:
      case eventEnum[2]: {
        event.playerId && (players.playerId = event.playerId);
        event.puckLostPlayerId &&
          (players.puckLostPlayerId = event.puckLostPlayerId);
        event.gainSharePlayerId &&
          (players.gainSharePlayerId = event.gainSharePlayerId);
      }
      case eventEnum[1]: {
        event.playerId && (players.playerId = event.playerId);
      }
      case eventEnum[0]: {
        event.playerId && (players.playerId = event.playerId);
        //event.goalkeeperId && (players.goalkeeperId = event.goalkeeperId);
        event.blockerId && (players.blockerId = event.blockerId);
      }
      case eventEnum[10]: {
        event.hitter && (players.hitter = event.hitter);
        event.hitted && (players.hitted = event.hitted);
      }
    }
    //console.log(players);
    return players;
  }

  /**
   * Get player full name [player number + player name] by player id
   * @param {number} playerId
   */
  getPlayerFullName(playerId: number) {
    return (
      this.getPlayerNumber(playerId) +
      ' ' +
      this.getPlayerName(playerId) +
      ' / '
    );
  }

  /**
   * Get available shot Players
   * @param {event time line} event
   */
  getShotPlayers(event) {
    let str = '';
    if (event.playerId != undefined)
      str +=
        this.getPlayerNumber(event.playerId) +
        ' ' +
        this.getPlayerName(event.playerId) +
        ' / ';
    if (event.goalkeeperId != undefined)
      str +=
        this.getPlayerNumber(event.goalkeeperId) +
        ' ' +
        this.getPlayerName(event.goalkeeperId) +
        ' / ';

    return this.removeEndsLettersFromLabel(str);
  }

  /**
   * Get available Puck Won Players
   * @param {event time line} event
   */
  getPuckWonPlayers(event) {
    let str = '';
    if (event.gainSharePlayerId != undefined)
      str += this.getPlayerFullName(event.gainSharePlayerId);
    if (event.playerId != undefined)
      str += this.getPlayerFullName(event.playerId);
    if (event.puckLostPlayerId != undefined)
      str += this.getPlayerFullName(event.puckLostPlayerId);

    return this.removeEndsLettersFromLabel(str);
  }

  /**
   * Get available Zone Entry Players
   * @param {event time line} event
   */
  getZoneEntryPlayers(event) {
    let str = '';
    if (event.passingPlayerId != undefined)
      str += this.getPlayerFullName(event.passingPlayerId);
    if (event.playerId != undefined)
      str += this.getPlayerFullName(event.playerId);
    if (event.stopperPlayerId != undefined)
      str += this.getPlayerFullName(event.stopperPlayerId);

    return this.removeEndsLettersFromLabel(str);
  }

  /**
   * Get available Zone Exit Players
   * @param {event time line} event
   */
  getZoneExitPlayers(event) {
    let str = '';
    if (event.passingPlayerId != undefined)
      str += this.getPlayerFullName(event.passingPlayerId);
    if (event.playerId != undefined)
      str += this.getPlayerFullName(event.playerId);
    if (event.denialPlayerId != undefined)
      str += this.getPlayerFullName(event.denialPlayerId);

    return this.removeEndsLettersFromLabel(str);
  }

  /**
   * Remove unnecessary end letters from the label of popup event players
   * @param {string} str
   */
  removeEndsLettersFromLabel = (str: string) =>
    str.endsWith(' / ') ? str.substring(0, str.length - 3) : str;

  /**
   * Manage width and postion shapes if I get more than one event
   * at the same time
   * @param {number, number} time,index
   */
  ManageEventTimeLineShape(time: number, index: number) {
    let target = $('#_event_' + index);
    if (
      !this.eventTimeLineData[index - 1] ||
      time !== this.eventTimeLineData[index - 1].time
    ) {
      return this.calcPos(time);
    }

    if (index > 0) {
      let nearEventIndex = index - 1;
      let nearEvent = $('#_event_' + nearEventIndex);
      target.css({ width: '10px' });
      nearEvent.css({ width: '10px' });
      return parseInt(nearEvent.css('left')) + 10;
    }
  }

  public onTimeLineEventClick(value) {
    if (value?.event) {
      this.show_event_edit_canvas = !this.show_event_edit_canvas;
      this.eventEditInputData = this.show_event_edit_canvas ? value : null;
    }
  }

  public onEventEditClose() {
    this.show_event_edit_canvas = false;
    this.eventEditInputData = null;
  }

  public onEventEditSave(event: { body: any; event: string; eventId: number }) {
    if (!event) {
      return;
    }

    const subscription = this.eventFlowService
      .updateEvent(this.id, event.eventId, event.event, event.body)
      .subscribe(
        () => {
          this.show_event_edit_canvas = false;
          this.eventEditInputData = null;
          this.loadData();
          subscription.unsubscribe();
          this.toastr.success('Vybraná událost byla upravena.', 'Výborně!');
        },
        (error) => {
          console.log('Event', event, 'was not saved. Error:', error);
          this.toastr.error('Nepodařilo se uložit událost.', 'Chyba!');
        }
      );
  }

  public onEventEditSupervision(event: {
    event: string;
    eventId: number;
    body: any;
  }) {
    if (event) {
      const subscription = this.eventFlowService
        .setSupervision(this.id, event.eventId, event.event, event.body)
        .subscribe(
          (res) => {
            this.show_event_edit_canvas = false;
            this.eventEditInputData = null;
            subscription.unsubscribe();
          },
          (err) => console.log('error -> ', err)
        );
    }
  }
}
