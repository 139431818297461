import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { getApiUrl } from '../utils';

@Injectable()
export class EventFlowService {
  token: string;
  api_url: string = getApiUrl();

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getEventflow(matchId: number) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    return this.http
      .get(this.api_url + '/api/eventFlow/' + matchId, header)
      .pipe(map((res) => res));
  }

  updateEvent(matchId: number, eventId: number, event: string, body: any) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    return this.http.put(
      `${this.api_url}/api/${event}/${matchId}/${eventId}`,
      body,
      header
    );
  }

  setSupervision(matchId: number, eventId: number, event: string, body: any) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    return this.http.put(
      `${this.api_url}/api/supervision/${event}/${matchId}/${eventId}`,
      body,
      header
    );
  }

  correctEventError(matchId: number, eventId: number, event: string) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    let body = {
      event: event,
      eventId: eventId,
    };
    return this.http.post(
      `${this.api_url}/api/eventErrorResult/${matchId}/correct`,
      body,
      header
    );
  }
}
