import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeTranslate'
})
export class TypeTranslatePipe implements PipeTransform {
  /**
   * Translates type into Czech type.
   * @param type String type for translation.
   * @returns Translated type.
   */
  transform(type: string): string {
    if(type == "create") return "Vytvoření";
    else if(type == "delete") return "Vymazání";
    return "Editace";
  }
}
