import { Component, OnInit, Input } from '@angular/core';
import { IDayCalendarConfig, DatePickerComponent } from 'ng2-date-picker';
import { Router } from '@angular/router';

import { DefaultService } from '../../services/default.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectService } from './select.service';
import moment from 'moment';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [DefaultService],
})
export class SelectComponent implements OnInit {

  calendar_settings: IDayCalendarConfig = {
    locale: 'cs',
    firstDayOfWeek: 'mo',
    format: 'DD.MM.YYYY',
  };

  public selected_month: string;
  public selected_year: number;
  public selectedDate: any;
  public displayDate;
  public playDaysDatesList = [];

  loading: boolean = false;

  games: any = [];
  opened_games: any = [];
  leagues: any = [];

  selectedLeague: string = '';

  league_name: string = 'Všechny soutěže';

  tracking: boolean = false;
  supervision: boolean = false;

  modal: boolean = false;

  sanitizer: DomSanitizer;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public defaultService: DefaultService,
    private selectService: SelectService,
    private s: DomSanitizer
  ) {
    this.supervision = JSON.parse(localStorage.getItem('user_identity'))[
      'supervision'
    ];
    this.tracking = JSON.parse(localStorage.getItem('user_identity'))[
      'tracking'
    ];

    this.sanitizer = s;
  }

  ngOnInit(): void {
    this.leagues = [];
    this.initCalendar();
    this.initData();
  }

  private initData() {
    this.defaultService.getLeague().subscribe((data: any) => this.leagues = data);
    this.defaultService.getMatches().subscribe((data: any) => {
      this.opened_games = data;
      this.playDaysDatesList = this.getPlayDaysList();
      this.highlightPlayDays();
    }, (error) => {
      if (error.status === 401) {
        this.defaultService.logout();
      }
    });
  }

  private initCalendar() {
    this.selected_month = this.getMonthName(this.selectService.selectedDate?._d ?? new Date());
    this.selected_year = this.getYear(this.selectService.selectedDate?._d ?? new Date());
    this.selectedDate = this.selectService.selectedDate;
    if (this.selectedDate) {
      const date = new Date(this.selectedDate._d);
      this.displayDate = `${date.getDate()}|${date.getMonth() + 1}|${date.getFullYear()}`;
      this.loadSchedule(this.selectedDate.toISOString().slice(0, 10).replace('T', ' '));
    }
  }

  private getPlayDaysList(): string[] {
    return this.opened_games.map((d) => {
        const date = new Date(d.date);
        const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
        const month = (date.getMonth() + 1) <= 9 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);
        return `${day}.${month}.${date.getFullYear()}`;
    });
  }

  private highlightPlayDays() {
    setTimeout(() => { // wait when all elements are in DOM
        if (this.playDaysDatesList.length > 0) {
            const days = document.getElementsByClassName('dp-calendar-day');
            for (let i = 0 ; i < days.length; i++) {
                const dateValue = days[i].getAttribute('data-date');
                if (this.playDaysDatesList.includes(dateValue)) {
                    days[i].classList.add('dp-play-day');
                }
            }
        }
    });
  }

  public onSelectDayClick(way: 'right' | 'left') {
    let date = new Date(this.selectedDate?._d);
    date = way === 'right' ? new Date(date.setDate(date.getDate() + 1)) : new Date(date.setDate(date.getDate() - 1));
    const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
    const month = (date.getMonth() + 1) <= 9 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);
    const newDateValue = `${day}.${month}.${date.getFullYear()}`;
    const days = document.getElementsByClassName('dp-calendar-day');
    for (let i = 0 ; i < days.length; i++) {
        const dateValue = days[i].getAttribute('data-date');
        if (dateValue === newDateValue) {
            (days[i] as HTMLElement).click();
            break;
        }
    }
  }

  selectDate(event) {
    this.selectService.setSelectedDate(event.date);
    this.loadSchedule(new Date(event.date._d).toISOString().slice(0, 10));
  }

  onChangeMonth(event) {
    this.getMonthName(event.to);
    this.selected_month = this.getMonthName(event.to);
    this.selected_year = this.getYear(event.to);
    this.highlightPlayDays();
  }

  public onSetTodayClick() {
    const date = new Date();
    this.selected_month = this.getMonthName(date);
    this.selected_year = this.getYear(date);
    this.displayDate = moment(date);
    this.selectedDate = moment(date);
    this.selectService.setSelectedDate(date);
    this.loadSchedule(this.selectedDate.toISOString().slice(0, 10).replace('T', ' '));
    this.highlightPlayDays();
  }

  reloadGames() {
    if (this.selectedLeague === '') {
      this.league_name = 'Všechny soutěže';
    } else {
      let league_ = '';
      this.leagues.forEach((league) => {
        if (String(league.id) === this.selectedLeague) {
          league_ = league.name;
        }
      });

      this.league_name = league_;
    }
  }

  filterGames() {
    const games = [];
    if (this.selectedLeague === '') {
      this.games.forEach(item => games.push(item));
    } else {
      this.games.forEach(item => {
        console.log(item.competition.id + '  ' + this.selectedLeague);
        if (item.competition.id === this.selectedLeague) {
          games.push(item);
        }
      });
    }

    return games;
  }

  loadSchedule(date: string) {
    this.games = [];
    this.loading = true;

    this.defaultService.getSchedule(date, this.selectedLeague).subscribe(
      (data: any) => {
        this.games = data;
        this.loading = false;
        this.highlightPlayDays();
      },
      (error) => {
        this.loading = false;
        if (error.status === 401) {
          this.defaultService.logout();
        }
      }
    );
  }

  isActiveWatch(matchId: number) {
    return this.opened_games.find(game => game.id === matchId && game.watch) !== undefined;
  }


  getMonthName(date: any) {
    const monthNames = [
      'Leden',
      'Únor',
      'Březen',
      'Duben',
      'Květen',
      'Červen',
      'Červenec',
      'Srpen',
      'Září',
      'Říjen',
      'Listopad',
      'Prosinec',
    ];
    return monthNames[new Date(date).getMonth()];
  }

  getYear(date: any) {
    return new Date(date).getFullYear();
  }

  stringy(data: any) {
    return JSON.stringify(data);
  }

  checkOpenedGame(id: string, route: string) {
    let opened = false;
    /*
    this.opened_games.forEach(item => {
      if (item.id == id) {
        opened = true;
      }
    });
    */
    if (opened) {
      this.router.navigate(['/' + route, id]);
    } else {
      this.toastr.info('Zápas se zakládá.', 'Info', {
        progressBar: true,
      });

      this.defaultService.createMatch(id).subscribe(
        (data: any) => {
          this.defaultService.createWatch(id).subscribe(
            (data: any) => {
              this.toastr.success('Zápas byl úspěšně odemčený.', 'Výborně!', {
                progressBar: true,
              });
              this.router.navigate(['/' + route, id]);
            },
            (error) => {
              this.toastr.warning(
                'Během odemykání zápasu došlo k chybě. Zkuste to prosím znovu',
                'Chyba!',
                {
                  progressBar: true,
                }
              );
            }
          );
        },
        (error) => {
          this.toastr.warning(
            'Během zakládání zápasu došlo k chybě. Zkuste to prosím znovu',
            'Chyba!',
            {
              progressBar: true,
            }
          );
        }
      );
    }
  }

  checkOpenedGame2(id: string) {
    return this.opened_games.find(item => item.id === id) !== undefined;
  }

  lock(id: number) {
    if (confirm('Přejete ukončit zápas?')) {
      this.defaultService.removeMatch(String(id)).subscribe(
        (data: any) => {
          this.toastr.success('Sběr dat byl ukončen.', 'Výborně!', {
            progressBar: true,
          });
          this.loadSchedule(
            this.selectedDate.toISOString().slice(0, 10).replace('T', ' ')
          );
        },
        (error) => {
          this.toastr.error('Během rušení zápasu došlo k chybě.', 'Chyba!', {
            progressBar: true,
          });
          this.loadSchedule(
            this.selectedDate.toISOString().slice(0, 10).replace('T', ' ')
          );
        }
      );
    }
  }

  removeWatch(id: number) {
    this.defaultService.removeWatch(id).subscribe(
      (data: any) => {
        this.defaultService.getMatches().subscribe(
          (data: any) => {
            this.opened_games = data;
            this.loading = false;

            this.toastr.success('Sběr dat byl ukončen.', 'Výborně!', {
              progressBar: true,
            });
          },
          (error) => {
            this.loading = false;
          }
        );
      },
      (error) => {
        this.defaultService.getMatches().subscribe(
          (data: any) => {
            this.opened_games = data;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
      }
    );
  }

  private zeroFill(value: number): string {
    return (value > 9 ? '' : '0') + value;
  }

  private secondsToMiniutes(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return this.zeroFill(minutes) + ":" + this.zeroFill(seconds);
  }

  private formatMessage(message) {
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  getLastModificationInfo(i: any) {
    let message = "";
    let color = "#fff";
    this.opened_games.forEach(item => {
      if (item.id + "" == i.id + "") {
        if (item != null && item.lastModification != null) {
          let modification = item.lastModification;
          let editTime = "00:00", eventTime = "00:00";
          if (modification.lastEditTime != null)
            editTime = this.secondsToMiniutes(modification.lastEditTime);
          if (modification.lastEventTime != null)
            eventTime = this.secondsToMiniutes(modification.lastEventTime);
          if (modification.lastEditTime == modification.lastEventTime)
            color = "#20c997";
          else if ((modification.lastEditTime - modification.lastEventTime > 120)
            || (modification.lastEventTime - modification.lastEditTime > 120)
          )
            color = "#d7181e";

          message = "<span style='color:" + color + "'>POSLEDNÍ UDÁLOST: " + eventTime + ", POSLEDNÍ ZMĚNA: " + editTime + "</span>";
        }
      }
    });
    return this.formatMessage(message);
  }
}