<div
  class="tracking-nav"
  *ngIf="
    !show_faceOff &&
    !show_shot &&
    !show_zoneEntry &&
    !show_hit &&
    !show_dumpIn &&
    !show_zoneExit &&
    !show_dumpOut &&
    !show_offensiveZoneLoss &&
    !show_shootout &&
    !show_penalty &&
    !show_topMoment &&
    !show_penaltyShootout
  "
>
  <div class="box">
    <button [class.active]="show_faceOff" (click)="toggleCanvas('faceOff')">
      <div class="char"><img src="/assets/image/alphabet/b.svg" /></div>
      <div class="nav-text">Vhazování</div>
    </button>
  </div>

  <div class="box">
    <button [class.active]="show_shot" (click)="toggleCanvas('shot')">
      <div class="char"><img src="/assets/image/alphabet/s.svg" /></div>
      <div class="nav-text">Střela</div>
    </button>
  </div>

  <div class="box">
    <button [class.active]="show_zoneEntry" (click)="toggleCanvas('zoneEntry')">
      <div class="char"><img src="/assets/image/alphabet/d.svg" /></div>
      <div class="nav-text">Vstup do pásma</div>
    </button>
  </div>

  <div class="box">
    <button [class.active]="show_dumpIn" (click)="toggleCanvas('dumpIn')">
      <div class="char"><img src="/assets/image/alphabet/n.svg" /></div>
      <div class="nav-text">Nahození</div>
    </button>
  </div>

  <div class="box">
    <button [class.active]="show_zoneExit" (click)="toggleCanvas('zoneExit')">
      <div class="char"><img src="/assets/image/alphabet/z.svg" /></div>
      <div class="nav-text">Výstup z pásma</div>
    </button>
  </div>

  <div class="box">
    <button [class.active]="show_dumpOut" (click)="toggleCanvas('dumpOut')">
      <div class="char"><img src="/assets/image/alphabet/v.svg" /></div>
      <div class="nav-text">Vyhození</div>
    </button>
  </div>

  <!--
        <div class="box">
            <button [class.active]="show_offensiveZoneLoss" (click)="toggleCanvas('offensiveZoneLoss',false)">
            <div class="char"><img src="/assets/image/alphabet/u.svg"></div>
            <div class="nav-text">Ztráta út. pásma</div>
            </button>
        </div>
    -->

  <div class="box">
    <button [class.active]="show_shootout" (click)="toggleCanvas('shootout')">
      <div class="char"><img src="/assets/image/alphabet/t.svg" /></div>
      <div class="nav-text">Nájezdy</div>
    </button>
  </div>

  <div class="box">
    <button [class.active]="show_penalty" (click)="toggleCanvas('penalty')">
      <div class="char"><img src="/assets/image/alphabet/p.svg" /></div>
      <div class="nav-text">Vyloučení</div>
    </button>
  </div>

  <div class="box">
    <button [class.active]="show_topMoment" (click)="toggleCanvas('topMoment')">
      <div class="char"><img src="/assets/image/alphabet/M.svg" /></div>
      <div class="nav-text">Top Moment</div>
    </button>
  </div>

  <!--
        <div class="box">
            <button [class.active]="show_hit" (click)="toggleCanvas('hit',false)">
            <div class="char"><img src="/assets/image/alphabet/t.svg"></div>
            <div class="nav-text">Hit</div>
            </button>
        </div>
    -->
</div>
