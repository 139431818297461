import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateSeconds'
})
export class TranslateSecondsPipe implements PipeTransform {
  /**
   * Parse given number into mm:ss string.
   * @param seconds A number represented in seconds.
   * @returns Parsed string in mm:ss format.
   */
  transform(seconds: number): string {
    const minutes = (seconds - seconds % 60) / 60;
    const secondsNoFormat = seconds % 60;
    return minutes.toString().concat(':').concat(secondsNoFormat > 9 ? secondsNoFormat.toString() : '0'.concat(secondsNoFormat.toString()));
  }
}