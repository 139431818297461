import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SelectService {

    public selectedDate;

    constructor() { }

    public setSelectedDate(date) {
        this.selectedDate = date;
    }

}
