import { WrongClipService } from '../../services/wrong-clip.service';
import { Component, OnInit } from '@angular/core';
import {
  IHPWrongClip,
  IHPWrongClipDTO,
  IHPWrongClipErrorType,
  IHPWrongClipsBySections,
} from '../../types';
import { ToastrService } from 'ngx-toastr';
import { parseHPWrongClips } from '../../utils';

@Component({
  selector: 'app-hokejplayer-errors',
  templateUrl: './hokejplayer-errors.component.html',
  styleUrls: ['./hokejplayer-errors.component.scss'],
  providers: [WrongClipService],
})
export class HokejPlayerErrorsComponent implements OnInit {
  constructor(
    private wrongClipService: WrongClipService,
    private toastr: ToastrService
  ) {}

  loading: boolean = true;
  wrongClips: IHPWrongClip[] = [];
  wrongClipsToDisplay: IHPWrongClipsBySections[] = [];
  options: IHPWrongClipErrorType[] = [
    'wrong_player',
    'wrong_time',
    'other',
    'resolved',
  ];

  ngOnInit(): void {
    this.getErrors();
  }

  /**
   * Requests errors from the backend.
   */
  getErrors(): void {
    this.wrongClipService.getWrongClips().subscribe(
      (data: IHPWrongClipDTO[]) => {
        this.wrongClips = parseHPWrongClips(data);
        this.filterWrongClips();
        this.loading = false;
      },
      (error) => {
        this.toastr.error('Nepodařilo se načíst chyby', 'Chyba');
        console.error('Error while getting errors:', error);
        this.loading = false;
      }
    );
  }

  /**
   * Sends request to resolve the error.
   * @param error IHPWrongClip object
   */
  resolveError(error: IHPWrongClip): void {
    if (confirm('Opravdu chcete označit chybu jako vyřešenou?')) {
      this.loading = true;
      this.wrongClipService.resolveWrongClip(error.wrongClipId).subscribe(
        () => {
          this.toastr.success('Chyba byla úspěšně vyřešena', 'Úspěch');
          this.filterWrongClips();
          this.loading = false;
        },
        (error) => {
          this.toastr.error('Nepodařilo se vyřešit požadavek', 'Chyba');
          console.error('Error while getting errors:', error);
          this.loading = false;
        }
      );
    }
  }

  /**
   * Filter all the clips into sections.
   */
  filterWrongClips(): void {
    this.options.forEach((option) => {
      this.wrongClipsToDisplay[option] = this.wrongClips.filter((clip) => {
        if (
          (clip.error === 'resolved' || clip.resolved) &&
          option === 'resolved'
        )
          return true;
        return clip.error === option && !clip.resolved;
      });
    });
  }
}
