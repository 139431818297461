<div class="clip-node" [class]="section" [ngClass]="{ odd: odd }">
  <div class="data">
    <div class="no-collapsable">
      <div class="section">
        {{ section | extraPlayerErrorsSectionTranslate }}
      </div>
      <div class="count-of-errors">
        {{ wrongClipArray ? wrongClipArray.length : 0 }}
      </div>
      <div class="button">
        <toggle-button (onToggle)="collapseEdit()"></toggle-button>
      </div>
    </div>
    <div class="collapsable">
      <div class="sub-data sub-data-header">
        <div class="date">Vytvořeno</div>
        <div class="player">Hráč</div>
        <div class="jersey">Číslo dresu</div>
        <div class="event">Událost</div>
        <div class="time">Čas</div>
      </div>
      <div class="sub-data" *ngFor="let clip of wrongClipArray; let i = index">
        <div class="clip-created">
          {{ clip.createdAt | date : "dd. MM. yyyy" }}
        </div>
        <div class="clip-reporter-name">
          {{ clip.reporter.name[0] }}. {{ clip.reporter.surname }}
        </div>
        <div class="clip-reporter-jersey">
          {{ clip.reporter.jersey }}
        </div>
        <div class="clip-event-type">
          {{ clip.event.type | eventTranslate }}
        </div>
        <div class="clip-event-time">
          {{ clip.event.time | translateSeconds }}
        </div>
        <div class="buttons">
          <button (click)="loadEditting(clip)">Editovat</button>
          <button class="primary" (click)="resolveClip(clip)">Vyřešit</button>
        </div>
      </div>
    </div>
  </div>
</div>
