<app-nav></app-nav>

<div class="page-heading">
    <div class="heading-inner">
        <h1>Chyby ExtraPlayer</h1>
    </div>
</div>

<div class="page">
    <div class="sides">
        <div class="wrong-clips-pane" *ngIf="!loading">
            <div class="clip-list">
                <clip-node
                *ngFor="let possibility of possibilities; let i = index;"
                [wrongClipArray]="wrongClipsToDisplay[possibility]" [section]="possibility" [odd]="i % 2 > 0">
                </clip-node>
            </div>
        </div>
    </div>
</div>

<loading *ngIf="loading"></loading>