<div
  class="playground"
  [class.disabled-playground]="show_faceOff"
  *ngIf="page_type === 'tracking'"
>
  <div class="playground-inner" (click)="onMapClick($event)">
    <div class="coordinates-inner">
      <div
        class="coordinates"
        *ngIf="show_coordinates"
        [ngStyle]="{ 'left.px': x, 'top.px': y }"
      ></div>
    </div>
    <img src="/assets/image/hriste_760x380.svg" />
    <!--
<div class="home-logo"><img src="/assets/image/logos/{{game.gameData.homeTeam.shortcut}}.png"
  onerror="this.src='/assets/image/logos/default.png';"></div>
<div class="away-logo"><img src="/assets/image/logos/{{game.gameData.awayTeam.shortcut}}.png"
  onerror="this.src='/assets/image/logos/default.png';"></div>
-->
    <div class="home-shortcut">
      {{
        period % 2 === 0
          ? game.gameData.awayTeam.shortcut
          : game.gameData.homeTeam.shortcut
      }}
    </div>
    <div class="away-shortcut">
      {{
        period % 2 === 0
          ? game.gameData.homeTeam.shortcut
          : game.gameData.awayTeam.shortcut
      }}
    </div>

    <div class="coordinates-test" *ngIf="show_coordinates">
      {{ test_x }};{{ test_y }}
    </div>
  </div>
</div>
<div class="video-player" *ngIf="page_type === 'supervize'">
  <div class="video-player-inner">
    <div class="camera-1" *ngIf="enabled_camera === 1">
      <iframe
        id="video-file-iframe"
        name="video-file-iframe"
        title="videoplayer"
        width="685px"
        height="385px"
        allowfullscreen
      ></iframe>
    </div>
    <!--
{{matchData.videoId}}
<div class="camera-2" *ngIf="enabled_camera==2">
<iframe width="100%" height="388" src="https://www.youtube.com/embed/xEoMC7czIxA" frameborder="0"
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<div class="camera-3" *ngIf="enabled_camera==3">
<iframe width="100%" height="388" src="https://www.youtube.com/embed/hrHPrrAiNgU" frameborder="0"
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
-->
  </div>
</div>
