<div class="match-info">
  <div class="team team-left" *ngIf="!sidesPeriod[period]">
    <div class="logo">
      <img
        src="/assets/image/logos/{{ game.gameData.homeTeam.shortcut }}.png"
        onerror="this.src='/assets/image/logos/default.png';"
      />
    </div>
    <div class="name">{{ game.gameData.homeTeam.name }}</div>
  </div>
  <div class="team team-left" *ngIf="sidesPeriod[period]">
    <div class="logo">
      <img
        src="/assets/image/logos/{{ game.gameData.awayTeam.shortcut }}.png"
        onerror="this.src='/assets/image/logos/default.png';"
      />
    </div>
    <div class="name">{{ game.gameData.awayTeam.name }}</div>
  </div>

  <div class="settings">
    <!--<div class="date">{{game.gameData.competition.league}} ･ {{game.gameData.date | date:'dd.MM.yyyy'}} ･
  {{game.gameData.competition.round}}. kolo</div>-->
    <div class="buttons">
      <button class="btn-control" routerLink="/select">
        <img src="/assets/image/hockey-sticks-white.svg" />
      </button>
      <button class="btn-control" (click)="changeSidesLock = !changeSidesLock">
        <img src="/assets/image/lock-white.svg" />
      </button>
      <button
        class="button-change-sides"
        (click)="onChangeSites()"
        [disabled]="changeSidesLock"
      >
        Změnit strany <img src="/assets/image/switch-side.svg" />
      </button>
      <div class="button-box number-box">
        <button class="minus" (click)="minusPeriod()">
          <img src="/assets/image/minus-circle.svg" />
        </button>
        <div class="mask"></div>
        <input type="text" class="number" value="{{ formatPeriod(period) }}" />
        <button class="plus" (click)="plusPeriod()">
          <img src="/assets/image/plus-circle.svg" />
        </button>
      </div>
      <div class="button-box number-box">
        <button class="minus" (click)="minusMinute()">
          <img src="/assets/image/minus-circle.svg" />
        </button>
        <input
          type="number"
          class="number"
          [(ngModel)]="minute"
          min="0"
          max="20"
          onClick="this.select();"
          (ngModelChange)="loadPlayersOnIce(false)"
        />
        <!--(ngModelChange)="checkMinuteChange(minute)"-->
        <button class="plus" (click)="plusMinute()">
          <img src="/assets/image/plus-circle.svg" />
        </button>
      </div>
      <div class="button-box number-box">
        <button class="minus" (click)="minusSecond()">
          <img src="/assets/image/minus-circle.svg" />
        </button>
        <input
          type="number"
          class="number"
          [(ngModel)]="second"
          min="0"
          max="59"
          onClick="this.select();"
          (ngModelChange)="loadPlayersOnIce(false)"
        />
        <!--(ngModelChange)="checkSecondChange(second)"-->
        <button class="plus" (click)="plusSecond()">
          <img src="/assets/image/plus-circle.svg" />
        </button>
      </div>
      <button class="btn-control" (click)="logout()">
        <img src="/assets/image/logout.svg" />
      </button>
    </div>
  </div>
  <div class="team team-right" *ngIf="!sidesPeriod[period]">
    <div class="logo">
      <img
        src="/assets/image/logos/{{ game.gameData.awayTeam.shortcut }}.png"
        onerror="this.src='/assets/image/logos/default.png';"
      />
    </div>
    <div class="name">{{ game.gameData.awayTeam.name }}</div>
  </div>
  <div class="team team-right" *ngIf="sidesPeriod[period]">
    <div class="logo">
      <img
        src="/assets/image/logos/{{ game.gameData.homeTeam.shortcut }}.png"
        onerror="this.src='/assets/image/logos/default.png';"
      />
    </div>
    <div class="name">{{ game.gameData.homeTeam.name }}</div>
  </div>
</div>
