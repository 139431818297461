import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { getApiUrl } from '../utils';
import { IGetVideoPlayerParams } from '../types';

@Injectable()
export class DefaultService {
  token: string;
  api_url: string = getApiUrl();

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.token = localStorage.getItem('access_token');
  }

  getToken(client_id: string, client_secret: string) {
    var body = {
      grant_type: 'client_credentials',
      client_id: client_id,
      client_secret: client_secret,
    };

    return this.http
      .post(this.api_url + '/api/token?cache=' + Date.now(), body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res) => res));
  }

  getIdentity(token: string) {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
    };
    return this.http
      .get(this.api_url + '/api/identity', header)
      .pipe(map((res) => res));
  }

  getMatch(matchId: number) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http
      .get(this.api_url + '/api/match/' + matchId, header)
      .pipe(map((res) => res));
  }

  getMatches() {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http
      .get(this.api_url + '/api/match?cache=' + Date.now(), header)
      .pipe(map((res) => res));
  }

  /**
   * Returns logs for the match indetified by the given ID.
   * @param matchId Id of the match.
   * @returns Data.
   */
  getMatchLog(matchId: number) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http
      .get(this.api_url + '/api/actionLog/' + matchId, header)
      .pipe(map((res) => res));
  }

  /**
   * Returns logs for the match indetified by the given ID.
   * @param matchId Id of the match.
   * @returns Data.
   */
  getEventFlowError(matchId: number) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http
      .get(this.api_url + '/api/eventFlowError/' + matchId, header)
      .pipe(map((res) => res));
  }

  /**
   * Returns logs for the match indetified by the given IDs.
   * @param matchId Id of the match.
   * @param teamId Id of the team.
   * @returns Data.
   */
  getEventFlowErrorByTeam(matchId: number, teamId: number) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http
      .get(
        this.api_url + '/api/eventFlowError/' + matchId + '?teamId=' + teamId,
        header
      )
      .pipe(map((res) => res));
  }

  getSchedule(date: string, league: string) {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    if (league == '') {
      return this.http
        .get(
          this.api_url + '/api/schedule?date=' + date + '&cache=' + Date.now(),
          header
        )
        .pipe(map((res) => res));
    } else {
      return this.http
        .get(
          this.api_url +
            '/api/schedule?date=' +
            date +
            '&league=' +
            league +
            '&cache=' +
            Date.now(),
          header
        )
        .pipe(map((res) => res));
    }
  }

  createMatch(matchId: string) {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    let body = {
      matchId,
    };
    return this.http
      .post(this.api_url + '/api/match?cache=' + Date.now(), body, header)
      .pipe(map((res) => res));
  }

  removeMatch(matchId: string) {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    return this.http
      .delete(
        this.api_url + '/api/match/' + matchId + '?cache=' + Date.now(),
        header
      )
      .pipe(map((res) => res));
  }

  matchSynchronize(matchId: string) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    return this.http
      .get(this.api_url + '/api/match/' + matchId + '/sync', header)
      .pipe(map((res) => res));
  }

  getLeague() {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    return this.http
      .get(this.api_url + '/api/league?cache=' + Date.now(), header)
      .pipe(map((res) => res));
  }

  getPlayersOnIce(matchId: number, time: number) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    return this.http
      .get(this.api_url + '/api/playersOnIce/' + matchId + '/' + time, header)
      .pipe(map((res) => res));
  }

  needRelog() {
    let expire = new Date(localStorage.getItem('token_expire')).getTime();
    let now = new Date().getTime();

    console.log(
      expire + ' až ' + now + '   rozdíl:' + (expire - now - 10800000)
    );

    if (now > expire - 10800000) {
      return true;
    } else {
      return false;
    }
  }

  error(data: any) {
    return this.http
      .post(
        'https://php.laura.esports.cz/tracking-app/error.php?date=' +
          new Date().toLocaleString(),
        data
      )
      .pipe(map((res) => res));
  }

  createWatch(matchId: string) {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    let data = {
      matchId: matchId,
    };
    return this.http
      .post(this.api_url + '/api/watch?cache=' + Date.now(), data, header)
      .pipe(map((res) => res));
  }

  removeWatch(matchId: number) {
    const header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    return this.http
      .delete(this.api_url + '/api/watch/' + matchId, header)
      .pipe(map((res) => res));
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('access_token');
    localStorage.removeItem('logged_user');
    this.router.navigate(['/login']);

    this.toastr.warning(
      'Platnost relace přihlášení vypršela. Přihlaste se znovu.',
      'Odhlášení',
      {
        progressBar: true,
      }
    );
  }

  getVideoPlayer = ({
    matchId,
    seekTime,
    text,
    description,
    videoType,
    videoStartTime,
    videoEndTime,
    extraControls,
  }: IGetVideoPlayerParams) => {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    let params = {};

    if (seekTime !== undefined) params = { ...params, seekTime };
    if (text) params = { ...params, text };
    if (description) params = { ...params, description };
    if (videoType) params = { ...params, videoType };
    if (videoStartTime !== undefined) params = { ...params, videoStartTime };
    if (videoEndTime !== undefined) params = { ...params, videoEndTime };
    if (extraControls) params = { ...params, extraControls };

    return this.http.get(this.api_url + `/api/video/${matchId}/player`, {
      headers,
      params,
      responseType: 'text',
    });
  };
}
