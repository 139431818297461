import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IHPWrongClip } from '../../../types';

@Component({
  selector: 'clip-node',
  templateUrl: './clip-node.component.html',
  styleUrls: ['./clip-node.component.scss'],
})
export class ClipNodeComponent {
  @Input() wrongClipArray: any[] = [];
  @Input() section: string;
  @Input() odd: boolean;
  @Output() onClipResolveButtonClicked = new EventEmitter<IHPWrongClip>();

  loadEditting(clip: any): void {
    if (clip.event.type === 'shift') {
      window.open(
        '/timeline/' + clip.matchId + '/' + clip.event.time,
        '_blank'
      );
      return;
    }
    window.open(
      '/supervize/' +
        clip.matchId +
        '/' +
        clip.event.type +
        '/' +
        clip.event.time,
      '_blank'
    );
  }

  resolveClip(clip: IHPWrongClip) {
    this.onClipResolveButtonClicked.emit(clip);
  }

  /**
   * Toggles collapsing of editing node and changes plus into minus and backwards.
   */
  collapseEdit() {
    if (!this.wrongClipArray || this.wrongClipArray.length === 0) return;

    let node = document.getElementsByClassName(this.section)[0];
    let collapsable = node.getElementsByClassName(
      'collapsable'
    )[0] as HTMLElement;
    if (!collapsable) return;

    if (collapsable.classList.contains('collapse')) {
      collapsable.classList.remove('collapse');
      collapsable.style.height = '0px';
      collapsable.style.marginTop = '0px';
      return;
    }

    collapsable.classList.add('collapse');
    collapsable.style.height =
      (collapsable.scrollHeight > 300 ? 300 : collapsable.scrollHeight) + 'px';
    collapsable.style.marginTop = '8px';
  }
}
