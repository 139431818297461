import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'match-detail-header',
  templateUrl: './match-detail-header.component.html',
  styleUrls: ['./match-detail-header.component.scss']
})
export class MatchDetailHeaderComponent implements OnInit {
  @Input() match;
  @Input() editArray: string[];
  @Input() userArray: string[];
  @Input() eventArray: string[];
  @Input() time: string;
  @Output() onEditSelectChanged = new EventEmitter<String>();
  @Output() onUserSelectChanged = new EventEmitter<String>();
  @Output() onEventSelectChanged = new EventEmitter<String>();
  @Output() onTimeSelectChanged = new EventEmitter<String>();

  selectedLeague: string = "-1";
  selectedEdit: string = "all";
  selectedUser: string = "all";
  selectedEvent: string = "all";

  constructor() { }

  ngOnInit(): void {
  }

  filterEdits() { this.onEditSelectChanged.emit(this.selectedEdit); }
  filterUsers() { this.onUserSelectChanged.emit(this.selectedUser); }
  filterEvents() { this.onEventSelectChanged.emit(this.selectedEvent); }
  filterTime(time: string) {
    this.onTimeSelectChanged.emit(time);
  }

  /**
   * Clears time in the time input.
   */
  clearTime(): void {
    this.time = null;
    this.filterTime(this.time);
  }

}
