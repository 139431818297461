import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() shot_active: string;
  @Input() goal = false;
  @Input() disable = false;

  active_bl: boolean = false;
  active_br: boolean = false;
  active_ce: boolean = false;
  active_fh: boolean = false;
  active_ur: boolean = false;
  active_ul: boolean = false;

  disable_ce: boolean = false;

  @Output() shotChange_ = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.toggle(this.shot_active);
    console.log('goal map', this.goal);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.disable?.currentValue === undefined) return;
    if(changes.disable.currentValue)
      this.disableAll();
  }

  toggle(value: string) {
    if (value === 'bl') {
      if (this.active_bl)
        this.disableAll();
      else {
        this.disableAll();
        this.active_bl = true;
      }
    } else if (value === 'br') {
      if (this.active_br)
        this.disableAll();
      else {
        this.disableAll();
        this.active_br = true;
      }
    } else if (value === 'ce') {
      if (this.active_ce)
        this.disableAll();
      else {
        this.disableAll();
        this.active_ce = true;
      }
    } else if (value === 'fh') {
      if (this.active_fh)
        this.disableAll();
      else {
        this.disableAll();
        this.active_fh = true;
      }
    } else if (value === 'ur') {
      if (this.active_ur)
        this.disableAll();
      else {
        this.disableAll();
        this.active_ur = true;
      }
    } else if (value === 'ul') {
      if (this.active_ul)
        this.disableAll();
      else {
        this.disableAll();
        this.active_ul = true;
      }
    } else if (value === '')
      this.disableAll();
    this.shotChange();
  }

  disableAll(): void {
    this.active_ce = false;
    this.active_br = false;
    this.active_bl = false;
    this.active_fh = false;
    this.active_ur = false;
    this.active_ul = false;
  }

  disableCeInit() {
    this.disable_ce = false;
  }

  disableCe() {
    this.disable_ce = true;
    this.active_ce = false;
  }

  getActive() {
    if (this.active_ce)
      return 'ce';
    if (this.active_br)
      return 'br';
    if (this.active_bl)
      return 'bl';
    if (this.active_fh)
      return 'fh';
    if (this.active_ur)
      return 'ur';
    if (this.active_ul)
      return 'ul';
    return '';
  }

  shotChange() {
    this.shotChange_.emit(this.getActive());
  }
}
