import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tracking-nav',
  templateUrl: './tracking-nav.component.html',
  styleUrls: ['./tracking-nav.component.scss'],
})
export class TrackingNavComponent {
  @Input() show_hit: boolean;
  @Input() show_faceOff: boolean;
  @Input() show_shot: boolean;
  @Input() show_zoneEntry: boolean;
  @Input() show_dumpIn: boolean;
  @Input() show_zoneExit: boolean;
  @Input() show_dumpOut: boolean;
  @Input() show_offensiveZoneLoss: boolean;
  @Input() show_shootout: boolean;
  @Input() show_penalty: boolean;
  @Input() show_penaltyShootout: boolean;
  @Input() show_topMoment: boolean;
  @Output() onToggleCanvas = new EventEmitter<string>();

  constructor() {}

  toggleCanvas(type: string): void {
    this.onToggleCanvas.emit(type);
  }
}
