<div class="container canvas" oncontextmenu="return false;">
  <button class="close" (click)="close()">Zavřít bez uložení</button>
  <div class="canvas-scroll" #scroller>
    <div class="canvas-data">
      <div class="canvas-box">
        <h2>
          Střela
          <button class="replay" (click)="replay(time)"></button>
        </h2>
        <div class="box-inner">
          <div class="box">
            <div class="form-box three-inputs">
              <table>
                <tr>
                  <th>Čas časomíry</th>
                  <th>Třetina</th>
                  <th>Lokace</th>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      matInput
                      mask="m0:s0"
                      [dropSpecialCharacters]="false"
                      [(ngModel)]="time"
                      (change)="emitTimeChange()"
                      (blur)="setTime()"
                      onClick="this.select();"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="period"
                      class="noedit"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      (focus)="checkXY()"
                      (blur)="uncheckXY()"
                      [(ngModel)]="lokace"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="form-box"
              [class.danger]="supervize.playerId"
              (contextmenu)="toggleMenu($event, 'playerId')"
            >
              <label>Střílející hráč</label>
              <div class="player-select">
                <ng-select
                  [items]="roster_all"
                  bindLabel="search"
                  bindValue="id"
                  dropdownPosition="bottom"
                  [(ngModel)]="shootingPlayer.id"
                  (focus)="shootingPlayer.active = true"
                  (blur)="shootingPlayer.active = false"
                  (change)="changePlayer1()"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position" *ngIf="item.id != ''">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>
                </ng-select>
              </div>
            </div>

            <div
              class="form-box two-buttons"
              [class.danger]="supervize.result"
              (contextmenu)="toggleMenu($event, 'result')"
            >
              <div style="display: flex; justify-content: space-between">
                <label>Druh střely</label>
                <label>{{ getCaughtSaveTypeLabel() }}</label>
              </div>
              <table>
                <tr>
                  <td>
                    <button
                      (click)="setResult('goal')"
                      [class.active]="result === 'goal'"
                    >
                      Gól
                    </button>
                  </td>
                  <td>
                    <div
                      class="tooltyp-saved form-box"
                      *ngIf="show_saved_tooltyp"
                    >
                      <div
                        class="form-box four-buttons"
                        [class.danger]="supervize.oneTimer"
                        (contextmenu)="toggleMenu($event, 'oneTimer')"
                      >
                        <label
                          class="close-tooltip"
                          (click)="show_saved_tooltyp = false"
                          >BRANKÁŘ STŘELU:</label
                        >
                        <table>
                          <tr>
                            <td>
                              <button
                                (click)="toggleCaughtSaveType('freeze')"
                                [class.active]="caught_save_type == 'freeze'"
                              >
                                Chytil a podržel
                              </button>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <button
                                (click)="toggleCaughtSaveType('controlled')"
                                [class.active]="
                                  caught_save_type == 'controlled'
                                "
                              >
                                Vyrazil mimo slot
                              </button>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <button
                                (click)="toggleCaughtSaveType('uncontrolled')"
                                [class.active]="
                                  caught_save_type == 'uncontrolled'
                                "
                              >
                                Vyrazil do slotu
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <button
                      (click)="setResult('saved')"
                      [class.active]="result === 'saved'"
                    >
                      Chycená
                    </button>
                  </td>
                </tr>
                <tr>
                  <td style="display: flex">
                    <button
                      style="margin-right: 7px"
                      (click)="setResult('missed')"
                      [class.active]="result === 'missed'"
                    >
                      Mimo
                    </button>

                    <button
                      (click)="setResult('post')"
                      [class.active]="result === 'post'"
                    >
                      Tyč
                    </button>
                  </td>
                  <td>
                    <button
                      (click)="setResult('deflected')"
                      [class.active]="result === 'deflected'"
                    >
                      Zblokovaná
                    </button>
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="form-box"
              [class.disabled]="
                result === 'saved' ||
                result === 'missed' ||
                result === 'goal' ||
                result === 'post'
              "
              [class.danger]="supervize.blockerId"
              (contextmenu)="toggleMenu($event, 'blockerId')"
            >
              <label>Blokující hráč</label>
              <div class="player-select">
                <ng-select
                  [items]="getOpponentRoster(shootingPlayer.id)"
                  bindLabel="search"
                  bindValue="id"
                  [(ngModel)]="blockingPlayer.id"
                  [disabled]="
                    result === 'saved' ||
                    result === 'missed' ||
                    result === 'goal' ||
                    result === 'post'
                  "
                  (focus)="shootingPlayer.active = true"
                  (blur)="shootingPlayer.active = false"
                  dropdownPosition="top"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position" *ngIf="item.id != ''">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div
              class="form-box"
              [class.disabled]="
                result === 'missed' ||
                result === 'deflected' ||
                result === 'post'
              "
              [class.danger]="supervize.goalkeeperId"
              (contextmenu)="toggleMenu($event, 'goalkeeperId')"
            >
              <label>Brankář</label>
              <div class="player-select">
                <ng-select
                  [items]="getOpponentGoalkeepers(shootingPlayer.id)"
                  bindLabel="search"
                  bindValue="id"
                  [(ngModel)]="goalkeeper.id"
                  [disabled]="
                    result === 'missed' ||
                    result === 'deflected' ||
                    result === 'post'
                  "
                  (focus)="goalkeeper.active = true"
                  (blur)="goalkeeper.active = false"
                  (change)="changeGoalie($event)"
                  dropdownPosition="top"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position" *ngIf="item.id != ''">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="box">
            <div
              class="form-box"
              [class.disabled]="
                result === 'missed' ||
                result === 'deflected' ||
                goalkeeper.id === '10000000000' ||
                result === 'post'
              "
              [class.danger]="supervize.gateZone"
              (contextmenu)="toggleMenu($event, 'gateZone')"
            >
              <label>Zóny branky</label>
              <div class="map">
                <app-map
                  (shotChange_)="shotChange($event)"
                  [shot_active]="shot_active"
                  [goal]="goal"
                ></app-map>
              </div>
            </div>
            <div class="tooltyp form-box" *ngIf="show_tooltyp">
              <div
                class="form-box four-buttons"
                [class.danger]="supervize.oneTimer"
                (contextmenu)="toggleMenu($event, 'oneTimer')"
              >
                <label class="close-tooltip" (click)="show_tooltyp = false"
                  >POČET HRÁČŮ ÚTOČÍCÍHO TÝMU</label
                >
                <table>
                  <tr>
                    <td>
                      <button
                        (click)="selectAttackerCount(4)"
                        [class.active]="attacking_players == 4"
                      >
                        4
                      </button>
                    </td>
                    <td>
                      <button
                        (click)="selectAttackerCount(3)"
                        [class.active]="attacking_players == 3"
                        [disabled]="defending_players > 2"
                      >
                        3
                      </button>
                    </td>
                    <td>
                      <button
                        (click)="selectAttackerCount(2)"
                        [class.active]="attacking_players == 2"
                        [disabled]="defending_players > 1"
                      >
                        2
                      </button>
                    </td>
                    <td>
                      <button
                        (click)="selectAttackerCount(1)"
                        [class.active]="attacking_players == 1"
                        [disabled]="defending_players > 0"
                      >
                        1
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
              <div
                class="form-box four-buttons"
                [class.danger]="supervize.oneTimer"
                (contextmenu)="toggleMenu($event, 'oneTimer')"
              >
                <label>POČET HRÁČŮ BRÁNÍCÍHO TÝMU</label>
                <table>
                  <tr>
                    <td>
                      <button
                        (click)="selectDefendingCount(3)"
                        [class.active]="defending_players == 3"
                        [disabled]="attacking_players < 4"
                      >
                        3
                      </button>
                    </td>
                    <td>
                      <button
                        (click)="selectDefendingCount(2)"
                        [class.active]="defending_players == 2"
                        [disabled]="attacking_players < 3"
                      >
                        2
                      </button>
                    </td>
                    <td>
                      <button
                        (click)="selectDefendingCount(1)"
                        [class.active]="defending_players == 1"
                        [disabled]="attacking_players < 2"
                      >
                        1
                      </button>
                    </td>
                    <td>
                      <button
                        (click)="selectDefendingCount(0)"
                        [class.active]="defending_players == 0"
                      >
                        0
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div
              class="form-box two-buttons"
              [class.danger]="supervize.oneTimer"
              (contextmenu)="toggleMenu($event, 'oneTimer')"
            >
              <div class="tooltip-value-holding">
                <label>Typ střely</label>
                <label
                  *ngIf="
                    attacking_players != undefined &&
                    defending_players != undefined &&
                    oddManRush == true
                  "
                  >{{ attacking_players }}/{{ defending_players }}</label
                >
              </div>

              <table>
                <tr>
                  <td>
                    <button
                      (click)="toggleOneTimer()"
                      [class.active]="oneTimer"
                    >
                      Střela z první
                    </button>
                  </td>
                  <td>
                    <button
                      (click)="toggleOddManRush()"
                      [class.active]="oddManRush"
                    >
                      Z přečíslení
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <button
                      (click)="togglePenaltyShot()"
                      [class.active]="penaltyShot"
                    >
                      Trestné střílení
                    </button>
                  </td>
                  <td>
                    <button
                      (click)="toggleDeflected()"
                      [class.active]="deflected"
                    >
                      Teč
                    </button>
                  </td>
                </tr>
                <div
                  class="tooltyp-top-moment form-box"
                  *ngIf="show_top_moment_tooltyp"
                >
                  <div
                    class="form-box four-buttons"
                    [class.danger]="supervize.oneTimer"
                    (contextmenu)="toggleMenu($event, 'oneTimer')"
                  >
                    <label
                      class="close-tooltip"
                      (click)="show_top_moment_tooltyp = false"
                      >Typ Momentu:</label
                    >
                    <table>
                      <tr>
                        <td>
                          <button
                            (click)="toggleTopMomentType('chance')"
                            [class.active]="top_moment_type == 'chance'"
                          >
                            TOP Šance
                          </button>
                        </td>

                        <td>
                          <button
                            (click)="toggleTopMomentType('save')"
                            [class.active]="top_moment_type == 'save'"
                          >
                            TOP Zákrok
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <tr>
                  <td>
                    <button
                      (click)="toggleScreeningPlayer()"
                      [class.active]="screeningPlayer"
                    >
                      Zacloněná střela
                    </button>
                  </td>
                  <td>
                    <button
                      (click)="toggleTopMomentTooltyp()"
                      [class.active]="
                        top_moment_type != null &&
                        this.top_moment_type != 'goal'
                      "
                      *ngIf="this.page_type != 'supervize'"
                      [disabled]="this.top_moment_type == 'goal'"
                    >
                      TOP moment
                    </button>
                  </td>
                </tr>

                <!--
                <tr>
                  <td>
                    <button (click)="toggleForecheck()" [class.active]="forecheck">Forčeking</button>
                  </td>
                  <td>
                    <button (click)="toggleQuickAttack()" [class.active]="quickAttack">Rychlý útok</button>
                  </td>
                </tr>
                -->
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="canvas-box small">
        <h2>Zisk puku před střelou</h2>
        <div class="box-inner">
          <div class="box">
            <div class="form-box two-inputs">
              <table>
                <tr>
                  <th>Čas časomíry</th>
                  <th>Lokace zisku</th>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      matInput
                      mask="m0:s0"
                      [dropSpecialCharacters]="false"
                      [(ngModel)]="puck_time2"
                      (focus)="focusPuckTime2()"
                      onClick="this.select();"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      (focus)="checkXY2()"
                      (blur)="uncheckXY2()"
                      [(ngModel)]="lokace2"
                      [disabled]="checkTimeDiffs()"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="form-box"
              [class.disabled]="checkTimeDiffs()"
              [class.danger]="supervize_zisk.playerId"
              (contextmenu)="toggleMenuZisk($event, 'playerId')"
            >
              <label>Hráč, který získal puk?</label>
              <div class="player-select">
                <ng-select
                  [items]="getOwnTeamRoster(shootingPlayer.id)"
                  bindLabel="search"
                  bindValue="id"
                  [(ngModel)]="puckWinPlayer.id"
                  [disabled]="checkTimeDiffs()"
                  (focus)="puckWinPlayer.active = true"
                  (blur)="puckWinPlayer.active = false"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position" *ngIf="item.id != ''">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div
              class="form-box two-buttons"
              [class.disabled]="checkTimeDiffs()"
              [class.danger]="supervize_zisk.type"
              (contextmenu)="toggleMenuZisk($event, 'type')"
            >
              <label>Druh zisku puku</label>
              <table>
                <tr>
                  <td>
                    <button
                      (click)="togglePuckType('battle')"
                      [class.active]="puck_type == 'battle'"
                    >
                      V souboji
                    </button>
                  </td>
                  <!-- <td>
                    <button
                      (click)="togglePuckType('no_battle')"
                      [class.active]="puck_type == 'no_battle'"
                      [disabled]="obranne_pasmo_strilejiciho_tymu"
                    >
                      Bez souboje
                    </button>
                  </td> -->
                  <td>
                    <button
                      (click)="togglePuckType('blocked')"
                      [class.active]="puck_type == 'blocked'"
                    >
                      Blok / zachycení
                    </button>
                  </td>
                </tr>
                <!-- <tr>
                  <td>
                    <button
                      (click)="togglePuckType('blocked')"
                      [class.active]="puck_type == 'blocked'"
                    >
                      Blok / zachycení
                    </button>
                  </td>
                  <td></td>
                </tr> -->
              </table>
            </div>
            <div
              class="form-box"
              [class.disabled]="puck_type == 'no_battle' || checkTimeDiffs()"
              [class.danger]="supervize_zisk.gainSharePlayerId"
              (contextmenu)="toggleMenuZisk($event, 'gainSharePlayerId')"
            >
              <label>Podíl na zisku puku</label>
              <div class="player-select">
                <ng-select
                  [items]="getOwnTeamRoster(puckWinPlayer.id)"
                  bindLabel="search"
                  bindValue="id"
                  [(ngModel)]="puckPointsSharedPlayer.id"
                  [disabled]="puck_type === 'no_battle' || checkTimeDiffs()"
                  (focus)="puckPointsSharedPlayer.active = true"
                  (blur)="puckPointsSharedPlayer.active = false"
                  dropdownPosition="top"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position" *ngIf="item.id != ''">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div
              class="form-box"
              [class.disabled]="puck_type == 'no_battle' || checkTimeDiffs()"
              [class.danger]="supervize_zisk.puckLostPlayerId"
              (contextmenu)="toggleMenuZisk($event, 'puckLostPlayerId')"
            >
              <label>Protihráč ztrácející držení puku</label>
              <div class="player-select">
                <ng-select
                  [items]="getOpponentRoster(puckWinPlayer.id)"
                  bindLabel="search"
                  bindValue="id"
                  [(ngModel)]="possesionPuckLoser.id"
                  [disabled]="puck_type === 'no_battle' || checkTimeDiffs()"
                  (focus)="possesionPuckLoser.active = true"
                  (blur)="possesionPuckLoser.active = false"
                  dropdownPosition="top"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position" *ngIf="item.id != ''">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                      <tr>
                        <td style="width: 20px">
                          <img
                            src="/assets/image/logos/{{
                              getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                              vertical-align: top;
                              margin-top: 0px;
                              margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                          />
                        </td>
                        <td style="width: 29px">
                          <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                          <div class="position">
                            {{ formatPosition(item.position) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="canvas-box small">
        <h2>Přihrávka na střelu</h2>
        <div class="box-inner">
          <div class="box">
            <div class="form-box two-buttons">
              <label>Přihrávka na střelu?</label>
              <table>
                <tr>
                  <td>
                    <button
                      (click)="prihravkaNaStreluToggle(true)"
                      [class.active]="prihravka_na_strelu == true"
                    >
                      Ano
                    </button>
                  </td>
                  <td>
                    <button
                      (click)="prihravkaNaStreluToggle(false)"
                      [class.active]="prihravka_na_strelu == false"
                    >
                      Ne
                    </button>
                  </td>
                </tr>
              </table>
            </div>
            <ng-container *ngIf="prihravka_na_strelu">
              <div
                class="form-box"
                [class.danger]="supervize_pass.playerId"
                (contextmenu)="toggleMenuPass($event, 'playerId')"
              >
                <label>Přihrávající hráč</label>
                <div class="player-select">
                  <ng-select
                    [items]="getOwnTeamRoster(shootingPlayer.id)"
                    bindLabel="search"
                    bindValue="id"
                    [(ngModel)]="passingPlayer.id"
                    (focus)="passingPlayer.active = true"
                    (blur)="passingPlayer.active = false"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <table class="ng-select-item">
                        <tr>
                          <td style="width: 20px">
                            <img
                              src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                              }}.png"
                              onerror="this.src='/assets/image/logos/default.png';"
                              onerror="this.src='/assets/image/logos/default.png';"
                              width="18px"
                              height="18px"
                              style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                              "
                              *ngIf="item.id != ''"
                            />
                          </td>
                          <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                          </td>
                          <td>{{ item.surname }} {{ item.name }}</td>
                          <td style="width: 16px">
                            <div class="position" *ngIf="item.id != ''">
                              {{ formatPosition(item.position) }}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                      <table class="ng-select-item">
                        <tr>
                          <td style="width: 20px">
                            <img
                              src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                              }}.png"
                              onerror="this.src='/assets/image/logos/default.png';"
                              onerror="this.src='/assets/image/logos/default.png';"
                              width="18px"
                              height="18px"
                              style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                              "
                              *ngIf="item.id != null"
                            />
                          </td>
                          <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                          </td>
                          <td>{{ item.surname }} {{ item.name }}</td>
                          <td style="width: 16px">
                            <div class="position">
                              {{ formatPosition(item.position) }}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="form-box two-inputs">
                <table>
                  <tr>
                    <th>Čas časomíry</th>
                    <th>Lokace přihrávky</th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="text"
                        matInput
                        mask="m0:s0"
                        [dropSpecialCharacters]="false"
                        [(ngModel)]="puck_time3"
                        (focus)="focusPuckTime3()"
                        onClick="this.select();"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        (focus)="checkXY3()"
                        (blur)="uncheckXY3()"
                        [(ngModel)]="lokace3"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </ng-container>
            <div class="submits">
              <button class="submit" (click)="submit('normal')">
                Uložit záznam
              </button>
              <button
                class="submit"
                (click)="submit('save_next')"
                *ngIf="editPage"
              >
                Uložit a přejít na další
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="canvas-box">
        <h2>&nbsp;</h2>
        <div class="box-inner">
          <!--[class.reversed]="reversed_sides"-->
          <div
            class="playground"
            [class.show_playground]="show_playground"
            (click)="onMapClick($event)"
          >
            <ng-container *ngIf="!reversed_sides">
              <img
                src="/assets/image/logos/{{ homeShortcut }}.png"
                onerror="this.src='/assets/image/logos/default.png';"
                class="home-logo"
              />

              <img
                src="/assets/image/logos/{{ awayShortcut }}.png"
                onerror="this.src='/assets/image/logos/default.png';"
                class="away-logo"
              />
            </ng-container>
            <ng-container *ngIf="reversed_sides">
              <img
                src="/assets/image/logos/{{ awayShortcut }}.png"
                onerror="this.src='/assets/image/logos/default.png';"
                class="home-logo"
              />
              <img
                src="/assets/image/logos/{{ homeShortcut }}.png"
                onerror="this.src='/assets/image/logos/default.png';"
                class="away-logo"
              />
            </ng-container>

            <div
              class="coordinate1"
              [ngStyle]="{
                'left.px': getCoordinatesMap(lokace, 'x'),
                'top.px': getCoordinatesMap(lokace, 'y')
              }"
              *ngIf="lokace != ''"
            ></div>

            <div
              class="coordinate2"
              [ngStyle]="{
                'left.px': getCoordinatesMap(lokace2, 'x'),
                'top.px': getCoordinatesMap(lokace2, 'y')
              }"
              *ngIf="lokace2 != ''"
            ></div>

            <div
              class="coordinate3"
              [ngStyle]="{
                'left.px': getCoordinatesMap(lokace3, 'x'),
                'top.px': getCoordinatesMap(lokace3, 'y')
              }"
              *ngIf="lokace3 != ''"
            ></div>

            <button class="toggle-button" (click)="showPlayground()"></button>
            <img src="/assets/image/hriste-634x312.svg" />
          </div>
          <div class="coordinates-list">
            <div class="form-box">
              <label>Střela</label>
              <div class="input-box red">
                <input
                  type="text"
                  (focus)="checkXY()"
                  (blur)="uncheckXY()"
                  [(ngModel)]="lokace"
                />
              </div>
            </div>
            <div class="form-box">
              <label>Zisk puku</label>
              <div class="input-box blue">
                <input
                  type="text"
                  (focus)="checkXY2()"
                  (blur)="uncheckXY2()"
                  [(ngModel)]="lokace2"
                />
              </div>
            </div>
            <div class="form-box">
              <label>Přihrávka na střelu</label>
              <div class="input-box green">
                <input
                  type="text"
                  (focus)="checkXY3()"
                  (blur)="uncheckXY3()"
                  [(ngModel)]="lokace3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <loading-small *ngIf="loading"></loading-small>

  <div
    class="menu"
    *ngIf="contextmenu"
    [ngStyle]="{ 'left.px': contextmenuX, 'top.px': contextmenuY }"
  >
    <a (click)="toggleSupervize()" class="supervize">Supervize</a>
    <a (click)="toggleHelp()" class="help">Nápověda</a>
  </div>

  <div
    class="help-box"
    *ngIf="showhelp"
    [ngStyle]="{ 'left.px': contextmenuX, 'top.px': contextmenuY }"
  >
    <h3>{{ help_title }}</h3>
    <button class="closebtn" (click)="showhelp = false"></button>
    <div class="desc">
      {{ help_desc }}
    </div>
  </div>
</div>
