<!--
<app-nav></app-nav>
-->
<div class="disabled-watch" *ngIf="disabledWatch">
  <h3>Sběr dat u tohoto zápasu byl ukončen. Vraťte se na výpis zápasů.</h3>
  <button (click)="goToSelect()">Výpis zápasů</button>
</div>
<div class="page tracking type-{{ page_type }}" *ngIf="loaded">
  <div class="container main">
    <match-info
      [game]="game"
      [sidesPeriod]="sidesPeriod"
      [minute]="minute"
      [second]="second"
      [overtimeLength]="overtimeLength"
      [filterEventFlow]="filterEventFlow"
      [changeSidesLock]="changeSidesLock"
      [period]="period"
      (onLoadPlayersOnIce)="loadPlayersOnIce($event)"
      (onPeriodChange)="period = $event"
      (onMinuteChange)="minute = $event"
      (onSecondChange)="second = $event"
      (onSidesPeriodChange)="sidesPeriod = $event"
      (onFilterEventFlowChange)="filterEventFlow = $event"
      (onLogout)="logout()"
    >
    </match-info>
    <tracking-box
      [class.reversed]="sidesPeriod[period]"
      [toggle_adding_nav]="toggle_adding_nav"
      [activeFormation]="activeFormation"
      [show_shootout]="show_shootout"
      [show_faceOff]="show_faceOff"
      [show_shot]="show_shot"
      [show_zoneEntry]="show_zoneEntry"
      [show_hit]="show_hit"
      [show_dumpIn]="show_dumpIn"
      [show_zoneExit]="show_zoneExit"
      [show_dumpOut]="show_dumpOut"
      [show_offensiveZoneLoss]="show_offensiveZoneLoss"
      [show_penalty]="show_penalty"
      [page_type]="page_type"
      [game]="game"
      [enabled_camera]="enabled_camera"
      [video]="video"
      [videoId]="videoId"
      [shootoutComponent]="shootoutComponent"
      [shotComponent]="shotComponent"
      [zoneEntryComponent]="zoneEntryComponent"
      [dumpInComponent]="dumpInComponent"
      [offensiveZoneLossComponent]="offensiveZoneLossComponent"
      [hitComponent]="hitComponent"
      [penaltyComponent]="penaltyComponent"
      [zoneExitComponent]="zoneExitComponent"
      [dumpOutComponent]="dumpOutComponent"
      [sidesPeriod]="sidesPeriod"
      [period]="period"
      [test_x]="test_x"
      [test_y]="test_y"
      (onCoordinatesChange)="onCoordinatesChange($event)"
      (onClose)="close($event)"
    >
    </tracking-box>

    <tracking-nav
      class="tracking-nav"
      [class.show]="toggle_adding_nav"
      [show_faceOff]="show_faceOff"
      [show_shot]="show_shot"
      [show_zoneEntry]="show_zoneEntry"
      [show_hit]="show_hit"
      [show_dumpIn]="show_dumpIn"
      [show_zoneExit]="show_zoneExit"
      [show_dumpOut]="show_dumpOut"
      [show_offensiveZoneLoss]="show_offensiveZoneLoss"
      [show_shootout]="show_shootout"
      [show_penalty]="show_penalty"
      [show_topMoment]="show_topMoment"
      [show_penaltyShootout]="show_penaltyShootout"
      (onToggleCanvas)="toggleCanvas($event, false)"
    ></tracking-nav>

    <tracking-selects
      class="tracking-selects"
      [show_faceOff]="show_faceOff"
      [show_shot]="show_shot"
      [show_zoneEntry]="show_zoneEntry"
      [show_hit]="show_hit"
      [show_dumpIn]="show_dumpIn"
      [show_zoneExit]="show_zoneExit"
      [show_dumpOut]="show_dumpOut"
      [show_offensiveZoneLoss]="show_offensiveZoneLoss"
      [show_shootout]="show_shootout"
      [show_penalty]="show_penalty"
      [show_topMoment]="show_topMoment"
      [show_penaltyShootout]="show_penaltyShootout"
      [id]="id"
      [page_type]="page_type"
      [period]="period"
      [event_list]="event_list"
      [game]="game"
      [langData]="langData"
      [filter_supervize]="filter_supervize"
      [filter_period]="filter_period"
      [filter_post]="filter_post"
      [filter_team]="filter_team"
      [filter_events]="filter_events"
      [filter_error_types]="filterErrorTypes"
      [filter_event_flow]="filterEventFlow"
      [eventflow]="eventflow"
      (onToggleFilterSupervize)="toggleFilterSupervize()"
      (onLoadEventFlow)="requireEventFlow = true"
      (onSetPeriod)="setPeriod($event)"
      (onFilterEventFlow)="onFilterEventFlow($event)"
      (onSync)="sync()"
    ></tracking-selects>
  </div>

  <eventflow
    [show_faceOff]="show_faceOff"
    [show_shot]="show_shot"
    [show_zoneEntry]="show_zoneEntry"
    [show_hit]="show_hit"
    [show_dumpIn]="show_dumpIn"
    [show_zoneExit]="show_zoneExit"
    [show_dumpOut]="show_dumpOut"
    [show_offensiveZoneLoss]="show_offensiveZoneLoss"
    [show_shootout]="show_shootout"
    [show_penalty]="show_penalty"
    [show_topMoment]="show_topMoment"
    [show_penaltyShootout]="show_penaltyShootout"
    [id]="id"
    [game]="game"
    [editData]="editData"
    [sidesPeriod]="sidesPeriod"
    [period]="period"
    [pageType]="page_type"
    [overtimeLength]="overtimeLength"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [toggle_adding_nav]="toggle_adding_nav"
    [render_eventflow]="render_eventflow"
    [filterEventFlow]="filterEventFlow"
    [requireEventFlow]="requireEventFlow"
    (onToggleCanvas)="toggleCanvas($event, true)"
    (onSetCasomiraByEvent)="setCasomiraByEvent($event, true)"
    (onLoading)="loading = $event"
    (onClose)="close($event)"
    (onRequireEventFlowChange)="requireEventFlow = $event"
    (onEventFlowChange)="eventflow = $event"
    (onEditDataChange)="editData = $event"
  >
  </eventflow>

  <faceOff-canvas
    *ngIf="show_faceOff"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [casomira]="getCasomiraSeconds()"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
  >
  </faceOff-canvas>

  <shot-canvas
    *ngIf="show_shot"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [test_x]="test_x"
    [test_y]="test_y"
    [casomira]="getCasomiraSeconds()"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    [invalidCasomira]="invalidCasomira"
    [activeFormation]="activeFormation"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
    (onCoordinatesChange)="onCoordinatesChange($event)"
  >
  </shot-canvas>

  <zoneEntry-canvas
    *ngIf="show_zoneEntry"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    [selected_x]="test_x"
    [selected_y]="test_y"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
    (onCoordinatesChange)="onCoordinatesChange($event)"
  >
  </zoneEntry-canvas>

  <dumpIn-canvas
    *ngIf="show_dumpIn"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    [selected_x]="test_x"
    [selected_y]="test_y"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    (onCoordinatesChange)="onCoordinatesChange($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
  >
  </dumpIn-canvas>

  <zoneExit-canvas
    *ngIf="show_zoneExit"
    (closeCanvas)="close($event)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    (onCoordinatesChange)="onCoordinatesChange($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
    [selected_x]="test_x"
    [selected_y]="test_y"
  >
  </zoneExit-canvas>

  <dumpOut-canvas
    *ngIf="show_dumpOut"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    (onCoordinatesChange)="onCoordinatesChange($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
  >
  </dumpOut-canvas>

  <offensiveZoneLoss-canvas
    *ngIf="show_offensiveZoneLoss"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    [selected_x]="test_x"
    [selected_y]="test_y"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
  >
  </offensiveZoneLoss-canvas>

  <hit-canvas
    *ngIf="show_hit"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
  ></hit-canvas>

  <shootout-canvas
    *ngIf="show_shootout"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (reloadTrueVideo)="openVideoTrueTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
  >
  </shootout-canvas>

  <penalty-canvas
    *ngIf="show_penalty"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
  >
  </penalty-canvas>

  <app-top-moment
    *ngIf="show_topMoment"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
    [game]="game"
  >
  </app-top-moment>

  <app-penalty-shootout
    *ngIf="show_penaltyShootout"
    (closeCanvas)="close(undefined)"
    [page_type]="page_type"
    [roster_home]="roster_home"
    [roster_away]="roster_away"
    [period]="period"
    [minute]="minute"
    [second]="second"
    [editData]="editData"
    [reversed_sides]="sidesPeriod[period]"
    (toast)="toast($event)"
    (changeCasomira)="changeCasomira($event)"
    (reloadVideo)="openVideoTime($event)"
    (reloadTrueVideo)="openVideoTrueTime($event)"
    (saveType)="saveType($event)"
    [homeShortcut]="game.gameData.homeTeam.shortcut"
    [awayShortcut]="game.gameData.awayTeam.shortcut"
  >
  </app-penalty-shootout>
  <loading *ngIf="loading"></loading>
</div>
