import { TranslateSecondsPipe } from './translate-seconds.pipe';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'snapShotKeys'
})
export class KeysPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private translateSecondsPipe: TranslateSecondsPipe) {
  }
  transform(value, type?: string): any {
    if(type?.length > 0)
      return this.handleTypeTransformations(value, type);
    let keys = [];
    for (let key in value)
      keys.push({name: key, new: value[key].new, old: value[key].old});
    return keys;
  }

  /**
   * Parses the attribute type.
   * @param attribute Attribute of the log.
   * @param type New | Old
   * @returns Parsed attribute type.
   */
  handleTypeTransformations(attribute, type: string) {
    if(attribute[type] == null || attribute[type].length < 1) return "-";
    if(attribute.name == "coordinates") {
      if(type == "old")
        return "x: " + attribute[type].x + ", y: " + attribute[type].y;
      let c: number = Math.round(Math.sqrt(Math.pow(attribute["old"].x * 0.3 - attribute["new"].x * 0.3, 2) + Math.pow(attribute["old"].y * 0.15 - attribute["new"].y * 0.15, 2)) * 10) / 10;
      return "x: " + attribute[type].x + ", y: " + attribute[type].y + " (změna o " + c + "m)";
    }
    else if(attribute.name == "timestamp")
      return this.datePipe.transform(attribute[type], "dd. MM. yyyy, hh:mm:ss");
      else if(attribute.name == "time")
        return this.translateSecondsPipe.transform(attribute[type]);
    else if(attribute.name == "cleanWin" || attribute.name == "oneTimer")
      return attribute[type] === true ? "Ano" : "Ne";
    else if(attribute.name == "result") {
      if(attribute[type] === "missed") return "Mimo"
      if(attribute[type] === "deflected") return "Zblokovaná"
      if(attribute[type] === "goal") return "Gól"
      if(attribute[type] === "saved") return "Chycená"
      if(attribute[type] === "post") return "Tyč"
      return attribute[type];
    }
    return attribute[type];
  }
}