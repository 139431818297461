import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { getApiUrl } from '../utils';

@Injectable()
export class PenaltiesService {
  token: string;
  api_url: string = getApiUrl();

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getPenalty(matchId: number) {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http
      .get(
        this.api_url + '/api/penalty/' + matchId + '?cache=' + Date.now(),
        header
      )
      .pipe(map((res) => res));
  }

  updatePenalty(matchId: number, id: number, data: any) {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    /*
        let data = {
            "coordinates": {
                "x": 0,
                "y": 0
            },
            "isChangingGameState": null,
            "disciplinedTeamStrength": null,
            "fouledTeamStrength": null
        };
        */

    return this.http
      .put(
        this.api_url +
          '/api/penalty/' +
          matchId +
          '/' +
          id +
          '?cache=' +
          Date.now(),
        data,
        header
      )
      .pipe(map((res) => res));
  }

  updateSupervize(matchId: number, id: number, data: any) {
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http
      .put(
        this.api_url +
          '/api/supervision/penalty/' +
          matchId +
          '/' +
          id +
          '?cache=' +
          Date.now(),
        data,
        header
      )
      .pipe(map((res) => res));
  }
}
