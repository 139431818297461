<app-nav></app-nav>

<div class="page-heading">
    <div class="heading-inner">
        <h1>Chyby a upozornění</h1>
        <select [(ngModel)]="selectedLeague" (change)="filterLeagues();" class="select-large-width">
            <option value="-1">Vyberte soutěž</option>
            <option *ngFor="let league of leagues" value="{{ league }}">{{ league }}</option>
        </select>
    </div>
</div>

<div class="page">
    <div class="sides">
        <div class="game-pane" *ngIf="matchesToDisplay?.length > 0">
            <div class="buttons">
                <div class="select-all">
                    <div (click)="onMainCheckboxClicked(!selectAll);">
                        <checkbox [checked]="selectAll"></checkbox>
                        Vybrat vše
                    </div>
                </div>
                <div class="load-errors">
                    <button class="button" (click)="loadEvents();">Načíst chyby</button>
                </div>
            </div>
            <div class="game game-header">
                <div class="game-info">
                    <div class="game-checkbox">
                    </div>
                    <div class="game-date clickable" (click)="sortBy('date');">
                        Datum <img [src]="arrowIcon" alt="Filter" *ngIf="sortingColumnName == 'date'" />
                    </div>
                    <div class="game-teams">
                    </div>
                    <div class="error-count clickable" (click)="sortBy('error-count');">
                        Chyby <img [src]="arrowIcon" alt="Filter" *ngIf="sortingColumnName == 'error-count'" />
                    </div>
                    <div class="notification-count clickable" (click)="sortBy('notification-count');">
                        Upoz. <img [src]="arrowIcon" alt="Filter" *ngIf="sortingColumnName == 'notification-count'" />
                    </div>
                </div>
            </div>
            <div class="game" [class.active]="match.selected" *ngFor="let match of matchesToDisplay; let i = index;">
                <div class="game-info">
                    <div class="game-checkbox" (click)="onCheckboxClicked(match);">
                        <checkbox [checked]="match.selected"></checkbox>
                    </div>
                    <div class="game-date">
                        {{ match.date | date: "dd.MM.yyyy" }}
                    </div>
                    <div class="game-teams">
                        <div class="team-left">
                            <img src="/assets/image/logos/{{ match.homeShortcut }}.png" title="{{ match.home }}"
                            onerror="this.src='/assets/image/logos/default.png';" alt="Domácí" />
                        </div>
                        <div class="game-team-names">
                            {{ match.homeShortcut }}-{{ match.awayShortcut }}
                        </div>
                        <div class="team-right">
                            <img src="/assets/image/logos/{{ match.awayShortcut }}.png" title="{{ match.away }}"
                            onerror="this.src='/assets/image/logos/default.png';" alt="Hosté" />
                        </div>
                    </div>
                    <div class="error-count" [innerHTML]='match.events | eventTransform: "error"'></div>
                    <div class="notification-count" [innerHTML]='match.events | eventTransform: "warning"'></div>
                </div>
            </div>
        </div>
        <div class="event-pane" *ngIf="!loading">
            <div class="event-list">
                <div class="event-node" *ngFor="let event of events; let i = index">
                    <div class="data">
                        <div class="no-collapsable">
                            <div class="event-icon">
                                <img src="/assets/image/icon-{{ event.icon === 'error' ? 'error' : 'warning' }}-colored.svg" alt="Typ chyby" />
                            </div>
                            <div class="event-rule">
                                {{ event.rule | errorWarningTranslator: event.icon }}
                            </div>
                            <div class="event-count">
                                {{ event.matches.length }}
                            </div>
                        </div>
                        <div class="collapsable">
                            <div class="sub-data sub-data-header">
                                <div class="date">
                                    Datum
                                </div>
                                <div class="teams">
                                    Zápas
                                </div>
                                <div class="event-type">
                                    Událost
                                </div>
                                <div class="time">
                                    Čas
                                </div>
                            </div>
                            <div class="sub-data" *ngFor="let match of event.matches; let i = index;">
                                <div class="date">
                                    {{ match.date | date: "dd.MM.yyyy" }}
                                </div>
                                <div class="teams">
                                    <div class="team-left">
                                        <img src="/assets/image/logos/{{ match.homeShortcut }}.png" title="{{ match.home }}"
                                        onerror="this.src='/assets/image/logos/default.png';" alt="Domácí" />
                                    </div>
                                    <div class="game-team-names">
                                        {{ match.homeShortcut }}-{{ match.awayShortcut }}
                                    </div>
                                    <div class="team-right">
                                        <img src="/assets/image/logos/{{ match.awayShortcut }}.png" title="{{ match.away }}"
                                        onerror="this.src='/assets/image/logos/default.png';" alt="Hosté" />
                                    </div>
                                </div>
                                <div class="event-type">
                                    {{ event.events[i].event | eventTranslate }}
                                </div>
                                <div class="time">
                                    {{ event.events[i].time | translateSeconds }}
                                </div>
                                <div class="button">
                                    <button (click)="loadEditing(match);">Editovat</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button">
                        <toggle-button (onToggle)="collapseEdit(i);"></toggle-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<loading *ngIf="loading"></loading>