<app-nav></app-nav>

<div class="page-heading">
    <div class="heading-inner">
        <h1>Přehled úprav</h1>
        <select [(ngModel)]="selectedLeague" (change)="filterLeagues();" class="select-large-width">
            <option value="-1">Vyberte soutěž</option>
            <option *ngFor="let league of leagues" value="{{ league }}">{{ league }}</option>
        </select>
    </div>
</div>

<div class="page">
    <div class="sides">
        <div class="game-pane">
            <div class="game" *ngFor="let match of matchesToDisplay">
                <div class="game-info">
                    <div class="game-date">
                        {{ match.date | date: "dd.MM.yyyy" }}
                    </div>
                    <div class="game-teams">
                        <div class="team-left">
                            <img src="/assets/image/logos/{{ match.homeShortcut }}.png"
                            onerror="this.src='/assets/image/logos/default.png';" alt="Domácí" />
                        </div>
                        <div class="game-team-names">
                            {{ match.homeShortcut }}-{{ match.awayShortcut }}
                        </div>
                        <div class="team-right">
                            <img src="/assets/image/logos/{{ match.awayShortcut }}.png"
                            onerror="this.src='/assets/image/logos/default.png';" alt="Hosté" />
                        </div>
                    </div>
                </div>
                <div class="game-button">
                    <button (click)="loadEditing(match.id);">Načíst opravy</button>
                </div>
            </div>
        </div>
        <div class="edit-pane" *ngIf="selectedMatch">
            <match-detail-header *ngIf="!loading"
            [match]="selectedMatch"
            [editArray]="editArray"
            [eventArray]="eventArray"
            [userArray]="userArray"
            [time]="time"
            (onEditSelectChanged)="editChanged($event);"
            (onEventSelectChanged)="eventChanged($event);"
            (onUserSelectChanged)="userChanged($event);"
            (onTimeSelectChanged)="timeChanged($event);">
            </match-detail-header>
            <div class="edit-list">
                <div class="edit-node edit-node-header">
                    <div class="data">
                        <div class="no-collapsable">
                            <div class="edit-type">
                                Typ úpravy
                            </div>
                            <div class="id">
                                ID
                            </div>
                            <div class="edit-time">
                                Čas úpravy
                            </div>
                            <div class="user">
                                Uživatel
                            </div>
                            <div class="type">
                                Událost
                            </div>
                            <div class="time">
                                Čas
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edit-node" *ngFor="let log of logDisplayArray; let i = index">
                    <div class="data">
                        <div class="no-collapsable">
                            <div class="edit-type">
                                {{ log.type | typeTranslate }}
                            </div>
                            <div class="id">
                                {{ log.entityId }}
                            </div>
                            <div class="edit-time">
                                {{ log.date | date:"dd.MM.yyyy, hh:mm:ss" }}
                            </div>
                            <div class="user">
                                {{ log.identityName }}
                            </div>
                            <div class="type">
                                {{ log.entity | eventTranslate }}
                            </div>
                            <div class="time">
                                {{ log.gameTime | translateSeconds }}
                            </div>
                        </div>
                        <div class="collapsable">
                            <ng-container *ngIf="hasSnapshot(log);">
                                <div class="sub-data sub-data-header">
                                    <div class="attribute">
                                        Atribut
                                    </div>
                                    <div class="previous-value">
                                        Původní hodnota
                                    </div>
                                    <div class="new-value">
                                        Nová hodnota
                                    </div>
                                </div>
                                <div class="sub-data" *ngFor="let key of log.snapshot | snapShotKeys">
                                    <div class="attribute">
                                        {{ key.name }}
                                    </div>
                                    <div class="previous-value">
                                        {{ key | snapShotKeys: "old" }}
                                    </div>
                                    <div class="new-value">
                                        {{ key | snapShotKeys: "new" }}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!hasSnapshot(log);">
                                <p class="no-attribute">Tato událost nemá žádný atribut.</p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="button" *ngIf="log.type == 'update'">
                        <toggle-button (onToggle)="collapseEdit(i);"></toggle-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<loading *ngIf="loading"></loading>