import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extraPlayerErrorsSectionTranslate'
})
export class ExtraPlayerErrorsSectionTranslatePipe implements PipeTransform {

  /**
   * Translates the section of the ExtraPlayer Error page into the normal readable string.
   * @param section string represented code to be translated.
   * @returns Translated section into the Czech language.
   */
  transform(section: string): string {
    let sectionArray: {[key: string]: string} = {
      'wrong_player': 'Chybně označený hráč u události',
      'wrong_time': 'Špatný čas střídání',
      'other': 'Jiná chyba',
      'resolved': 'Vyřešeno'
    };

    let foundedSection = sectionArray[section];
    if(foundedSection !== undefined && foundedSection !== null)
      return foundedSection;

    return null;
  }

}
