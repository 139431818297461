<div
  class="container canvas"

  oncontextmenu="return false;"
>
  <button class="close" (click)="close()">Zavřít bez uložení</button>
  <div class="canvas-scroll" #scroller>
    <div class="canvas-data">
      <div class="canvas-box">
        <h2>
          Top moment
          <button class="replay" (click)="replay(time)"></button>
        </h2>
        <div class="box-inner">
          <div class="box">
            <div class="form-box two-inputs">
              <table>
                <tr>
                  <th>Čas časomíry</th>
                  <th>Třetina</th>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      matInput
                      mask="m0:s0"
                      [dropSpecialCharacters]="false"
                      [(ngModel)]="time"
                      (blur)="sendTimeCasomira()"
                      (focus)="focusTime()"
                      onClick="this.select();"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="period"
                      class="noedit"
                      disabled
                    />
                  </td>
                </tr>
              </table>
            </div>

            <div
              class="form-box two-buttons"
              [class.danger]="supervize.result"
              (contextmenu)="toggleMenu($event, 'result')"
            >
              <table>
                <tr>
                  <td>
                    <button
                      (click)="setResult('fight')"
                      [class.active]="result == 'fight'"
                    >
                    Bitka, šarvátka
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      (click)="setResult('hit')"
                      [class.active]="result == 'hit'"
                    >
                    Velký hit, důrazný souboj
                    </button>
                  </td>
                </tr>
                <tr>
                    <td >
                        <button
                          (click)="setResult('controversy')"
                          [class.active]="result == 'controversy'"
                        >
                        Sporný moment, odvolaný gól
                        </button>

                      </td>
                </tr>
                <tr>
                    <td>
                        <button
                        (click)="setResult('curiosity')"
                        [class.active]="result == 'curiosity'"
                      >
                      Trenéři, diváci, kuriozity, ostatni
                      </button>
                    </td>
                </tr>
                <tr>
                  <td>
                      <button
                      (click)="setResult('chance')"
                      [class.active]="result == 'chance'"
                    >
                    Šance bez střely
                    </button>
                  </td>
              </tr>
              </table>
            </div>

            <div
            class="form-box"
            [class.danger]="supervize.loserId"
            (contextmenu)="toggleMenu($event, 'loserId')"
            *ngIf="page_type != 'supervize'"
          >
            <label>LIVE VIDEOČAS TOP MOMENTU</label>
            <input class="noedit" id="liveVideotime" appTimeMask matInput [(ngModel)]="liveVideoTime" />
          </div>

          </div>
          <div class="box">

            <div
            class="form-box"
            [class.danger]="supervize.playerId"
            (contextmenu)="toggleMenu($event, 'playerId')"
          >
            <label>HRÁČ #1</label>
            <div class="player-select">
              <ng-select
                [items]="roster_all"
                bindLabel="search"
                bindValue="id"
                dropdownPosition="bottom"
                [(ngModel)]="player1"
                (focus)="player1_active = true"
                (blur)="player1_active = false"
                (change)="changePlayer1()"
              >
                <ng-template ng-label-tmp let-item="item">
                  <table class="ng-select-item">
                    <tr>
                      <td style="width: 20px">
                        <img
                          src="/assets/image/logos/{{
                            getPlayerTeamShortcut(item.id)
                          }}.png"
                          onerror="this.src='/assets/image/logos/default.png';"
                          onerror="this.src='/assets/image/logos/default.png';"
                          width="18px"
                          height="18px"
                          style="
                            vertical-align: top;
                            margin-top: 0px;
                            margin-right: 4px;
                          "
                          *ngIf="item.id != ''"
                        />
                      </td>
                      <td style="width: 29px">
                        <div class="jersey">{{ item.jersey }}</div>
                      </td>
                      <td>{{ item.surname }} {{ item.name }}</td>
                      <td style="width: 16px">
                        <div class="position" *ngIf="item.id != ''">
                          {{ formatPosition(item.position) }}
                        </div>
                      </td>
                    </tr>
                  </table>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  <table class="ng-select-item">
                    <tr>
                      <td style="width: 20px">
                        <img
                          src="/assets/image/logos/{{
                            getPlayerTeamShortcut(item.id)
                          }}.png"
                          onerror="this.src='/assets/image/logos/default.png';"
                          onerror="this.src='/assets/image/logos/default.png';"
                          width="18px"
                          height="18px"
                          style="
                            vertical-align: top;
                            margin-top: 0px;
                            margin-right: 4px;
                          "
                          *ngIf="item.id != null"
                        />
                      </td>
                      <td style="width: 29px">
                        <div class="jersey">{{ item.jersey }}</div>
                      </td>
                      <td>{{ item.surname }} {{ item.name }}</td>
                      <td style="width: 16px">
                        <div class="position">
                          {{ formatPosition(item.position) }}
                        </div>
                      </td>
                    </tr>
                  </table>
                </ng-template>
              </ng-select>
            </div>
            </div>

            <div
                class="form-box"
                [class.disabled]="
                result == 'saved' || result == 'missed' || result == 'goal'|| result == 'post'
                "
                [class.danger]="supervize.blockerId"
                (contextmenu)="toggleMenu($event, 'blockerId')"
            >
                <label>HRÁČ #2</label>
                <div class="player-select">
                <ng-select
                    [items]="roster_all"
                    bindLabel="search"
                    bindValue="id"
                    [(ngModel)]="player2"
                    [disabled]="
                    result == 'saved' || result == 'missed' || result == 'goal'|| result == 'post'
                    "
                    (focus)="player2_active = true"
                    (blur)="player2_active = false"
                >
                    <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                        <tr>
                        <td style="width: 20px">
                            <img
                            src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                            />
                        </td>
                        <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                            <div class="position" *ngIf="item.id != ''">
                            {{ formatPosition(item.position) }}
                            </div>
                        </td>
                        </tr>
                    </table>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                        <tr>
                        <td style="width: 20px">
                            <img
                            src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                            />
                        </td>
                        <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                            <div class="position">
                            {{ formatPosition(item.position) }}
                            </div>
                        </td>
                        </tr>
                    </table>
                    </ng-template>
                </ng-select>
                </div>
            </div>


            <div
            class="form-box"
            [class.disabled]="puck_type == 'no_battle' || checkTimeDiffs()"
            [class.danger]="supervize_zisk.puckLostPlayerId"
            (contextmenu)="toggleMenuZisk($event, 'puckLostPlayerId')"
            style="margin-top: 60px;"
            >
            <label>TRENER</label>
            <div class="player-select">
                <ng-select
                [items]="functionaries"
                bindLabel="search"
                bindValue="id"
                [(ngModel)]="player6"
                [disabled]="puck_type == 'no_battle' || checkTimeDiffs()"
                (focus)="player6_active = true"
                (blur)="player6_active = false"
                dropdownPosition="top"
                >
                <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                    <tr>
                        <td style="width: 20px">
                        <img
                            src="/assets/image/logos/{{
                            getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                            vertical-align: top;
                            margin-top: 0px;
                            margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                        />
                        </td>
                        <td style="width: 29px">
                        <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                        </td>
                    </tr>
                    </table>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                    <tr>
                        <td style="width: 20px">
                        <img
                            src="/assets/image/logos/{{
                            getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                            vertical-align: top;
                            margin-top: 0px;
                            margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                        />
                        </td>
                        <td style="width: 29px">
                        <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                        <div class="position">
                            {{ formatPosition(item.position) }}
                        </div>
                        </td>
                    </tr>
                    </table>
                </ng-template>
                </ng-select>
            </div>
            </div>

            <div class="submits">
                <button class="submit saveButton" (click)="submit('normal')">
                  Uložit záznam
                </button>
                <button
                  class="edit"
                  (click)="submit('save_next')"
                  *ngIf="editPage"
                >
                  Uložit a přejít na další
                </button>
            </div>

          </div>
        </div>
      </div>

      <div class="canvas-box small">
        <div class="box-inner">
            <div class="box">


                <div
                class="form-box"
                [class.disabled]="result == 'missed' || result == 'deflected' || result == 'post' "
                [class.danger]="supervize.goalkeeperId"
                (contextmenu)="toggleMenu($event, 'goalkeeperId')"
            >
                <label>HRÁČ #3</label>
                <div class="player-select">

                <ng-select
                    [items]="roster_all"
                    bindLabel="search"
                    bindValue="id"
                    [(ngModel)]="player3"
                    [disabled]="result == 'missed' || result == 'deflected' || result == 'post'"
                    (focus)="player3_active = true"
                    (blur)="player3_active = false"
                    (change)="changeGoalie($event)"
                >
                    <ng-template ng-label-tmp let-item="item">
                    <table class="ng-select-item">
                        <tr>
                        <td style="width: 20px">
                            <img
                            src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                            "
                            *ngIf="item.id != ''"
                            />
                        </td>
                        <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                            <div class="position" *ngIf="item.id != ''">
                            {{ formatPosition(item.position) }}
                            </div>
                        </td>
                        </tr>
                    </table>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                    <table class="ng-select-item">
                        <tr>
                        <td style="width: 20px">
                            <img
                            src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                            }}.png"
                            onerror="this.src='/assets/image/logos/default.png';"
                            onerror="this.src='/assets/image/logos/default.png';"
                            width="18px"
                            height="18px"
                            style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                            "
                            *ngIf="item.id != null"
                            />
                        </td>
                        <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                        </td>
                        <td>{{ item.surname }} {{ item.name }}</td>
                        <td style="width: 16px">
                            <div class="position">
                            {{ formatPosition(item.position) }}
                            </div>
                        </td>
                        </tr>
                    </table>
                    </ng-template>
                </ng-select>

                </div>
                </div>


                <div
                class="form-box"
                [class.disabled]="checkTimeDiffs()"
                [class.danger]="supervize_zisk.playerId"
                (contextmenu)="toggleMenuZisk($event, 'playerId')"
                >
                <label>HRÁČ #4</label>
                <div class="player-select">
                    <ng-select
                    [items]="roster_all"
                    bindLabel="search"
                    bindValue="id"
                    [(ngModel)]="player4"
                    [disabled]="checkTimeDiffs()"
                    (focus)="player4_active = true"
                    (blur)="player4_active = false"
                    >
                    <ng-template ng-label-tmp let-item="item">
                        <table class="ng-select-item">
                        <tr>
                            <td style="width: 20px">
                            <img
                                src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                                }}.png"
                                onerror="this.src='/assets/image/logos/default.png';"
                                onerror="this.src='/assets/image/logos/default.png';"
                                width="18px"
                                height="18px"
                                style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                                "
                                *ngIf="item.id != ''"
                            />
                            </td>
                            <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                            </td>
                            <td>{{ item.surname }} {{ item.name }}</td>
                            <td style="width: 16px">
                            <div class="position" *ngIf="item.id != ''">
                                {{ formatPosition(item.position) }}
                            </div>
                            </td>
                        </tr>
                        </table>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                        <table class="ng-select-item">
                        <tr>
                            <td style="width: 20px">
                            <img
                                src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                                }}.png"
                                onerror="this.src='/assets/image/logos/default.png';"
                                onerror="this.src='/assets/image/logos/default.png';"
                                width="18px"
                                height="18px"
                                style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                                "
                                *ngIf="item.id != null"
                            />
                            </td>
                            <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                            </td>
                            <td>{{ item.surname }} {{ item.name }}</td>
                            <td style="width: 16px">
                            <div class="position">
                                {{ formatPosition(item.position) }}
                            </div>
                            </td>
                        </tr>
                        </table>
                    </ng-template>
                    </ng-select>
                </div>
                </div>

                <div
                class="form-box"
                [class.disabled]="puck_type == 'no_battle' || checkTimeDiffs()"
                [class.danger]="supervize_zisk.gainSharePlayerId"
                (contextmenu)="toggleMenuZisk($event, 'gainSharePlayerId')"
                >
                <label>HRÁČ #5</label>
                <div class="player-select">
                    <ng-select
                    [items]="roster_all"
                    bindLabel="search"
                    bindValue="id"
                    [(ngModel)]="player5"
                    [disabled]="puck_type == 'no_battle' || checkTimeDiffs()"
                    (focus)="player5_active = true"
                    (blur)="player5_active = false"
                    dropdownPosition="top"
                    >
                    <ng-template ng-label-tmp let-item="item">
                        <table class="ng-select-item">
                        <tr>
                            <td style="width: 20px">
                            <img
                                src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                                }}.png"
                                onerror="this.src='/assets/image/logos/default.png';"
                                onerror="this.src='/assets/image/logos/default.png';"
                                width="18px"
                                height="18px"
                                style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                                "
                                *ngIf="item.id != ''"
                            />
                            </td>
                            <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                            </td>
                            <td>{{ item.surname }} {{ item.name }}</td>
                            <td style="width: 16px">
                            <div class="position" *ngIf="item.id != ''">
                                {{ formatPosition(item.position) }}
                            </div>
                            </td>
                        </tr>
                        </table>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                        <table class="ng-select-item">
                        <tr>
                            <td style="width: 20px">
                            <img
                                src="/assets/image/logos/{{
                                getPlayerTeamShortcut(item.id)
                                }}.png"
                                onerror="this.src='/assets/image/logos/default.png';"
                                onerror="this.src='/assets/image/logos/default.png';"
                                width="18px"
                                height="18px"
                                style="
                                vertical-align: top;
                                margin-top: 0px;
                                margin-right: 4px;
                                "
                                *ngIf="item.id != null"
                            />
                            </td>
                            <td style="width: 29px">
                            <div class="jersey">{{ item.jersey }}</div>
                            </td>
                            <td>{{ item.surname }} {{ item.name }}</td>
                            <td style="width: 16px">
                            <div class="position">
                                {{ formatPosition(item.position) }}
                            </div>
                            </td>
                        </tr>
                        </table>
                    </ng-template>
                    </ng-select>
                </div>
                </div>

            </div>
        </div>
      </div>

      <div class="canvas-box small">
        <div class="box-inner">

        </div>
      </div>

      <div class="canvas-box">
        <h2>&nbsp;</h2>
        <div class="box-inner">
          <!--[class.reversed]="reversed_sides"-->
        </div>
      </div>
    </div>
  </div>

  <loading-small *ngIf="loading"></loading-small>

  <div
    class="menu"
    *ngIf="contextmenu"
    [ngStyle]="{ 'left.px': contextmenuX, 'top.px': contextmenuY }"
  >
    <a (click)="toggleSupervize()" class="supervize">Supervize</a>
    <a (click)="toggleHelp()" class="help">Nápověda</a>
  </div>

  <div
    class="help-box"
    *ngIf="showhelp"
    [ngStyle]="{ 'left.px': contextmenuX, 'top.px': contextmenuY }"
  >
    <h3>{{ help_title }}</h3>
    <button class="closebtn" (click)="showhelp = false"></button>
    <div class="desc">
      {{ help_desc }}
    </div>
  </div>
</div>