import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'match-info',
  templateUrl: './match-info.component.html',
  styleUrls: ['./match-info.component.scss'],
})
export class MatchInfoComponent implements OnInit, OnChanges {
  @Input() game: any;
  @Input() sidesPeriod: boolean[];
  @Input() period: number;
  @Input() changeSidesLock: boolean;
  @Input() filterEventFlow: boolean;
  @Input() overtimeLength: number;
  @Input() minute: number;
  @Input() second: number;

  @Output() onLoadPlayersOnIce = new EventEmitter<boolean>();
  @Output() onPeriodChange = new EventEmitter<number>(true);
  @Output() onMinuteChange = new EventEmitter<number>();
  @Output() onSecondChange = new EventEmitter<number>();
  @Output() onSidesPeriodChange = new EventEmitter<boolean[]>();
  @Output() onLogout = new EventEmitter<boolean[]>();
  @Output() onFilterEventFlowChange = new EventEmitter<boolean>();

  filter_period: number;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checkEmptyValue(changes.filterEventFlow))
      this.filterEventFlow = changes.filterEventFlow.currentValue;
  }

  checkEmptyValue(value: any): boolean {
    return (
      value !== undefined &&
      value !== null &&
      value.currentValue !== undefined &&
      value.currentValue !== null
    );
  }

  loadPlayersOnIce(open_video: boolean) {
    this.onLoadPlayersOnIce.emit(open_video);
  }

  public onChangeSites() {
    this.sidesPeriod[this.period] = !this.sidesPeriod[this.period];
    this.changeSidesLock = true;
    this.onSidesPeriodChange.emit(this.sidesPeriod);
  }

  minusPeriod() {
    if (this.period > 1) {
      --this.period;
      this.filter_period = this.period;
    }
    this.loadPlayersOnIce(false);
    this.filterEventFlow = true;
    this.onPeriodChange.emit(this.period);
    this.onFilterEventFlowChange.emit(this.filterEventFlow);
  }

  plusPeriod() {
    if (this.overtimeLength < 1200 && this.period > 3) return;
    ++this.period;
    this.filter_period = this.period;
    if (this.period > 3) {
      const minutesCount = this.getMinutesCount();
      if (this.minute >= minutesCount) {
        this.minute = 0;
        this.second = 0;
      }
    }
    this.loadPlayersOnIce(false);
    this.filterEventFlow = true;
    this.onPeriodChange.emit(this.period);
    this.onFilterEventFlowChange.emit(this.filterEventFlow);
  }

  plusMinute() {
    const minutesCount = this.getMinutesCount();
    if (this.minute < minutesCount) {
      ++this.minute;
      if (this.minute === minutesCount) {
        this.second = 0;
      }
    } else {
      this.minute = 0;
    }
    this.onMinuteChange.emit(this.minute);
    this.onSecondChange.emit(this.second);
    this.loadPlayersOnIce(false);
  }

  minusMinute() {
    const minutesCount = this.getMinutesCount();
    if (this.minute > 0) --this.minute;
    else this.minute = minutesCount - 1;

    this.onMinuteChange.emit(this.minute);
    this.loadPlayersOnIce(false);
  }

  plusSecond() {
    if (this.second < 59) {
      ++this.second;
      const minutesCount = this.getMinutesCount();
      if (this.second > 0 && this.minute === minutesCount) {
        this.minute = minutesCount - 1;
      }
    } else {
      this.second = 0;
    }

    this.onMinuteChange.emit(this.minute);
    this.onSecondChange.emit(this.second);
    this.loadPlayersOnIce(false);
  }

  minusSecond() {
    if (this.second > 0) {
      --this.second;
      const minutesCount = this.getMinutesCount();
      if (this.second > 0 && this.minute === minutesCount) {
        this.minute = minutesCount - 1;
      }
    } else {
      this.second = 59;
    }

    this.onMinuteChange.emit(this.minute);
    this.onSecondChange.emit(this.second);
    this.loadPlayersOnIce(false);
  }

  private getMinutesCount(): number {
    let minutes = 20;
    if (this.overtimeLength)
      minutes = this.period > 3 ? this.overtimeLength / 60 : 20;
    return minutes;
  }

  formatPeriod(period: number) {
    if (period === 1) return 'I.';
    if (period === 2) return 'II.';
    if (period === 3) return 'III.';
    if (period === 4) return 'prod.';
    return 'prod.' + (period - 3);
  }

  logout(): void {
    this.onLogout.emit();
  }
}
