import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface TeamInterface {
    id: number;
    name: number;
    shortName: number;
    shortcut: number;
}

@Component({
    selector: 'app-select-side-dialog',
    templateUrl: './select-side-dialog.component.html',
    styleUrls: ['./select-side-dialog.component.scss']
})
export class SelectSideDialogComponent implements OnInit {

    public leftSide;
    public rightSide;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { gameData: { homeTeam: TeamInterface, awayTeam: TeamInterface }},
        public dialogRef: MatDialogRef<SelectSideDialogComponent>,
    ) { }

    ngOnInit(): void {
        this.initData();
    }

    private initData() {
        if (this.data?.gameData) {
            this.leftSide = this.data.gameData.homeTeam;
            this.rightSide = this.data.gameData.awayTeam;
        }
    }

    public onChangeSites() {
        const tempSide = this.leftSide;
        this.leftSide = this.rightSide;
        this.rightSide = tempSide;
    }

    public onSaveClick() {
        const changed = this.leftSide.id !== this.data.gameData.homeTeam.id;
        this.dialogRef.close(changed);
    }
}