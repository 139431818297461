import { Component, OnInit } from '@angular/core';

import { DefaultService } from '../../services/default.service';

import { iMatch, iEvent } from './../../interfaces/match.interface';
import { iLog } from './../../interfaces/log.interface';
import { DatePipe } from '@angular/common';

interface iEventTypes {
  icon: string;
  rule: number;
  matches: iMatch[];
  events: iEvent[];
}

enum SortDir {
  ASC,
  DESC
}

@Component({
  selector: 'error-overview',
  templateUrl: './error-overview.component.html',
  styleUrls: ['./error-overview.component.scss']
})
export class ErrorOverviewComponent implements OnInit {

  constructor(public defaultService: DefaultService, private datePipe: DatePipe) {}
  leagues: string[];
  matches: iMatch[];
  logs: iLog[];
  matchesToDisplay: iMatch[];
  events: iEventTypes[];
  eventsToDisplay: iEvent[];
  loading: boolean = true;
  selectedMatch: iMatch;

  selectedLeague: string = "-1";
  selectAll: boolean = false;

  sortDirection: SortDir = SortDir.ASC;
  sortingColumnName: string = "";
  arrowIcon: string = "";

  ngOnInit(): void {
    this.loadMatches();
  }

  /**
   * Loads the matches.
   */
  loadMatches(): void {
    this.defaultService.getMatches().subscribe(
      (data: iMatch[]) => {
        this.matches = data;
        this.loadLeagues();
      },
      error => console.error("Error:", error),
      () => this.loading = false
    );
  }

  /**
   * Toggles collapsing of editing node and changes plus into minus and backwards.
   * @param id Index of selected edit node.
   */
  collapseEdit(id: number) {
    let node = document.getElementsByClassName("event-node")[id];
    let collapsable = node.getElementsByClassName("collapsable")[0] as HTMLElement;
    if(collapsable != undefined)
      if(collapsable.classList.contains("collapse")) {
        collapsable.classList.remove("collapse");
        collapsable.style.height = "0px";
        collapsable.style.marginTop = "0px";
      } else {
        collapsable.classList.add("collapse");
        collapsable.style.height = collapsable.scrollHeight > 300 ? 300 + "px" : collapsable.scrollHeight + "px";
        collapsable.style.marginTop = "8px";
      }
  }

  /**
   * Redirects into the tracking page by the given match id.
   * @param match Selected match.
   */
  loadEditing(match: iMatch): void {
    window.open("/supervize/" + match.id);
  }

  /**
   * Filters and loads events to the right side of the page.
   */
  loadEvents(): void {
    this.eventsToDisplay = [];
    let selected = this.matchesToDisplay.filter(match => match.selected);
    if(selected.length === 0)
      return;

    this.loading = true;
    selected.forEach((match: iMatch, index) => {
      this.defaultService.getEventFlowError(match.id).subscribe(
        (data: iEvent[]) => match.events = data,
        error => console.error("Error:", error)
      ).add(() => {
        if(index === selected.length - 1)
          this.loadElements(selected);
      });
    });
  }

  /**
   * Loads events to the right side of the page.
   * @param selected array of users selected matches.
   */
  loadElements(selected: iMatch[]): void {
    this.events = [];
    selected.forEach((match: iMatch) => {
      if(match.events === undefined || match.events?.length < 1) return;

      match.events.forEach(event => {
        let node = this.checkIfEventExists(event.error_type, event.error_rule);
        if(node === undefined) {
          node = {
            icon: event.error_type,
            rule: event.error_rule,
            matches: [],
            events: []
          };
          node.matches.push(match);
          node.events.push(event);
          this.events.push(node);
        } else {
          node.matches.push(match);
          node.events.push(event);
        }
      });
    });
    this.sortByCount();
    this.sortByError();
    this.loading = false;
  }

  /**
   * Sorts showed events in the right side of the page by count of errors or warnings.
   */
  sortByCount(): void {
    this.events.sort((a, b) => b.events.length - a.events.length);
  }

  /**
   * Sorts showed events in the right side of the page by error first and warning after.
   */
  sortByError(): void {
    this.events.sort((a, b) => {
      if(a.icon === "warning" && b.icon === "error") return 1;
      if(a.icon === "error" && b.icon === "warning") return -1;
      return 0;
    });
  }

  /**
   * Checks if event already exists.
   * @param type error | warning
   * @param rule rule number
   * @returns iEventType node
   */
  checkIfEventExists(type: string, rule: number): iEventTypes {
    return this.events.find(event => event.icon === type && event.rule === rule);
  }

  /**
   * Filters showed matches by the given column name.
   * @param type column name
   */
  sortBy(type: string): void {
    this.setSortDirection(type);
    if(type === "date") {
      this.matchesToDisplay = this.matchesToDisplay.sort((a: iMatch, b: iMatch) => {
        if(a.date > b.date) return this.sortDirection == SortDir.ASC ? 1 : -1;
        if(a.date < b.date) return this.sortDirection == SortDir.ASC ? -1 : 1;
        return 0;
      });
      return;
    }
    let usingType = type == "error-count" ? "error" : "warning";
    this.matchesToDisplay = this.matchesToDisplay.sort((a: iMatch, b: iMatch) => {
      if(a.events === undefined)
        return 0;
      if(b.events === undefined)
        return this.sortDirection == SortDir.ASC ? -1 : 1;
      if(this.getLengthOfErrorType(a.events, usingType) > this.getLengthOfErrorType(b.events, usingType))
        return this.sortDirection == SortDir.ASC ? -1 : 1
      if(this.getLengthOfErrorType(a.events, usingType) < this.getLengthOfErrorType(b.events, usingType))
        return this.sortDirection == SortDir.ASC ? 1 : -1;
      return 0;
    });
  }

  /**
   * Sets a sorting direction and arrow image source.
   * @param type A column name that is sorting.
   */
  setSortDirection(type): void {
    if(type !== this.sortingColumnName) {
      this.sortDirection = SortDir.ASC;
      this.sortingColumnName = type;
      this.arrowIcon = "/assets/image/filter-arrow-" + (this.sortDirection == SortDir.ASC ? "up" : "down") + ".svg";
      return;
    }
    this.sortDirection = this.sortDirection === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    this.arrowIcon = "/assets/image/filter-arrow-" + (this.sortDirection == SortDir.ASC ? "up" : "down") + ".svg";
  }

  /**
   * Calculates the length of the given event array filtered with the given event type.
   * @param events iEvent array
   * @param type error | warning
   * @returns length of the filtered events
   */
  getLengthOfErrorType(events: iEvent[], type: string): number {
    return events.filter(event => event.error_type === type).length;
  }

  /**
   * Filter through the log array and sets a new array of all edit names for ngModel of the select.
   * @param arr Array that will contain strings of the given type.
   * @param type Variable of the iLog type.
   * @returns Array of all strings of the given type.
   */
  setEdittingArray(arr: string[], type: string): string[] {
    arr = [];
    this.logs.forEach(log => {
      if(!arr.includes(log[type]))
        arr.push(log[type]);
    });
    return arr;
  }

  /**
   * Loads leagues that contains extraliga in it's name and places it select.
   */
  loadLeagues() {
    this.leagues = [];
    this.matches.forEach((match: iMatch) => {
      if(match.competition.league.toLowerCase().includes("tipsport extralig") && !this.leagues.includes(match.competition.league))
        this.leagues.push(match.competition.league);
    });
  }

  /**
   * Filters leagues with the settings of select.
   */
  filterLeagues(): void {
    if(this.selectedLeague === "-1")
      return;

    this.events = [];
    this.matchesToDisplay = this.matches.filter(match => this.selectedLeague == match.competition.league);
    this.onMainCheckboxClicked(false);
  }

  /**
   * Selects or diselects all the matches.
   */
  onMainCheckboxClicked(clicked: boolean): void {
    this.selectAll = clicked;
    this.matchesToDisplay.forEach(match => match.selected = clicked);
  }

  /**
   * Toggles match selection.
   * @param match Match to be selected.
   */
  onCheckboxClicked(match: iMatch): void {
    match.selected = !match.selected;
    let length = this.matchesToDisplay.filter(match => match.selected).length;
    if(length === this.matchesToDisplay.length) {
      this.selectAll = true;
      return;
    }
    this.selectAll = false;
  }
}