import { errorsText } from './../../errors_text';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorWarningTranslator'
})
export class ErrorWarningTranslatorPipe implements PipeTransform {

  /**
   * Transforms rule id into text.
   * @error_rule Id of the rule.
   * @returns string represented by the given rule id.
   */
  transform(error_rule: number, error_type: string): string {
    return errorsText.find(error => error.rule === error_rule && error.type === error_type).text;
  }
}